import {
  Box,
  Button,
  Card,
  CardMedia,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  MenuProps,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import { Iconify, RefLink } from "../../components";
import {
  IServiceProvider,
  IServiceProviderStatus,
  ServiceDays,
  ServiceProviderRejectionReason,
  ServiceProviderRejectionReasonLabel,
  ServiceStatusList,
} from "../../interface";
import { axiosInstance, fDateTime, fetcher, wait } from "../../utils";
import { ServiceSettinsModal, UploadZone } from "../../section";
import { Days, ServiceBusinessTypes, ServiceVehicleTypes } from "../../data";
import { useAuth } from "../../context";
import { ICityDetail } from "../../interface/ICity";
import MaskedInput from "react-text-mask";
import { enqueueSnackbar } from "notistack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DangerousIcon from "@mui/icons-material/Dangerous";
import WrongLocationIcon from "@mui/icons-material/WrongLocation";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import { alpha, styled } from "@mui/material/styles";

const onChangeForm = (
  key: string,
  value: any,
  form: any,
  setForm: Dispatch<SetStateAction<any>>,
  key2?: any,
  value2?: any,
  extend?: any
) => {
  const f: any = { ...form };
  if (key.includes(".")) {
    const splitted = key.split(".");
    const first = splitted[0];
    const second = splitted[1];

    let targetObj = f[first];
    targetObj = {
      ...targetObj,
      [second]: value,
    };

    f[first] = targetObj;
  } else {
    f[key] = value;
    if (key2 && value2) {
      f[key2] = value2;
    }
    if (extend) {
      Object.assign(f, extend);
    }
  }
  setForm!(f);
};
const getNestedValue = (obj: any, path: string) => {
  return path.split(".").reduce((acc, key) => acc?.[key], obj);
};

const fillWithRules = (field: string, value: string) => {
  switch (field) {
    case "authorized_person_name":
      return value.toUpperCase();
    case "authorized_person_email":
      return value.toUpperCase();

    default:
      break;
  }
};
const ModalTextBoxItem = ({
  title,
  sx,
  form,
  setForm,
  field,
}: {
  title?: string;
  sx?: any;
  form: any;
  setForm: Dispatch<SetStateAction<any>>;
  field: string;
}) => {
  return (
    <Stack gap={1} sx={{ flex: 1, ...sx }}>
      {title && (
        <Typography
          sx={{
            fontSize: "16px",
            lineHeight: "24px",
            color: "grey.700",
          }}
        >
          {title}
        </Typography>
      )}
      {field == "authorized_person_phone" || field == "appointment_phone" ? (
        <MaskedInput
          required
          value={getNestedValue(form, field)}
          onChange={(e) => {
            onChangeForm(field, e.target.value, form, setForm);
          }}
          guide={false}
          render={(innerRef, props) => (
            <TextField
              {...props}
              inputRef={innerRef}
              // placeholder="+90 "
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          )}
          mask={[
            "0",
            " ",
            "(",
            /\d/,
            /\d/,
            /\d/,
            ")",
            " ",
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
          ]}
        />
      ) : (
        <TextField
          required
          type={field === "authorized_person_email" ? "email" : "text"}
          value={getNestedValue(form, field!)}
          sx={{
            width: "95%",
          }}
          onChange={(e) => {
            onChangeForm(
              field,
              field === "authorized_person_name" || field === "offical_title"
                ? e.target.value.toUpperCase()
                : e.target.value,
              form,
              setForm
            );
          }}
        />
      )}
    </Stack>
  );
};
// const ModalCitySelectBoxItem = ({
//   title,
//   sx,
//   form,
//   setForm,
//   field,
//   cities,
//   selectedCity,
//   setDistricts,
// }: {
//   title?: string;
//   sx?: any;
//   form: any;
//   setForm: Dispatch<SetStateAction<any>>;
//   field: string;
//   cities?: any;
//   selectedCity?: ICityDetail[];
//   setSelectedCity?: Dispatch<SetStateAction<ICityDetail[]>>;
//   setDistricts: Dispatch<SetStateAction<ICityDetail[]>>;
// }) => {
//   useEffect(() => {
//     setDistricts((cities as any)[getNestedValue(form, field!)] ?? []);
//   });
//   return (
//     <Stack gap={1} sx={{ flex: 1, ...sx }}>
//       {title && (
//         <Typography
//           sx={{
//             fontSize: "16px",
//             lineHeight: "24px",
//             color: "grey.700",
//           }}
//         >
//           {title}
//         </Typography>
//       )}

//       <FormControl required>
//         <Select
//           required
//           sx={{ width: "95%" }}
//           onChange={(e) => {
//             onChangeForm(field!, e.target.value, form, setForm);
//             setDistricts((cities as any)[e.target.value as any] ?? []);
//           }}
//           value={getNestedValue(form, field!)}
//         >
//           {Object.entries(cities as any[]).map(([key, value]) => (
//             <MenuItem key={key} value={key}>
//               {key}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </Stack>
//   );
// };
// const ModalDistrictSelectBoxItem = ({
//   title,
//   sx,
//   form,
//   setForm,
//   field,
//   districts,
//   disabled,
//   cities,
// }: {
//   title?: string;
//   sx?: any;
//   form: any;
//   setForm: Dispatch<SetStateAction<any>>;
//   field?: string;
//   districts: ICityDetail[];
//   disabled?: boolean;
//   cities: any;
//   setDistricts: Dispatch<SetStateAction<ICityDetail[]>>;
// }) => {
//   return (
//     <Stack gap={1} sx={{ flex: 1, ...sx }}>
//       {title && (
//         <Typography
//           sx={{
//             fontSize: "16px",
//             lineHeight: "24px",
//             color: "grey.700",
//           }}
//         >
//           {title}
//         </Typography>
//       )}

//       <FormControl required sx={{ width: "90%" }}>
//         <Select
//           required
//           sx={{ width: "90%" }}
//           disabled={disabled}
//           onChange={(e) => {
//             onChangeForm(field!, e.target.value, form, setForm);
//           }}
//           value={districts && getNestedValue(form, field!)}
//         >
//           {districts.map((detail, key) => (
//             <MenuItem key={key} value={detail.district}>
//               {detail.district}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </Stack>
//   );
// };
// const ModalBTSelectBoxItem = ({
//   title,
//   sx,
//   form,
//   setForm,
//   field,
// }: {
//   title?: string;
//   sx?: any;
//   form: any;
//   setForm: Dispatch<SetStateAction<any>>;
//   field?: string;
// }) => {
//   return (
//     <Stack gap={1} sx={{ flex: 1, ...sx }}>
//       {title && (
//         <Typography
//           sx={{
//             fontSize: "16px",
//             lineHeight: "24px",
//             color: "grey.700",
//           }}
//         >
//           {title}
//         </Typography>
//       )}

//       <FormControl required>
//         <Select
//           sx={{ width: "95%" }}
//           onChange={(e) => {
//             onChangeForm(field!, e.target.value, form, setForm);
//           }}
//           value={getNestedValue(form, field!)}
//         >
//           {ServiceBusinessTypes.map((val, index) => (
//             <MenuItem value={val} key={index}>
//               {val}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </Stack>
//   );
// };
const ModalItem = ({
  title,
  value,
  sx,
  form,
  setForm,
  field,
  ignore,
}: {
  title?: string;
  value?: string | number | React.ReactNode;
  sx?: any;
  form?: any;
  setForm?: Dispatch<SetStateAction<any>>;
  field?: string;
  ignore?: boolean;
}) => {
  return (
    <Stack gap={1} sx={{ flex: 1, ...sx }}>
      {title && (
        <Typography
          sx={{
            fontSize: "16px",
            lineHeight: "24px",
            color: "grey.700",
          }}
        >
          {title}
        </Typography>
      )}
      {!!value && typeof value === "object" && value}
      {value && (typeof value === "string" || typeof value === "number") && (
        <Typography
          sx={{
            fontSize: "20px",
            lineHeight: "30px",
            color: "gray.900",
            fontWeight: 500,
          }}
        >
          {value}
        </Typography>
      )}
    </Stack>
  );
};

const ModalContainer = ({
  isModal,
  children,
}: {
  isModal: boolean;
  children: React.ReactNode;
}) => {
  if (isModal) {
    return (
      <Box
        sx={{
          position: "absolute",
          inset: 0,
          backgroundColor: "grey.50",
          zIndex: 1,
          overflowY: "auto",
        }}
      >
        {children}
      </Box>
    );
  }
  return <>{children}</>;
};

const filesTabs = [
  "Sözleşmeler",
  "Vergi levhası",
  "Dekont",
  "Ruhsat",
  "Ticari Sicil Gazetesi",
  "Faaliyet Belgesi",
  "İmza Sirküleri",
  "1. Teknisyen Kimlik Arkalı Önlü",
  "2. Teknisyen Kimlik Arkalı Önlü",
  "İşyeri Görselleri",
];

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

export function OptionsButton({
  selectedService,
  setSelectedService,
}: {
  selectedService: IServiceProvider;
  setSelectedService: Dispatch<React.SetStateAction<IServiceProvider | null>>;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openCustomModal, setOpenCustomModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [customReason, setCustomReason] = useState("");
  const [error, setError] = useState("");
  const [rejectLoading, setRejectLoading] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value?: string) => {
    if (value) {
      setCustomReason(value);
      setOpenConfirmModal(true);
    }
    setAnchorEl(null);
  };

  const handleCustomReasonSubmit = async () => {
    if (!customReason.trim()) {
      setError("Lütfen bir red sebebi giriniz");
      return;
    }
    setOpenCustomModal(false);
    setOpenConfirmModal(true);
  };

  const handleConfirm = async () => {
    try {
      setRejectLoading(true);
      try {
        const id = selectedService._id;

        await axiosInstance.put(`/service-provider/${id}`, {
          status: ServiceStatusList.REJECTED,
          rejection_reason: customReason,
        });

        const reasonLabel =
          ServiceProviderRejectionReasonLabel[
            customReason as ServiceProviderRejectionReason
          ] || "Diğer";
        enqueueSnackbar("Başvuru reddedildi. Neden: " + reasonLabel, {
          variant: "success",
        });
        setSelectedService(null);
      } catch (err) {
        console.log(err);
        enqueueSnackbar("Beklenmeyen bir hata oluştu", { variant: "error" });
        setSelectedService(null);
      }

      setOpenConfirmModal(false);
      setOpenCustomModal(false);
      setCustomReason("");
      setError("");
      setRejectLoading(false);
      setSelectedService(null);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Bir hata oluştu.", { variant: "error" });
    } finally {
      setRejectLoading(false);
    }
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        color="error"
      >
        REDDET
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose(ServiceProviderRejectionReason.missing_form);
          }}
          disableRipple
        >
          <DangerousIcon />
          Eksik Bilgi
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenCustomModal(true);
          }}
          disableRipple
        >
          <MoreHorizIcon />
          Diğer
        </MenuItem>
      </StyledMenu>

      {/* Özel Red Sebebi Modalı */}
      <Modal
        open={openCustomModal}
        onClose={() => setOpenCustomModal(false)}
        aria-labelledby="custom-reason-modal"
        aria-describedby="custom-rejection-reason"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Red Sebebi
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={
              ServiceProviderRejectionReasonLabel[
                customReason as ServiceProviderRejectionReason
              ] ?? customReason
            }
            onChange={(e) => {
              setCustomReason(e.target.value);
              setError("");
            }}
            error={!!error}
            helperText={error}
            placeholder="Red sebebini giriniz..."
            sx={{ mb: 2 }}
          />
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              onClick={() => {
                setOpenCustomModal(false);
                setCustomReason("");
                setError("");
              }}
              variant="outlined"
              color="error"
            >
              İptal
            </Button>
            <Button
              onClick={handleCustomReasonSubmit}
              variant="contained"
              color="secondary"
            >
              Reddet
            </Button>
          </Stack>
        </Box>
      </Modal>

      {/* Onay Modalı */}
      <Dialog
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 2, minWidth: 400 }}>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Onay
          </Typography>
          <Typography sx={{ mb: 3 }}>
            Başvuruyu reddetmek istediğinizden emin misiniz?
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              onClick={() => setOpenConfirmModal(false)}
              variant="outlined"
              color="inherit"
            >
              Hayır
            </Button>
            <Button
              onClick={handleConfirm}
              variant="contained"
              color="secondary"
              autoFocus
              disabled={rejectLoading}
              startIcon={
                rejectLoading ? (
                  <CircularProgress color="secondary" size={20} />
                ) : null
              }
            >
              Evet
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </div>
  );
}

export const ServiceDetailModal = ({
  selectedService,
  setSelectedService,
  isModal,
}: {
  selectedService: IServiceProvider;
  setSelectedService: Dispatch<React.SetStateAction<IServiceProvider | null>>;
  isModal?: boolean;
}) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  const [showFiles, setShowFiles] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [selectedCity, setSelectedCity] = useState<ICityDetail[]>([]);
  const [districts, setDistricts] = useState<ICityDetail[]>([]);

  const { data: cities, isLoading: cityLoading } = useSWR<any>(
    "/montage/city",
    fetcher
  );

  // const [form, setForm] = useState<any>({
  //   authorized_person_name: selectedService?.authorized_person_name ?? "",
  //   authorized_person_email: selectedService?.authorized_person_email ?? "",
  //   authorized_person_phone: selectedService?.authorized_person_phone ?? "",
  //   signage_name: selectedService?.signage_name ?? "",
  //   offical_title: selectedService?.offical_title ?? "",
  //   tax_office: selectedService?.tax_office ?? "",
  //   tax_number: selectedService?.tax_number ?? "",
  //   address: {
  //     city: selectedService?.address?.city ?? "",
  //     district: selectedService?.address?.district ?? "",
  //     fullAddress: selectedService?.address?.fullAddress ?? "",
  //   },
  //   business_type: selectedService?.business_type ?? "",
  //   workplace_type: selectedService?.workplace_type ?? "",
  //   appointment_phone: selectedService?.appointment_phone ?? "",
  //   total_employees: selectedService?.total_employees ?? "",
  //   employees_for_installation:
  //     selectedService?.employees_for_installation ?? "",
  //   total_vehicle_capacity: selectedService?.total_vehicle_capacity ?? "",
  //   vehicles_for_installation: selectedService?.vehicles_for_installation,
  //   has_internet_service: selectedService?.has_internet_service ?? "",
  //   vehicle_types_for_service: selectedService?.vehicle_types_for_service ?? "",
  //   coordinates: {
  //     lat: selectedService?.coordinates?.lat ?? "",
  //     lng: selectedService?.coordinates?.lng ?? "",
  //   },
  //   service_provider_code: selectedService?.service_provider_code ?? "",
  // });

  const [form, setForm] = useState<any>({
    authorized_person_name: "",
    authorized_person_email: "",
    authorized_person_phone: "",
    signage_name: "",
    offical_title: "",
    tax_office: "",
    tax_number: "",
    city: "",
    district: "",
    fullAddress: "",
    business_type: "",
    workplace_type: "",
    appointment_phone: "",
    total_employees: "",
    employees_for_installation: "",
    total_vehicle_capacity: "",
    vehicles_for_installation: "",
    has_internet_service: false,
    vehicle_types_for_service: "",
    lat: "",
    lng: "",
    service_provider_code: "",
    level: "",
  }); // İlk başta boş bir obje olarak tanımla

  useEffect(() => {
    if (selectedService) {
      setForm({
        authorized_person_name: selectedService.authorized_person_name ?? "",
        authorized_person_email: selectedService.authorized_person_email ?? "",
        authorized_person_phone: selectedService.authorized_person_phone ?? "",
        signage_name: selectedService.signage_name ?? "",
        offical_title: selectedService.offical_title ?? "",
        tax_office: selectedService.tax_office ?? "",
        tax_number: selectedService.tax_number ?? "",
        city: selectedService.address?.city ?? "",
        district: selectedService.address?.district ?? "",
        fullAddress: selectedService.address?.fullAddress ?? "",
        business_type: selectedService.business_type ?? "",
        workplace_type: selectedService.workplace_type ?? "",
        appointment_phone: selectedService.appointment_phone ?? "",
        total_employees: selectedService.total_employees ?? "",
        employees_for_installation:
          selectedService.employees_for_installation ?? "",
        total_vehicle_capacity: selectedService.total_vehicle_capacity ?? "",
        vehicles_for_installation:
          selectedService.vehicles_for_installation ?? "",
        has_internet_service: selectedService.has_internet_service ?? false,
        vehicle_types_for_service:
          selectedService.vehicle_types_for_service ?? "",
        lat: selectedService.coordinates?.lat ?? "",
        lng: selectedService.coordinates?.lng ?? "",
        service_provider_code: selectedService.service_provider_code ?? "",
      });
      if (cities) {
        setDistricts(cities[selectedService?.address?.city]);
      }
    }
  }, [selectedService, cities]);

  const onConfirm = async () => {
    setLoading(true);

    try {
      await axiosInstance.put(`/service-provider/${selectedService._id}`, {
        status: ServiceStatusList.SET_PASSWORD,
      });

      const params = new URLSearchParams();
      params.append("status", ServiceStatusList.PENDING_APPROVAL);
      mutate("/service-provider");
      mutate(`/service-provider/query?${params.toString()}`);

      setSelectedService(null);

      enqueueSnackbar("Bayii durumu değiştirildi.", { variant: "success" });
    } catch (error) {}

    setLoading(false);
  };

  const onSaveFile = async (files: any[]) => {
    setDisabled(true);
    try {
      await axiosInstance.put(`/service-provider/${selectedService?._id}`, {
        files,
      });
      enqueueSnackbar("Bayi evrakları düzenlendi.", {
        variant: "success",
      });

      const params = new URLSearchParams();
      params.append("status", ServiceStatusList.PENDING_APPROVAL);
      mutate("/service-provider");
      mutate(`/service-provider/query?${params.toString()}`);
      mutate(`/service-provider/profile/${selectedService._id}`);
    } catch (error) {
      enqueueSnackbar(`Bilinmeyen bir hata oluştu.`, { variant: "error" });
      console.log(error);
    }
    setDisabled(false);
  };

  // const getReferans = () => {
  //   let ref = selectedService.reference;

  //   if (ref && ref.startsWith("S-")) {
  //     ref = ref.replace("S-", "");
  //   }

  //   return ref;
  // };
  // const onReject = async () => {
  //   try {
  //     const id = selectedService._id;

  //     await axiosInstance.put(`/service-provider/${id}`, {
  //       status: ServiceStatusList.REJECTED,
  //     });

  //     enqueueSnackbar("Başvuru reddedildi.", { variant: "success" });
  //     setSelectedService(null);
  //   } catch (err) {
  //     console.log(err);
  //     enqueueSnackbar("Beklenmeyen bir hata oluştu", { variant: "error" });
  //     setSelectedService(null);
  //   }
  // };

  const onSubmit = async () => {
    try {
      let isValid: boolean = true;
      setLoading(true);

      const { city, district, fullAddress, lat, lng, ...without } = form;
      console.log(form) 
      if (isValid) {
        await axiosInstance.put(`/service-provider/${selectedService._id}`, {
          ...without,
          address: {
            city,
            district,
            fullAddress,
          },
          coordinates: {
            lat,
            lng,
          },
        });
        enqueueSnackbar("Bayi bilgileri başarıyla güncellendi.", {
          variant: "success",
        });
        setLoading(false);
      }
    } catch (e) {
      enqueueSnackbar("Bir hata oluştu.", { variant: "error" });
      console.log(e);
      setLoading(false);
      return;
    }
  };
  if (!selectedService) return null;

  return (
    <ModalContainer isModal={!!isModal}>
      <Dialog
        fullWidth
        maxWidth="md"
        open={showFiles}
        onClose={() => {
          setShowFiles(false);
        }}
      >
        <Stack gap={2} sx={{ p: 3 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>
              {selectedService?.offical_title} - Gerekli Evraklar{" "}
            </Typography>
            <IconButton
              onClick={() => {
                setShowFiles(false);
              }}
            >
              <Iconify icon="ic:baseline-close" />
            </IconButton>
          </Stack>
          <UploadZone
            disabled={disabled}
            files={selectedService?.files}
            onSave={(newFiles) => {
              onSaveFile(newFiles);
            }}
          />
        </Stack>
      </Dialog>

      <Stack gap={2} p={isModal ? 3 : 0}>
        {isModal && (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                sx={{ fontSize: "24px", lineHeight: "48px", fontWeight: 500 }}
              >
                Bayii Başvuru Bilgileri
              </Typography>
              <IconButton
                onClick={() => {
                  setSelectedService(null);
                }}
              >
                <Iconify icon="ic:baseline-close" />
              </IconButton>
            </Stack>
          </>
        )}
        <Box
          sx={{
            border: "1px solid",
            borderColor: "divider",
            borderRadius: 4,
            backgroundColor: "#fff",
          }}
        >
          <Stack
            sx={{
              "&>*": {
                borderBottom: "1px solid",
                borderBottomColor: "divider",
                p: 3,
              },
              "&>*:last-child": {
                borderBottom: "0px",
              },
            }}
          >
            <Stack gap={3}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <ModalItem
                  value="Montaj Noktası Yetkilisine Ait Bilgiler"
                  ignore
                />

                <Stack direction="row" alignItems="center" gap={1}>
                  {user?.role === "admin" && (
                    <IconButton
                      onClick={() => {
                        setShowFiles(true);
                      }}
                    >
                      <Iconify icon="ic:round-upload-file" />
                    </IconButton>
                  )}
                  <ServiceSettinsModal forceMe={selectedService} />
                </Stack>
              </Stack>
              <Stack direction="row">
                <ModalTextBoxItem
                  title="Bayi Kodu"
                  field="service_provider_code"
                  form={form}
                  setForm={setForm}
                />
                <ModalTextBoxItem
                  title="Yetkili İsmi Soyisim"
                  field="authorized_person_name"
                  form={form}
                  setForm={setForm}
                />
                <ModalTextBoxItem
                  title="Yetkili Mail Adresi"
                  field="authorized_person_email"
                  form={form}
                  setForm={setForm}
                />
                <ModalTextBoxItem
                  title="Yetkili Telefon Numarası"
                  field="authorized_person_phone"
                  form={form}
                  setForm={setForm}
                />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  ignore
                  title="Referans"
                  value={
                    <RefLink reference={selectedService.reference ?? ""} />
                  }
                />
                {!!selectedService.services?.length ? (
                  <ModalItem
                    ignore
                    title="Bayi Referans Sayısı"
                    value={selectedService.services.length}
                  />
                ) : (
                  <Box sx={{ flex: 1 }} />
                )}
                <Box sx={{ flex: 1 }} />
              </Stack>
            </Stack>

            <Stack gap={3}>
              <Stack direction="row">
                <ModalItem value="Montaj Noktasına Ait Bilgiler" ignore />
              </Stack>
              <Stack direction="row">
                <ModalTextBoxItem
                  title="Montaj Noktası Tabela İsmi"
                  field="signage_name"
                  form={form}
                  setForm={setForm}
                />
                <ModalTextBoxItem
                  title="Resmi Ünvanı"
                  field="offical_title"
                  form={form}
                  setForm={setForm}
                />
                <ModalTextBoxItem
                  title="Vergi Dairesi"
                  field="tax_office"
                  form={form}
                  setForm={setForm}
                />
                <ModalTextBoxItem
                  title="Vergi Numarası"
                  field="tax_number"
                  form={form}
                  setForm={setForm}
                />
              </Stack>
              <Stack direction="row">
                {!cityLoading && (
                  <Stack gap={1} sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "grey.700",
                      }}
                    >
                      İl
                    </Typography>

                    <FormControl required>
                      <Select
                        required
                        sx={{ width: "95%" }}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            city: e.target.value,
                          });
                          setDistricts(cities && cities[e.target.value as any]);
                        }}
                        value={form?.city}
                      >
                        {Object.entries(cities as any)?.map(([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {key}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                )}
                <Stack gap={1} sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "grey.700",
                    }}
                  >
                    İlçe
                  </Typography>

                  <FormControl required sx={{ width: "90%" }}>
                    <Select
                      required
                      sx={{ width: "90%" }}
                      disabled={disabled}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          district: e.target.value,
                        });
                      }}
                      value={form?.district ?? ""}
                    >
                      {districts?.map((detail, key) => (
                        <MenuItem key={key} value={detail.district}>
                          {detail.district}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                {/* <ModalItem
                  title="Mahalle"
                  value={selectedService.address?.neighborhood ?? "-"}
                /> */}
                {/* <ModalItem
                  title="Sokak"
                  value={selectedService.address?.street ?? "-"}
                /> */}
                <Box sx={{ flex: 1 }} />
                <Box sx={{ flex: 1 }} />
              </Stack>
              <Stack direction="row">
                <Stack gap={1} sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "grey.700",
                    }}
                  >
                    Adres
                  </Typography>

                  <TextField
                    required
                    value={form?.fullAddress ?? ""}
                    sx={{
                      width: "95%",
                    }}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        fullAddress: e.target.value,
                      });
                    }}
                  />
                </Stack>
              </Stack>
              <Stack direction="row">
                <ModalItem
                  ignore
                  title="Enlem"
                  field="coordinates.lat"
                  form={form}
                  setForm={setForm}
                  value={selectedService.coordinates?.lat ?? "-"}
                />
                <ModalItem
                  ignore
                  title="Boylam"
                  field="coordinates.lng"
                  form={form}
                  setForm={setForm}
                  value={selectedService.coordinates?.lng ?? "-"}
                />
                {selectedService?.coordinates?.lat &&
                  selectedService?.coordinates?.lng && (
                    <Stack sx={{ flex: 1 }}>
                      <ModalItem title="Konum" />
                      <a
                        target="_blank"
                        href={`https://www.google.com/maps/search/${selectedService.coordinates.lat},${selectedService.coordinates.lng}`}
                        rel="noreferrer"
                      >
                        <Stack direction="row" gap={0.25} alignItems="center">
                          Google'da Göster
                          <Iconify icon="mdi:map-marker-outline" />
                        </Stack>
                      </a>
                    </Stack>
                  )}
                <Box sx={{ flex: 1 }} />
                <Box sx={{ flex: 1 }} />
              </Stack>
            </Stack>
            <Stack gap={3}>
              <Stack direction="row">
                <ModalItem value="İşletmeye Ait Bilgiler" ignore />
              </Stack>
              <Stack direction="row">
                <Stack gap={1} sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "grey.700",
                    }}
                  >
                    İşletme Tipi
                  </Typography>

                  <FormControl required>
                    <Select
                      sx={{ width: "95%" }}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          business_type: e.target.value,
                        });
                      }}
                      value={form?.business_type ?? ""}
                    >
                      {ServiceBusinessTypes.map((val, index) => {
                        return (
                          <MenuItem value={val} key={index}>
                            {val}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Stack>
                <ModalTextBoxItem
                  title="İşyeri Tipi"
                  field="workplace_type"
                  form={form}
                  setForm={setForm}
                />
                <ModalTextBoxItem
                  title="Toplam Çalışan Sayısı"
                  field="total_employees"
                  form={form}
                  setForm={setForm}
                />
                <ModalTextBoxItem
                  title="Montaj Hizmeti Verebilecek Çalışan Sayısı"
                  field="employees_for_installation"
                  form={form}
                  setForm={setForm}
                />
              </Stack>
              <Stack direction="row">
                <ModalTextBoxItem
                  title="Randevu Telefonu"
                  field="appointment_phone"
                  form={form}
                  setForm={setForm}
                />
                <ModalItem
                  ignore
                  title="Çalışma Günleri"
                  value={(selectedService.working_days ?? [])
                    .sort((a, b) => Days.indexOf(a) - Days.indexOf(b))
                    .map((r: any) => (ServiceDays as any)[r])
                    .join(", ")}
                />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  ignore
                  title="Çalışma Saatleri"
                  value={selectedService.working_hours?.join(", ")}
                />
              </Stack>
              <Stack direction="row">
                <ModalTextBoxItem
                  title="Toplam Araç Kapasitesi"
                  field="total_vehicle_capacity"
                  form={form}
                  setForm={setForm}
                />
                <ModalTextBoxItem
                  title="Aynı Anda Montaj Hizmeti Verebilecek Araç Sayısı"
                  field="vehicles_for_installation"
                  form={form}
                  setForm={setForm}
                />
                {/* <ModalTextBoxItem
                  title="İşletme Tipi/ Montaj Hizmetleri Sağlayıcı Tipi"
                  field="business_type"
                  form={form}
                  setForm={setForm}
                /> */}
                <ModalItem
                  title="Montaj alanında internet hizmeti var mı?"
                  field="has_internet_service"
                  form={form}
                  setForm={setForm}
                  value={selectedService.has_internet_service ? "Var" : "-"}
                />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  title="Hizmet verebileceği araç türleri"
                  field="vehicle_types_for_service"
                  form={form}
                  setForm={setForm}
                  value={selectedService.vehicle_types_for_service
                    .map((vehicle) => vehicle)
                    .join(", ")}
                />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  ignore
                  title="Hizmet vermediği araç türleri"
                  value={ServiceVehicleTypes.filter(
                    (vehicle) =>
                      !selectedService.vehicle_types_for_service.includes(
                        vehicle
                      )
                  ).join(", ")}
                />
              </Stack>
            </Stack>
            {selectedService.technicians.length > 0 && (
              <Stack gap={3}>
                <Stack direction="row">
                  <ModalItem value="Teknisyen Bilgileri" ignore />
                </Stack>
                {selectedService.technicians.map((tec, index) => (
                  <Stack key={index} direction="row">
                    <ModalItem title="Teknisyen Adı Soyadı" value={tec.name} />
                    <ModalItem title="Teknisyen E-mail" value={tec.email} />
                    <ModalItem
                      title="Teknisyen Telefon Numarası"
                      value={tec.phone}
                    />
                    <Box sx={{ flex: 1 }} />
                  </Stack>
                ))}
              </Stack>
            )}
            {!!selectedService.files.length && (
              <Stack>
                <Stack direction="row">
                  <ModalItem value="Yüklenen Dosyalar" />
                </Stack>
                <Stack
                  direction="row"
                  flexWrap={"wrap"}
                  gap={2}
                  sx={{ mx: -2 }}
                >
                  {filesTabs.map((tabName, index) => {
                    const groupFiles = selectedService.files.filter(
                      (f: any) => f?.name === tabName
                    );

                    return (
                      <Stack key={index} gap={1} sx={{ p: 2 }}>
                        {groupFiles.length > 0 ? (
                          <>
                            <Stack gap={0.5}>
                              <Typography>{tabName}</Typography>
                              {groupFiles?.map((f) => (
                                <a
                                  href={`${process.env.REACT_APP_BACKEND_URL}/${f.url}`}
                                  target="_blank"
                                >
                                  <Card
                                    sx={{
                                      maxWidth: 200,
                                    }}
                                  >
                                    <CardMedia
                                      component="img"
                                      height="100"
                                      image={`${process.env.REACT_APP_BACKEND_URL}/${f.url}`}
                                    />
                                  </Card>
                                </a>
                              ))}
                            </Stack>
                            <Typography
                              sx={{ fontSize: "12px", lineHeight: "12px" }}
                            >
                              {fDateTime(groupFiles[0].uploadTime)}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography>{tabName}</Typography>
                            <Typography>Yüklenmedi</Typography>
                          </>
                        )}
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            )}
            {!!selectedService.montages?.length && (
              <Stack direction="row">
                <ModalItem
                  title="Referanslı Montaj Adeti"
                  value={selectedService.montages.length}
                />
                <ModalItem
                  title="Onaylanan Montaj Adeti"
                  value={
                    selectedService.montages.filter(
                      (montage) => montage.status === "completed"
                    ).length
                  }
                />
                <Box sx={{ flex: 1 }} />
              </Stack>
            )}
          </Stack>
        </Box>
        {selectedService.status === ServiceStatusList.PENDING_APPROVAL ? (
          <Stack direction="row" gap={2} mt={1}>
            <OptionsButton
              selectedService={selectedService}
              setSelectedService={setSelectedService}
            />
            <Button
              variant="contained"
              color={"secondary"}
              sx={{ minWidth: "256px" }}
              onClick={() => onConfirm()}
              disabled={loading}
            >
              Ön Başvuruyu Onayla
            </Button>
          </Stack>
        ) : (
          <Stack direction="row" gap={2} mt={1}>
            <Button
              variant="contained"
              color={"secondary"}
              sx={{ minWidth: "256px" }}
              onClick={() => onSubmit()}
              disabled={loading}
              startIcon={
                loading && <CircularProgress size={20} color="secondary" />
              }
            >
              Kaydet
            </Button>
          </Stack>
        )}
      </Stack>
    </ModalContainer>
  );
};
