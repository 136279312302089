import { IMontageApplication } from "./IMontageApplication";

export enum IServiceProviderStatus {
  "Pending Approval" = "Ön Onay Bekliyor",
  "Set Password" = "Şifre Girişi Bekleniyor",
  "Onboarding" = "Onay Bekleniyor",
  "Approved by Mint" = "Darphane Onayında",
  "Field Control" = "Saha Kontrolü",
  "Rejected" = "Ret Edildi",
  "Accepted" = "Kabul Edildi",
}

export const ServiceStatusList = {
  PENDING_APPROVAL: "Pending Approval",
  APPROVED_BY_MINT: "Approved by Mint",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
  FIELD_CONTROL: "Field Control",
  ONBOARDING: "Onboarding",
  SET_PASSWORD: "Set Password",
};

export const ServiceProviderStatusLabels = {
  "Pending Approval": "Ön Onay Bekliyor",
  "Set Password": "Şifre Girişi Bekleniyor",
  Onboarding: "Onay Bekleniyor",
  "Approved by Mint": "Darphane Onayında",
  "Field Control": "Saha Kontrolü",
  Rejected: "Ret Edildi",
  Accepted: "Kabul Edildi",
};

export const ServiceDays = {
  monday: "Pazartesi",
  tuesday: "Salı",
  wednesday: "Çarşamba",
  thursday: "Perşembe",
  friday: "Cuma",
  saturday: "Cumartesi",
  sunday: "Pazar",
};

export interface IAddress {
  city: string;
  district: string;
  street?: string;
  neighborhood?: string;
  fullAddress?: string;
}

interface ICoordinates {
  lat: number;
  lng: number;
}

interface ITechnician {
  name: string;
  email: string;
  phone: string;
}

interface IUploadedFile {
  filename: string;
  originalname: string;
  path: string;
  mimetype: string;
  size: number;
}

interface IFile {
  url: string;
  name: string;
  uploadTime: string;
}

export interface IServiceProvider {
  _id: string;
  authorized_person_name: string;
  authorized_person_email: string;
  authorized_person_phone: string;
  signage_name: string;
  offical_title: string;
  tax_office: string;
  tax_number: string;
  address: IAddress;
  coordinates: ICoordinates;
  business_type: string;
  workplace_type: string;
  total_employees: number;
  employees_for_installation: number;
  appointment_phone: string;
  working_days: string[];
  total_vehicle_capacity: number;
  vehicles_for_installation: number;
  has_internet_service: boolean;
  vehicle_types_for_service: string[];
  technicians: ITechnician[];
  files: IFile[];
  status: IServiceProviderStatus;
  createdAt: string;
  device_count?: number;
  mobile_device_count?: number;
  montages?: IMontageApplication[];
  level?: string;
  working_hours?: string[];
  reference?: string;
  services?: IServiceProvider[];

  service_provider_code?: string;
  rejection_reason?: string;

  utts?: any;
}

export enum ServiceProviderRejectionReason {
  missing_form = "missing_form",
}
export enum ServiceProviderRejectionReasonLabel {
  missing_form = "Eksik Bilgi",
}

