import React from 'react'
import WhatsAppIcon from '../WhatsAppIcon'

export default function WhatsappWidget() {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 20,
        right: 20,
        zIndex: 1000,
        padding: 10,
        cursor: "pointer",
      }}
      onClick={() => {
        window.open("https://wa.me/905330704841", "_blank");
      }}
    >
      <WhatsAppIcon />
    </div>
  )
}
