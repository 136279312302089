import { useState } from "react";
import { IAddress, IServiceProvider, ServiceProviderStatusLabels } from "../../interface";
import { axiosInstance } from "../../utils";
import { mutate } from "swr";
import { enqueueSnackbar } from "notistack";
import { MenuItem, Select } from "@mui/material";
export const ServiceStatusChanger = ({
  id,
  status,
  onChange,
  disabled,
}: {
  id: string;
  status: string;
  onChange?: () => void;
  disabled?: boolean;
}) => {
  const list: any = ServiceProviderStatusLabels;
    
  const [loading, setLoading] = useState(false);

  const handleOnChange = async (status: string) => {
    setLoading(true);


    try {
      await axiosInstance.put(`/service-provider/${id}`, {
        status,
      });

      const params = new URLSearchParams();
      params.append("status", ServiceProviderStatusLabels["Pending Approval"]);
      mutate("/service-provider");
      mutate(`/service-provider/query?${params.toString()}`);

      enqueueSnackbar("Bayii durumu değiştirildi.", { variant: "success" });

      if (onChange) {
        onChange();
      }
    } catch (error) {}

    setLoading(false);
  };

  return (
    <>
      <Select
        disabled={loading}
        size="small"
        sx={{ minWidth: 200 }}
        value={status}
        onChange={(e) => {
          handleOnChange(e.target.value);
        }}
      >
        {Object.keys(list as any).map((key) => (
          <MenuItem key={key} value={key} disabled={key == "Accepted" && disabled}>
            {list[key]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
