import React from 'react'
import { AdminLayout } from '../../../components'
import DashboardSection from '../../../section/admin/dashboard/DashboardSection'

export default function DashboardPage() {
  return (
    <AdminLayout>
      <DashboardSection />
    </AdminLayout>
  )
}
