import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, useTransition } from "react";
import { mutate } from "swr";
import { enqueueSnackbar } from "notistack";
import { useParams, useSearchParams } from "react-router-dom";
import { Header, IconError, IconSuccess } from "../components";
import { axiosInstance } from "../utils";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const PageHelmet = () => (
  <Helmet>
    <script>fbq('track', 'Lead_Montaj');</script>
  </Helmet>
);
export default function MontageThanks() {
  const navigate = useNavigate();

  const theme = useTheme();
  const [params] = useSearchParams();
  const typeParam = params.get("type") || "";
  const reservationParam = params.get("reservation") || "";

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <PageHelmet />
      <Container sx={{ mt: 2 }}>
        <Header />
        <Box
          sx={{
            backgroundColor: "#002B32",
            borderBottomLeftRadius: "24px",
            borderBottomRightRadius: "24px",
            pb: 4,
          }}
        >
          <Box sx={{ mx: 4, backgroundColor: "#fff", borderRadius: 4, p: 3 }}>
            {/* <Stack
              alignItems="center"
              gap={3}
              sx={{ py: 4 }}
              direction="column"
            >
              <Typography
                component={"body"}
                sx={{
                  fontSize: "30px",
                  lineHeight: "45px",
                  textAlign: "center",
                  mb: "48px",
                  ...(isMobile
                    ? { fontSize: "34px", lineHeight: "46px", mb: "24px" }
                    : {}),
                }}
              >
                Montaj Başvurunuz oluşturulmuştur. Teşekkür ederiz.
              </Typography>
            </Stack> */}

            <Stack alignItems="center" gap={3} sx={{ py: 4 }}>
              <IconSuccess />

              <Typography
                sx={{
                  fontSize: "32px",
                  lineHeight: "40px",
                  textAlign: "center",
                }}
              >
                {typeParam === "mobile"
                  ? "Mobil Randevu Talebiniz Alınmıştır"
                  : "Randevunuz Başarıyla Oluşturulmuştur"}
              </Typography>
              {reservationParam && (
                <Typography sx={{fontSize:"23px"}}>
                  Randevu Tarihi/Saati:<br /> 
                  {reservationParam}
                </Typography>
              )}

              <Typography
                sx={{
                  fontSize: "24px",
                  lineHeight: "34px",
                  textAlign: "center",
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      typeParam === "mobile"
                        ? "Randevunuz en kısa sürede Müşteri Deneyimi ekibimiz tarafından değerlendirilecek<br/> Randevu bilgileri tarafınıza e-mail yolu ile iletilecektir.<br/>Herhangi bir değişiklik ya da ek bilgi için bizimle iletişime geçebilirsiniz."
                        : "Randevu bilgileriniz e-mail adresinize gönderilmiştir.<br/>Randevu saatinden <b>en az 10 dakika öncesinde</b> montaj noktasında olmanızı rica ederiz.<br/>Herhangi bir değişiklik ya da ek bilgi için bizimle iletişime geçebilirsiniz.",
                  }}
                />
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Container>
    </>
  );
}

