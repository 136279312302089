import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { CSSProperties } from "react";

const WhatsAppIcon = () => {
  const whatsappLink = "#";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <a
      href={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
      style={styles.container}
    >
      <img src={require("../assets/img/wp.png")} alt="WhatsApp" width={isMobile ? 100 : 150}/>
    </a>
  );
};

const styles: { container: CSSProperties; icon: CSSProperties } = {
  container: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: "1000",
    cursor: "pointer",
  },
  icon: {
    width: "50px",
    height: "50px",
  },
};

export default WhatsAppIcon;
