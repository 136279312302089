import {
  Container,
  Grid2,
  Box,
  Typography,
  Paper,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { ChangeEvent, ChangeEventHandler, forwardRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../components";
import { styled } from "styled-components";
import MaskedInput from "react-text-mask";
import { axiosInstance } from "../utils";
import { enqueueSnackbar } from "notistack";

const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100&;
`;

interface StyledInputProps {
  $error?: boolean;
}

const StyledInput = styled.input<StyledInputProps>`
  border: 1px solid ${(props) => (props.$error ? "red" : "#ccc")};
  outline: none;
  background: white;
  transition: border-color 0.2s;

  font-size: 20px;
  width: 100%;
  outline: none;
  height: 45px;
  border-radius: 4px;
  border: none;
  padding-left: 10px;

  &:focus {
    border-color: ${(props) => (props.$error ? "red" : "#007bff")};
  }
`;

const HelperText = styled.span<StyledInputProps>`
  font-size: 0.85rem;
  color: red;
  margin-top: 5px;
  visibility: ${(props) => (props.$error ? "visible" : "hidden")};
`;

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  helperText?: string;
}

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  ({ error, helperText, ...props }, ref) => {
    return (
      <StyledInputWrapper>
        <StyledInput ref={ref} {...props} $error={error} />
        <HelperText $error={error}>{helperText}</HelperText>
      </StyledInputWrapper>
    );
  }
);


export const TTS = () => {
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    phone: "",
    email: "",
    company_name: "",
    vehicle_quantity: "",
    consent: false,
  });

  const navigate = useNavigate();

  const [fieldErrors, setFieldErrors] = useState<any>({});

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let isValid = true;
    const {name, surname, phone, email, company_name, vehicle_quantity} = formData;
    if(!name){
      setFieldErrors((prev: any) => ({
        ...prev,
        name: "Ad alanı boş olamaz."
      }))
      isValid = false;
    }
    if(!surname){
      setFieldErrors((prev: any) => ({
        ...prev,
        surname: "Soyad alanı boş olamaz."
      }))
      isValid = false;
    }
    if (!email || !emailRegex.test(email)) {
      setFieldErrors((prev: any) => ({
        ...prev,
        email: "E-Posta alanı geçerli olmalıdır.",
      }));
      isValid = false;
    }
    if (!company_name) {
      setFieldErrors((prev: any) => ({
        ...prev,
        company_name: "Firma adı alanı boş olamaz.",
      }));
      isValid = false;
    }
    if (!phone || phone.length !== 17) {
      setFieldErrors((prev: any) => ({
        ...prev,
        phone: "Telefon numarası alanı geçerli olmalıdır.",
      }));
      isValid = false;
    }
    if (parseInt(vehicle_quantity) === 0 || !vehicle_quantity) {

      setFieldErrors((prev: any) => ({
        ...prev,
        vehicle_quantity: "Araç adedi geçerli bir sayı olmalıdır."
      }));

      isValid = false;
    }
      
    
    if (!isValid) return;

    const {vehicle_quantity: vq, ...w} = formData;

    const modified = {
      ...w,
      vehicle_quantity: parseInt(vq)
    };

    try{
      const response = await axiosInstance.post("/tts/create", modified);
      if(response.status === 201){
        enqueueSnackbar("Form başarıyla gönderilmiştir.", {variant: "success"});
        setTimeout(() => {
          navigate('/');
        }, 500);
      }
    }
    catch(e){
      enqueueSnackbar("Bir hata oluştu.", {variant: "error"});
    }
   
    
  };


  return (
    <Container sx={{ mt: 2 }}>
      <Header />
      <Box
        sx={{
          backgroundColor: "#002B32",
          borderBottomLeftRadius: "24px",
          borderBottomRightRadius: "24px",
          py: 4,
          px: 4,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <p
          style={{
            fontSize: "35px",
            alignSelf: "center",
            color: "#fff",
          }}
        >
          BİR TIKLA ARACINIZ İÇİN İNDİRİMLİ AKARYAKIT AYRICALIĞINI YAKALAYIN
        </p>

        <Box
          sx={{
            background: "#fff",
            py: 4,
            px: 2,
          }}
        >
          <Grid2 container spacing={4}>
            {/* <Grid2 size={{ xs: 12, md: 6 }}>
              <Box sx={{ pr: { md: 4 }, mb: { xs: 4, md: 0 } }}>
              

                <Typography
                  sx={{
                    fontWeight: 700,
                    marginBottom: 3,
                    color: "#333",
                    fontSize: { xs: "2rem", md: "2.5rem" },
                  }}
                >
                  Kit-Tak Shell Taşıt Tanıma Sistemi
                </Typography>

                <Typography
                  variant="h6"
                  sx={{
                    mb: 3,
                    color: "#555",
                    fontWeight: 400,
                    fontSize: { xs: "1.1rem", md: "1.25rem" },
                  }}
                >
                  Şirketinizin akaryakıt giderlerini kolayca yönetmek için
                  Kit-Tak'ı tercih edin!
                </Typography>

                <Typography
                  variant="h5"
                  sx={{
                    mb: 2,
                    color: "#444",
                    fontWeight: 500,
                    fontSize: { xs: "1.25rem", md: "1.5rem" },
                  }}
                >
                  Kit-Tak ile hem şirketiniz hem çalışanlarınız kazanacak!
                </Typography>

                <p style={{ marginBottom: 20, color: "#666" }}>
                  Şirket akaryakıt giderlerinizi ayda yalnızca 3 fatura ile
                  yönetmek ve Shell kalitesinden faydalanmak ister misiniz?
                  Hemen başvurun, sizi arayalım, nasıl olur anlatalım.
                </p>

                <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
                  <img
                    src="/images/multinet-w.webp"
                    alt="TTS Logo"
                    style={{ height: 40, width: "100%" }}
                  />
                 
                </Box>

                <p style={{ color: "#666" }}>
                  Shell Taşıt Tanıma Sistemi ile işveren olarak çalışanlarınız
                  için limitler belirleyebilir ve ödemeleri kolayca
                  yönetebilirsiniz. Böylece ekstra bir işlem yapmanıza gerek
                  kalmadan kolayca tüm süreci yönetebilirsiniz. Üstelik tüm
                  süreç sizin için raporlanır ve raporlara 7/24 internet
                  üzerinden erişebilirsiniz.
                </p>
              </Box>
            </Grid2> */}

            <Grid2 size={{ xs: 12, md: 12 }}>
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  borderRadius: 2,
                  backgroundColor: "warning.main",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    mb: 4,
                    color: "white",
                    textAlign: "center",
                    fontSize: { xs: "1.25rem", md: "1.5rem" },
                  }}
                >
                  SHELL TTS BAŞVUSU İÇİN BİLGİLERİNİZİ DOLDURUN, SİZİ ARAYALIM
                </Typography>

                <form
                  onSubmit={handleSubmit}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 12,
                  }}
                >

                  <CustomInput
                    placeholder="Ad"
                    onChange={(e) => {
                      if (e.target.value !== "" && fieldErrors?.name) {
                        setFieldErrors((f: any) => {
                          const { name, ...rest } = f;
                          return rest;
                        });
                      }
                      setFormData((prev: any) => ({
                        ...prev,
                        name: e.target.value,
                      }));
                    }}
                    value={formData.name}
                    error={!!fieldErrors?.name}
                    helperText={fieldErrors?.name && fieldErrors.name}
                  />

                
                  <CustomInput
                    placeholder="Soyad"
                    onChange={(e) => {
                      if (e.target.value !== "" && fieldErrors?.surname) {
                        setFieldErrors((f: any) => {
                          const { surname, ...rest } = f;
                          return rest;
                        });
                      }
                      setFormData((prev: any) => ({
                        ...prev,
                        surname: e.target.value,
                      }));
                    }}
                    value={formData.surname}
                    error={!!fieldErrors?.surname}
                    helperText={fieldErrors?.surname && fieldErrors.surname}
                  />

                  {/* <TextField
                    fullWidth
                    label="Telefon Numarası"
                    variant="outlined"
                    margin="normal"
                    value={formData.phone}
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                  /> */}

                  {/* <InputField
                    placeholder="Telefon Numarası"
                    onChange={(e) => {
                      setFormData((prev: any) => ({
                        ...prev,
                        phone: e.target.value,
                      }));
                    }}
                  /> */}

                  {/* <PhoneInput 
                    value={formData.phone}
                    onChange={(e) => {
                      setFormData((prev: any) => ({
                        ...prev,
                        phone: e.target.value
                      }))
                    }}
                  /> */}

                  <MaskedInput
                    value={formData.phone}
                    onChange={(e) => {
                      if (
                        e.target.value !== "" &&
                        e.target.value.length === 17 &&
                        fieldErrors?.phone
                      ) {
                        setFieldErrors((f: any) => {
                          const { phone, ...rest } = f;
                          return rest;
                        });
                      }
                      setFormData((prev: any) => ({
                        ...prev,
                        phone: e.target.value,
                      }));
                    }}
                    guide={false}
                    render={(innerRef, props) => (
                      <CustomInput
                        {...props}
                        ref={innerRef as any}
                        error={fieldErrors?.phone}
                        placeholder="Telefon Numarası"
                        helperText={fieldErrors?.phone && fieldErrors.phone}
                      />
                    )}
                    mask={[
                      "0",
                      " ",
                      "(",
                      /\d/,
                      /\d/,
                      /\d/,
                      ")",
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                    ]}
                  />
                  {/* <TextField
                    fullWidth
                    label="E-Mail Adresi"
                    variant="outlined"
                    margin="normal"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  /> */}

                  <CustomInput
                    placeholder="E-Mail Adresi"
                    onChange={(e) => {
                      if (e.target.value !== "" && fieldErrors?.email) {
                        setFieldErrors((f: any) => {
                          const { email, ...rest } = f;
                          return rest;
                        });
                      }
                      setFormData((prev: any) => ({
                        ...prev,
                        email: e.target.value,
                      }));
                    }}
                    value={formData.email}
                    error={!!fieldErrors?.email}
                    helperText={fieldErrors?.email && fieldErrors.email}
                  />

                  <CustomInput
                    placeholder="Firma Adı"
                    onChange={(e) => {
                      if (e.target.value !== "" && fieldErrors?.company_name) {
                        setFieldErrors((f: any) => {
                          const { company_name, ...rest } = f;
                          return rest;
                        });
                      }
                      setFormData((prev: any) => ({
                        ...prev,
                        company_name: e.target.value,
                      }));
                    }}
                    value={formData.company_name}
                    error={!!fieldErrors?.company_name}
                    helperText={
                      fieldErrors?.company_name && fieldErrors.company_name
                    }
                  />

                  <CustomInput
                    placeholder="Araç Adedi"
                    onChange={(e) => {
                      if (
                        e.target.value !== "" &&
                        parseInt(e.target.value) !== 0 &&
                        fieldErrors?.vehicle_quantity
                      ) {
                        setFieldErrors((f: any) => {
                          const { vehicle_quantity, ...rest } = f;
                          return rest;
                        });
                      }
                      setFormData((prev: any) => ({
                        ...prev,
                        vehicle_quantity: e.target.value,
                      }));
                    }}
                    value={formData.vehicle_quantity}
                    error={!!fieldErrors?.vehicle_quantity}
                    helperText={
                      fieldErrors?.vehicle_quantity &&
                      fieldErrors.vehicle_quantity
                    }
                    type="number"
                  />

                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.consent}
                        onChange={(e) =>
                          setFormData((prev: any) => ({
                            ...prev,
                            consent: e.target.checked,
                          }))
                        }
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "lightgreen",
                          },
                        }}
                      />
                    }
                    label={
                      <p style={{ color: "white", fontSize: "0.875rem" }}>
                        Tarafımca sunulan verilerin, Kit-Tak
                        A.Ş. tarafından tanıtım, promosyon, bilgilendirme vb.
                        amaçlarla kullanılmasına ve bu kapsamda tarafıma ticari
                        elektronik ileti gönderilmesine muvafakat ediyorum.
                      </p>
                    }
                    sx={{ mt: 2 }}
                  /> */}

                  {/* <p
                    style={{
                      color: "white",
                      marginTop: 2,
                      fontSize: "0.875rem",
                    }}
                  >
                    Kişisel verileriniz, talebinizin gerçekleştirilmesi amacıyla
                    6698 Sayılı Kanun kapsamında işlenmektedir.{" "}
                    <a
                      href="https://multinet.com.tr/sites/default/files/static/musteri-aydinlatma-metni.pdf"
                      target="_blank"
                      style={{
                        color: "#ffa62b",
                      }}
                    >
                      Aydınlatma Metnine buradan ulaşabilirsiniz.
                    </a>
                  </p> */}

                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    sx={{
                      mt: 4,
                      mb: 2,
                      bgcolor: "white",
                      py: 1.5,
                      fontSize: "1.1rem",
                      textTransform: "none",
                    }}
                    color="warning"
                  >
                    DETAYLI BİLGİ AL
                  </Button>
                </form>
              </Paper>
            </Grid2>
          </Grid2>
        </Box>
      </Box>
    </Container>
  );
};
