import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import { axiosInstance, fetcher } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context";
import { useMe } from "../../../hooks";
import { IMontageStatus } from "../../../interface";
import { CircularProgressModal } from "../../../components";

export default function DashboardSection() {
  const { user } = useAuth();
  const {me} = useMe();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const params = new URLSearchParams();
  if (user && user?.role === "service_provider") {
    params.append("service_provider", user?.id);
  }

  const { data: montageList, isLoading } = useSWR<any[]>(
    `/montage/application?${params.toString()}`,
    fetcher
  );


  const installationParams = new URLSearchParams();
  installationParams.append("Count", "true");
  if(me?.utts?.serviceName) {
    installationParams.append("ServiceName", me.utts.serviceName);
  }
  

  const { data: installationData } = useSWR<any>(
    `/utts/installation?${installationParams.toString()}`,
    fetcher
  );

  const stockParams = new URLSearchParams();
  stockParams.append("Count", "true");
  if(me?.utts?.compare?.stockName){
    stockParams.append("ServiceName", me.utts.compare.stockName);
  }

  const {data: stockData} = useSWR<any>(
    `/utts/stock?${stockParams.toString()}`,
    fetcher
  );

  const emptyInstallationData = installationData?.emptyActivationDateCount ?? 0;

  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();
  const todayStart = new Date(
    currentYear,
    currentMonth,
    now.getDate(),
    0,
    0,
    0,
    0
  );
  const todayEnd = new Date(
    currentYear,
    currentMonth,
    now.getDate(),
    23,
    59,
    59,
    999
  );

  // Bu ayki kayıtları filtrele
  // const monthlyRecordslength = montageList?.reduce((total, item) => {
  //   const validDates =
  //     item.application_date?.filter((app: any) => {
  //       const appDate = new Date(app);
  //       return (
  //         appDate.getFullYear() === currentYear &&
  //         appDate.getMonth() === currentMonth &&
  //         item.status === IMontageStatus.completed
  //       );
  //     }) || [];

    

  //   const quantitySum =
  //     validDates.length > 0
  //       ? item.vehicles?.reduce(
  //           (sum: number, vehicle: any) => sum + (vehicle.quantity || 0),
  //           0
  //         ) || 0
  //       : 0;

  //   return total + quantitySum;
  // }, 0);

  
  // Bugünkü kayıtları filtrele
  // const dailyRecords = montageList?.filter((item) => {
  //   const appDate = new Date(item.application_date);
  //   return appDate >= todayStart && appDate <= todayEnd;
  // });

  // const dailyRecords = montageList?.map((item) => {
  //   const appAvailable = item.application_date?.filter((app: any) => {
  //     const appDate = new Date(app);

  //     console.log("appdate ", appDate);
  //     console.log("todaystart, ", todayStart);
  //     console.log("todayend, ", todayEnd);
  //     return (
  //       appDate >= todayStart &&
  //       appDate <= todayEnd &&
  //       !item.is_rejected &&
  //       item.status == IMontageStatus.waiting
  //     );
  //   });
  //   return appAvailable.length || 0;
  // });

  const dailyRecordsLength = montageList?.reduce((total, item) => {
    const validDates =
      item.application_date?.filter((app: any) => {
        const appDate = new Date(app);
        return (
          appDate >= todayStart &&
          appDate <= todayEnd &&
          !item.is_rejected &&
          item.status == IMontageStatus.waiting
        );
      }) || [];

    const quantitySum =
      validDates.length > 0
        ? item.vehicles?.reduce(
            (sum: number, vehicle: any) => sum + (vehicle.quantity || 0),
            0
          ) || 0
        : 0;

    return total + quantitySum;
  }, 0);

  const completedTodayRecordsLength = montageList?.reduce((total, item) => {
    const validDates =
      item.application_date?.filter((app: any) => {
        const appDate = new Date(app);
        return (
          appDate >= todayStart &&
          appDate <= todayEnd &&
          !item.is_rejected &&
          item.status === IMontageStatus.completed
        );
      }) || [];

    const quantitySum =
      validDates.length > 0
        ? item.vehicles?.reduce(
            (sum: number, vehicle: any) => sum + (vehicle.quantity || 0),
            0
          ) || 0
        : 0;

    return total + quantitySum;
  }, 0);

  const futureRecordsLength = montageList?.reduce((total, item) => {
    const now = new Date().valueOf();

    const hasFutureDate = (item.application_date ?? []).some(
      (t: string) => new Date(t).valueOf() > now
    );

    const quantitySum =
      hasFutureDate &&
      item.status === IMontageStatus.waiting &&
      !item.is_rejected
        ? item.vehicles?.reduce(
            (sum: number, vehicle: any) => sum + (vehicle.quantity || 0),
            0
          ) || 0
        : 0;

    return total + quantitySum;
  }, 0);

  const boxStyle = {
    width: isMobile ? 300: 400,
    height: 180,
    backgroundColor: "orange",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 2,
    boxShadow: 3,
    padding: 2,
    cursor: "pointer",
    textAlign: "center",
    overflow: "hidden",
  };

  if(stockData?.count == null || installationData?.count == null)
  {
      return <CircularProgressModal />;
  }
  return (
    <Container>
      <Box sx={{ mx: 4, borderRadius: 4, p: 3 }}>
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              zIndex: 2,
              backgroundColor: "rgba(0,0,0,.3)",
              inset: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        )}

        {!isLoading && (
          <Stack spacing={1}>
            <Stack
              direction={isMobile ? "column" : "row"} // Küçük ekranda alt alta getir
              spacing={5} // Küçük ekranda daha küçük boşluklar
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{ ...boxStyle, bgcolor: "green" }}
                onClick={() => navigate("/admin")}
              >
                <Stack spacing={2} direction="column" alignItems="center">
                  <Typography sx={typographyStyle}>
                    Atanan Stok Adedi
                  </Typography>
                  <Divider sx={dividerStyle} />
                  <Typography sx={numberStyle}>{stockData?.count}</Typography>
                </Stack>
              </Box>

              <Box
                sx={{ ...boxStyle, bgcolor: "green" }}
                onClick={() => navigate("/admin")}
              >
                <Stack spacing={2} direction="column" alignItems="center">
                  <Typography sx={typographyStyle}>
                    Aktivasyon Tamamlandı Montaj Adedi
                  </Typography>
                  <Divider sx={dividerStyle} />
                  <Typography sx={numberStyle}>
                    {(installationData?.count ?? 0) - emptyInstallationData}
                  </Typography>
                </Stack>
              </Box>

              <Box
                sx={{ ...boxStyle, bgcolor: "red" }}
                onClick={() => navigate("/admin")}
              >
                <Stack spacing={2} direction="column" alignItems="center">
                  <Typography sx={typographyStyle}>
                    Tamamlanmayan Montaj Adedi
                  </Typography>
                  <Divider sx={dividerStyle} />
                  <Typography sx={numberStyle}>
                    {emptyInstallationData}
                  </Typography>
                </Stack>
              </Box>

              <Box
                sx={{ ...boxStyle, bgcolor: "#0000a6" }}
                onClick={() => navigate("/admin")}
              >
                <Stack spacing={2} direction="column" alignItems="center">
                  <Typography sx={typographyStyle}>
                    Günlük Araç Montajı Sayısı
                  </Typography>
                  <Divider sx={dividerStyle} />
                  <Typography sx={numberStyle}>
                    {dailyRecordsLength !== 0
                      ? `${completedTodayRecordsLength}/${dailyRecordsLength} Tamamlandı`
                      : dailyRecordsLength}
                  </Typography>
                </Stack>
              </Box>

              <Box sx={boxStyle} onClick={() => navigate("/admin")}>
                <Stack spacing={2} direction="column" alignItems="center">
                  <Typography sx={typographyStyle}>
                    İleri Tarihli Araç Montajı Sayısı
                  </Typography>
                  <Divider sx={dividerStyle} />
                  <Typography sx={numberStyle}>
                    {futureRecordsLength}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        )}
      </Box>
    </Container>
    // <Container>
    //   <Box sx={{ mx: 4, borderRadius: 4, p: 3 }}>
    //     {isLoading && (
    //       <Box
    //         sx={{
    //           position: "absolute",
    //           zIndex: 2,
    //           backgroundColor: "rgba(0,0,0,.3)",
    //           inset: 0,
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "center",
    //         }}
    //       >
    //         <CircularProgress color="secondary" />
    //       </Box>
    //     )}

    //     {!isLoading && (
    //       <Stack spacing={25}>
    //         <Stack direction= {isMobile ? "column":"row"} spacing={10} justifyContent="center">
    //           <Box sx={boxStyle} onClick={() => navigate("/admin")} >
    //             <Stack spacing={4} direction="column" >
    //               <Typography sx={{ color: "white", fontSize: 23 }}>
    //                 Tamamlanan Aylık Montaj Sayısı
    //               </Typography>
    //               <Divider
    //                 sx={{
    //                   width: "100%",
    //                   height: "2px",
    //                   backgroundColor: "white",
    //                 }}
    //               />
    //               <Typography
    //                 sx={{
    //                   fontWeight: "bold",
    //                   fontSize: 27,
    //                   color: "white",
    //                 }}
    //               >
    //                 {monthlyRecordslength}
    //               </Typography>
    //             </Stack>
    //           </Box>
    //           <Box sx={boxStyle} onClick={() => navigate("/admin")} >
    //             <Stack spacing={4} direction="column">
    //               <Typography sx={{ color: "white", fontSize: 23 }}>
    //                 Günlük Montaj Sayısı
    //               </Typography>
    //               <Divider
    //                 sx={{
    //                   width: "100%",
    //                   height: "2px",
    //                   backgroundColor: "white",
    //                 }}
    //               />
    //               <Typography
    //                 sx={{
    //                   fontWeight: "bold",
    //                   fontSize: 27,
    //                   color: "white",
    //                 }}
    //               >
    //                 {dailyRecordsLength != 0
    //                   ? String(
    //                       `${completedTodayRecordsLength}/${dailyRecordsLength} Tamamlandı`
    //                     )
    //                   : String(dailyRecordsLength)}
    //               </Typography>
    //             </Stack>
    //           </Box>
    //           <Box sx={boxStyle} onClick={() => navigate("/admin")}>
    //             <Stack spacing={4} direction="column">
    //               <Typography sx={{ color: "white", fontSize: 23 }}>
    //                 İleri Tarihli Montaj Sayısı
    //               </Typography>
    //               <Divider
    //                 sx={{
    //                   width: "100%",
    //                   height: "2px",
    //                   backgroundColor: "white",
    //                 }}
    //               />
    //               <Typography
    //                 sx={{
    //                   fontWeight: "bold",
    //                   fontSize: 27,
    //                   color: "white",
    //                 }}
    //               >
    //                 {futureRecordsLength}
    //               </Typography>
    //             </Stack>
    //           </Box>
    //         </Stack>
    //         {/* <Stack direction="row" spacing={25} justifyContent="center">

    //         </Stack> */}
    //       </Stack>
    //     )}
    //   </Box>
    // </Container>
  );
}



const typographyStyle = {
  color: "white",
  fontSize: 20,
  fontWeight: "bold",
  textAlign: "center",
  overflow: "hidden",
  wordBreak: "break-word",
  whiteSpace: "normal",
};

const numberStyle = {
  fontWeight: "bold",
  fontSize: 24,
  color: "white",
  textAlign: "center",
};

const dividerStyle = {
  width: "80%",
  height: "2px",
  backgroundColor: "white",
};
