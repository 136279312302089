import useSWR from "swr";
import { fDateTime, fetcher } from "../../../utils";
import {
  Box,
  ButtonBase,
  Chip,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useDebouncedValue, useMe } from "../../../hooks";
import {
  IServiceProvider,
  ServiceDays,
  ServiceProviderStatusLabels,
  ServiceStatusList,
} from "../../../interface";
import { Link, Navigate, useLocation, useSearchParams } from "react-router-dom";
import { PATHS } from "../../../routes";
import { Iconify } from "../../../components";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context";
import { UploadZone } from "../../service";
import { Days } from "../../../data";
import {saveAs} from "file-saver";
import * as XLSX from "xlsx";
import { ServiceProvider } from "./ServiceFormSection";

const TableLableStyle = {
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 500,
  color: "#3D3D3D",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.grey[100],
  },
}));

const getChipColor = (status: string): any => {
  switch (status) {
    case ServiceStatusList.ACCEPTED:
      return "success";
    case ServiceStatusList.APPROVED_BY_MINT:
    case ServiceStatusList.FIELD_CONTROL:
      return "warning";
    case ServiceStatusList.REJECTED:
      return "error";
    case ServiceStatusList.PENDING_APPROVAL:
    case ServiceStatusList.ONBOARDING:
      return "info";
    default:
      return "inherit";
  }
};

export const ServiceListSection = () => {
  const [searchParams] = useSearchParams();
  const [debouncedSearch, search, setSearch] = useDebouncedValue("");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (searchParams.get("search")) {
      setSearch(searchParams.get("search")!);
    }
  }, [searchParams.get("search")]);

  const params = new URLSearchParams();
  if (debouncedSearch) params.append("search", debouncedSearch);

  params.append("status", ServiceStatusList.PENDING_APPROVAL);
  params.append("status", ServiceStatusList.REJECTED);
  params.append("ow", "true");
  
  const { data, isLoading } = useSWR<IServiceProvider[]>(
    `/service-provider/query?${params.toString()}`,
    fetcher
  );

  let filteredData: IServiceProvider[] = [];

  const statusOrder = {
    [ServiceStatusList.FIELD_CONTROL]: 1,
    [ServiceStatusList.APPROVED_BY_MINT]: 2,
    [ServiceStatusList.ONBOARDING]: 3,
    [ServiceStatusList.SET_PASSWORD]: 4,
    [ServiceStatusList.ACCEPTED]: 5
  }

  if(!isLoading && data){
    filteredData = data.sort((a, b) => statusOrder[a.status] - statusOrder[b.status])
      .filter(f => f.status != ServiceStatusList.ACCEPTED);

    if (filter && filter != ServiceStatusList.ACCEPTED) {
      filteredData = data.filter(
        (f) => f.status === filter);

    }
    else if (filter && filter == ServiceStatusList.ACCEPTED) {
       filteredData = data.filter(
         (f) => f.status == ServiceStatusList.ACCEPTED
       );
    }

  }


  const paramsCount = new URLSearchParams();
  paramsCount.append("status", ServiceStatusList.PENDING_APPROVAL);
  const { data: waitingList } = useSWR<any[]>(
    `/service-provider/query?${paramsCount.toString()}`,
    fetcher
  );
  const waitingCount = waitingList?.length ?? -1;
  const exportToExcel = () => {
        const header = [
          "ID",
          "Yetkili Kişi Adı",
          "Yetkili Kişi E-mail",
          "Yetkili Kişi Telefon",
          "Referans",
          "Montaj Noktası Tabela İsmi",
          "Firma Unvanı",
          "Vergi Dairesi",
          "Vergi Numarası",
          "Şehir",
          "İlçe",
          "Adres",
          "İşletme Tipi",
          "İşyeri Tipi",
          "Toplam Çalışan Sayısı",
          "Toplam Çalışan Sayısı",
          "Randevu Telefonu",
          "Çalışma Günleri",
          "Çalışma Saatleri",
          "Toplam Araç Kapasitesi",
          "Montaj Hizmeti için Uygun Araç Sayısı",
          "Montaj Alanında İnternet Hizmeti",
          "Teknisyen",
          "Araç Tipi",
          "Durum",
          "Başvuru Tarihi",
        ];
    
        const rows = [
          header,
          ...(data ?? []).map((item) => [
            item._id ?? "",
            item.authorized_person_name ?? "",
            item.authorized_person_email ?? "",
            item.authorized_person_phone ?? "",
            item.reference ?? "",
            item.signage_name ?? "",
            item.offical_title ?? "",
            item.tax_office ?? "",
            item.tax_number ?? "",
            item.address?.city ?? "",
            item.address?.district ?? "",
            item.address?.fullAddress ?? "",
            item.business_type ?? "",
            item.workplace_type ?? "",
            item.total_employees ?? "",
            item.employees_for_installation ?? "",
            item.appointment_phone ?? "",
            (item.working_days ?? [])
              .sort((a: any, b: any) => Days.indexOf(a) - Days.indexOf(b))
              .map((r: any) => (ServiceDays as any)[r])
              .join(", "),
            (item.working_hours ?? []).map((hour: any) => hour).join(","),
            item.total_vehicle_capacity ?? "",
            item.vehicles_for_installation ?? "",
            item.has_internet_service ? "Var" : "Yok",
            (item.technicians ?? [])
              .map((tech: any) => `${tech.name}:${tech.email}:${tech.phone}`)
              .join("  /  "),
            (item.vehicle_types_for_service ?? []).map((v: any) => v).join("-"),
            // @ts-ignore
            ServiceProviderStatusLabels[item.status] ?? "",
            fDateTime(item.createdAt) ?? "",
          ]),
        ];
    
        const ws = XLSX.utils.aoa_to_sheet(rows);
  
        ws["!cols"] = header.map(() => ({ wch: 30 })); 
  
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    
        saveAs(blob, `bayiler-${new Date().valueOf()}.xlsx`);
      };
      
  return (
    <Stack gap={2}>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        direction="row"
      >
        <Stack direction="column" gap={1}>
          <TextField
            placeholder="Ara"
            sx={{ maxWidth: 350 }}
            size="small"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <Typography fontWeight="bold">Bayiler</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={2}>
          {filter && (
            <IconButton
              onClick={() => {
                setFilter("");
              }}
            >
              <Iconify icon="ic:round-clear" />
            </IconButton>
          )}

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="status-filter-serv-provider">
              Bayi durumu
            </InputLabel>
            <Select
              labelId="status-filter-serv-provider"
              id="status-filter-serv-provider"
              label="Bayi durumu"
              onChange={(e) => {
                setFilter(e.target.value as string);
                console.log(e.target.value);
              }}
              value={filter}
            >
              {Object.entries(ServiceProviderStatusLabels as any).sort((a,b) => statusOrder[a[0] as any] - statusOrder[b[0] as any]).map(
                (value, key) =>
                  value[0] != "Pending Approval" &&
                  value[0] != "Rejected" && (
                    <MenuItem key={key} value={value[0]}>{value[1] as any}</MenuItem>
                  )
              )}
            </Select>
          </FormControl>

          <Link to={PATHS.admin.servicesProviders.application}>
            <Stack direction="row" alignItems="center" gap={1}>
              Bayi Başvuruları
              {waitingCount > 0 ? ` (${waitingCount})` : ""}
              <Iconify icon="solar:alt-arrow-right-line-duotone" />
            </Stack>
          </Link>
        </Stack>
      </Stack>
      <TableContainer sx={{ maxHeight: "70vh", overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ backgroundColor: "grey.200" }}>
              <TableCell
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }}
              >
                <Typography sx={TableLableStyle}>
                  {filter === ServiceStatusList.ACCEPTED ? "Bayi Kodu" : "Bayi Başvuru Tarihi"}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }}
              >
                <Typography sx={TableLableStyle}>Bayi</Typography>
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }}
              >
                <Typography sx={TableLableStyle}>Şehir</Typography>
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }}
              >
                <Typography sx={TableLableStyle}>Cihaz Sayısı</Typography>
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }}
              >
                <Typography sx={TableLableStyle}>Mobil Cihaz Sayısı</Typography>
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }}
              >
                <Typography sx={TableLableStyle}>Bayi Durumu</Typography>
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }}
              >
                {data?.length ? (
                  <ButtonBase
                    onClick={() => {
                      exportToExcel();
                    }}
                    sx={{
                      px: 1,
                      borderRadius: 2,
                      fontFamily: "inherit",
                      gap: 1,
                    }}
                  >
                    Tümünü İndir
                    <Iconify icon="vscode-icons:file-type-excel" />
                  </ButtonBase>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && !data?.length && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography sx={{ textAlign: "center", opacity: 0.75 }}>
                    Veri Bulunamadı
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {isLoading && !data?.length && (
              <>
                {Array(6)
                  .fill("")
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </>
            )}
            {filteredData?.map((row, index) => (
                    <StyledTableRow key={index}>
                      <TableCell>
                        <Typography sx={{}}>
                          {filter === ServiceStatusList.ACCEPTED ? (row.service_provider_code ?? "-") : fDateTime(row.createdAt)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={TableLableStyle}>
                          {(row.offical_title as string).toLocaleUpperCase()}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={TableLableStyle}>
                          {row?.address?.city ?? "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={TableLableStyle}>
                          {row?.device_count ?? 0}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={TableLableStyle}>
                          {row?.mobile_device_count ?? 0}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip
                          size="small"
                          color={getChipColor(row.status)}
                          label={
                            (ServiceProviderStatusLabels as any)[row.status]
                          }
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          to={PATHS.admin.servicesProviders.profile.get(
                            row._id
                          )}
                        >
                          <ButtonBase
                            sx={{ fontFamily: "unset", fontSize: 16 }}
                          >
                            Profile Git
                            <Iconify icon="solar:alt-arrow-right-line-duotone" />
                          </ButtonBase>
                        </Link>
                      </TableCell>
                    </StyledTableRow>
                  ))
              /*: data?.map((row, index) => (
                  <StyledTableRow key={index}>
                    <TableCell>
                      <Typography sx={{}}>
                        {fDateTime(row.createdAt)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableLableStyle}>
                        {(row.offical_title as string).toLocaleUpperCase()}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableLableStyle}>
                        {row?.address?.city ?? "-"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableLableStyle}>
                        {row?.device_count ?? 0}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableLableStyle}>
                        {row?.mobile_device_count ?? 0}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        size="small"
                        color={getChipColor(row.status)}
                        label={(ServiceProviderStatusLabels as any)[row.status]}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Link
                        to={PATHS.admin.servicesProviders.profile.get(row._id)}
                      >
                        <ButtonBase sx={{ fontFamily: "unset", fontSize: 16 }}>
                          Profile Git
                          <Iconify icon="solar:alt-arrow-right-line-duotone" />
                        </ButtonBase>
                      </Link>
                    </TableCell>
                  </StyledTableRow>
                ))*/}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
