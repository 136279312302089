import {
  Box,
  ButtonBase,
  Chip,
  ChipPropsColorOverrides,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { Iconify, ServiceDetailModal } from "../../../components";
import { ServiceDays, ServiceProviderStatusLabels, ServiceStatusList } from "../../../interface";
import { fDateTime, fetcher } from "../../../utils";
import { PATHS } from "../../../routes";
import { Link, useSearchParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useDebouncedValue } from "../../../hooks";
import { parseISO } from "date-fns";
import { format, toZonedTime } from "date-fns-tz";
import { tr } from "date-fns/locale";
import * as XLSX from "xlsx"
import { saveAs } from "file-saver";
import { Days } from "../../../data";

const TableLableStyle = {
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 500,
  color: "#3D3D3D",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.grey[100],
  },
}));

export const ServiceAppListSection = () => {
  const [searchParams] = useSearchParams();
    const [selectedService, setSelectedService] = useState<any>(null);
  const [statusFilter, setStatusFilter] = useState(ServiceStatusList.PENDING_APPROVAL);
  const [debouncedSearch, search, setSearch] = useDebouncedValue("");

  const params = new URLSearchParams();

  useEffect(() => {
      if (searchParams.get("search")) {
        setSearch(searchParams.get("search")!);
      }
    }, [searchParams.get("search")]);

    if(debouncedSearch)
      params.append("search", debouncedSearch);

    
    params.append("status", ServiceStatusList.PENDING_APPROVAL);
    params.append("status", ServiceStatusList.REJECTED);

  const { data, isLoading } = useSWR<any[]>(
    `/service-provider/query?${params.toString()}`,
    fetcher
  );
  

    const exportToExcel = () => {
      const header = [
        "ID",
        "Yetkili Kişi Adı",
        "Yetkili Kişi E-mail",
        "Yetkili Kişi Telefon",
        "Referans",
        "Montaj Noktası Tabela İsmi",
        "Firma Unvanı",
        "Vergi Dairesi",
        "Vergi Numarası",
        "Şehir",
        "İlçe",
        "Adres",
        "Enlem",
        "Boylam",
        "İşletme Tipi",
        "İşyeri Tipi",
        "Toplam Çalışan Sayısı",
        "Toplam Çalışan Sayısı",
        "Randevu Telefonu",
        "Çalışma Günleri",
        "Çalışma Saatleri",
        "Toplam Araç Kapasitesi",
        "Montaj Hizmeti için Uygun Araç Sayısı",
        "Montaj Alanında İnternet Hizmeti",
        "Teknisyen",
        "Araç Tipi",
        "Durum",
        "Başvuru Tarihi",
      ];
  
      const rows = [
        header,
        ...(data ?? []).map((item) => [
          item._id ?? "",
          item.authorized_person_name ?? "",
          item.authorized_person_email ?? "",
          item.authorized_person_phone ?? "",
          item.reference ?? "",
          item.signage_name ?? "",
          item.offical_title ?? "",
          item.tax_office ?? "",
          item.tax_number ?? "",
          item.address?.city ?? "",
          item.address?.district ?? "",
          item.address?.fullAddress ?? "",
          item.lat ?? "",
          item.lng ?? "",
          item.business_type ?? "",
          item.workplace_type ?? "",
          item.total_employees ?? "",
          item.employees_for_installation ?? "",
          item.appointment_phone ?? "",
          (item.working_days ?? [])
            .sort((a: any, b: any) => Days.indexOf(a) - Days.indexOf(b))
            .map((r: any) => (ServiceDays as any)[r])
            .join(", "),
          (item.working_hours ?? []).map((hour: any) => hour).join(","),
          item.total_vehicle_capacity ?? "",
          item.vehicles_for_installation ?? "",
          item.has_internet_service ? "Var" : "Yok",
          (item.technicians ?? [])
            .map((tech: any) => `${tech.name}:${tech.email}:${tech.phone}`)
            .join(" /  "),
          (item.vehicle_types_for_service ?? []).map((v: any) => v).join("-"),
          // @ts-ignore
          ServiceProviderStatusLabels[item.status] ?? "",
          fDateTime(item.createdAt) ?? "",
        ]),
      ];
  
      const ws = XLSX.utils.aoa_to_sheet(rows);

      ws["!cols"] = header.map(() => ({ wch: 30 })); 

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  
      saveAs(blob, `basvurular-${new Date().valueOf()}.xlsx`);
    };
  
  const SetChipColor: React.FC<{ status: string }> = ({status}) => {
    let color: any;
    let label;
    switch (status) {
      case ServiceStatusList.PENDING_APPROVAL:
        label =
          ServiceProviderStatusLabels[
            status as keyof typeof ServiceProviderStatusLabels
          ];
        color = "warning";
        break;
      case ServiceStatusList.ACCEPTED:
        label =
          ServiceProviderStatusLabels[
            status as keyof typeof ServiceProviderStatusLabels
          ];
        color = "success";
        break;
      case ServiceStatusList.REJECTED:
        label =
          ServiceProviderStatusLabels[
            status as keyof typeof ServiceProviderStatusLabels
          ];
        color = "error";
        break;
      case ServiceStatusList.ONBOARDING:
        label =
          ServiceProviderStatusLabels[
            status as keyof typeof ServiceProviderStatusLabels
          ];
        color = "info";
        break;
      case ServiceStatusList.APPROVED_BY_MINT:
        label =
          ServiceProviderStatusLabels[
            status as keyof typeof ServiceProviderStatusLabels
          ];
        color = "info";
        break;
      case ServiceStatusList.FIELD_CONTROL:
        label =
          ServiceProviderStatusLabels[
            status as keyof typeof ServiceProviderStatusLabels
          ];
        color = "info";
        break;
      case ServiceStatusList.SET_PASSWORD:
        label =
          ServiceProviderStatusLabels[
            status as keyof typeof ServiceProviderStatusLabels
          ];
        color = "info";
        break;
      default:
        color = "primary";
        label = "Bilinmeyen";
        break;
    }
    return <Chip label={label} color={color} variant="outlined" />;
  };
  
  return (
    <Stack gap={2} sx={{ minHeight: 1200 }}>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        direction="row"
        sx={{ height: 40 }}
      >
        <Stack direction="column" gap={1}>
          <TextField
            placeholder="Ara"
            sx={{ maxWidth: 350 }}
            size="small"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Typography fontWeight="bold">Bayi başvuruları</Typography>
        </Stack>

        {/* Sağ tarafa yaslanacak içerikler için ayrı bir Stack */}
        <Stack direction="row" alignItems="center" gap={2}>
          {statusFilter && (
            <IconButton
              onClick={() => {
                setStatusFilter("");
              }}
            >
              <Iconify icon="ic:round-clear" />
            </IconButton>
          )}
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="status-filter">Durum</InputLabel>
            <Select
              labelId="status-filter"
              id="status-filter"
              label="Filtrele"
              onChange={(e) => {
                setStatusFilter(e.target.value as string);
              }}
              value={statusFilter}
            >
              <MenuItem value={ServiceStatusList.PENDING_APPROVAL}>
                Onay Bekliyor
              </MenuItem>
              <MenuItem value={ServiceStatusList.REJECTED}>Reddedildi</MenuItem>
              {/* {Object.entries(ServiceStatusList).map(([key, value]) => (
                <MenuItem value={value}>
                  {
                    ServiceProviderStatusLabels[
                      value as keyof typeof ServiceProviderStatusLabels
                    ]
                  }
                </MenuItem>
              ))} */}
            </Select>
          </FormControl>

          <Link to={PATHS.admin.servicesProviders.root}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Iconify icon="solar:alt-arrow-left-line-duotone" />
              Geri Dön
            </Stack>
          </Link>
        </Stack>
      </Stack>

      <ServiceDetailModal
        isModal
        selectedService={selectedService}
        setSelectedService={setSelectedService}
  
      />
      <TableContainer
        sx={!selectedService ? { maxHeight: "70vh", overflow: "auto" }: {}}
      >
        <Table {...(!selectedService ? { stickyHeader: true }: {stickyHeader: false})}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "grey.200" }}>
              <TableCell
                sx={!selectedService ?{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }: {}}
              >
                <Typography sx={TableLableStyle}>Başvuru Tarihi</Typography>
              </TableCell>
              <TableCell
                sx={!selectedService ? {
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }: {}}
              >
                <Typography sx={TableLableStyle}>Firma Adı</Typography>
              </TableCell>
              <TableCell
                sx={!selectedService ? {
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }:{}}
              >
                <Typography sx={TableLableStyle}>Yetkili Kişi</Typography>
              </TableCell>
              <TableCell
                sx={!selectedService ? {
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }: {}}
              >
                <Typography sx={TableLableStyle}>Şehir</Typography>
              </TableCell>
              <TableCell
                sx={!selectedService ?{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }: {}}
              >
                <Typography sx={TableLableStyle}>Durum</Typography>
              </TableCell>
              <TableCell
                align="right"
                sx={!selectedService ? {
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }: {}}
              >
                {data?.length ? (
                  <ButtonBase
                    onClick={() => {
                      exportToExcel();
                    }}
                    sx={{
                      px: 1,
                      borderRadius: 2,
                      fontFamily: "inherit",
                      gap: 1,
                    }}
                  >
                    Tümünü İndir
                    <Iconify icon="vscode-icons:file-type-excel" />
                  </ButtonBase>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && !data?.length && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography sx={{ textAlign: "center", opacity: 0.75 }}>
                    Veri Bulunamadı
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {isLoading && (
              <>
                {Array(5)
                  .fill("")
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </>
            )}
            {data
              ?.filter((x) => !statusFilter || x.status === statusFilter)
              .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
              .map((row, index) => (
                <StyledTableRow key={index}>
                  <TableCell>
                    <Typography sx={{}}>{fDateTime(row.createdAt)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={TableLableStyle}>
                      {(row.offical_title as string).toLocaleUpperCase()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={TableLableStyle}>
                      {(
                        row.authorized_person_name as string
                      ).toLocaleUpperCase()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={TableLableStyle}>
                      {(row.address.city as string).toLocaleUpperCase()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={TableLableStyle}>
                      {/* <Chip
                        label={
                          row.status == ServiceStatusList.PENDING_APPROVAL
                            ? "Onay Bekliyor"
                            : "Onaylandı"
                        }
                        color={
                          row.status == ServiceStatusList.PENDING_APPROVAL
                            ? "warning"
                            : "success"
                        }
                        variant="outlined"
                      /> */}
                      <SetChipColor status={row.status} />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Stack
                      gap={1}
                      direction="row"
                      alignItems="center"
                      justifyContent={"end"}
                    >
                      {/*<IconButton color="success">
                        <Iconify icon="ic:baseline-check" /> 
                        <Icon
                          icon="basil:cross-solid"
                          width="30"
                          height="30"
                          style={{ color: "#f03" }}
                        />
                      </IconButton>*/}
                      <Box
                        sx={{
                          width: "1px",
                          backgroundColor: "divider",
                          height: "16px",
                        }}
                      />
                      <IconButton
                        onClick={() => {
                          setSelectedService(row);
                        }}
                      >
                        <Iconify icon="ic:baseline-remove-red-eye" />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
