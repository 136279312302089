import React from 'react'
import { AdminLayout } from '../../../components'
import { ProspectListSection } from '../../../section/admin/prospect'

export default function ProspectPage() {
  return (
    <AdminLayout>
      <ProspectListSection />
    </AdminLayout>
  )
}
