import React, { useMemo } from "react";
import {
  Box,
  Grid,
  Icon,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Footer, Header } from "../components";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const UttsNedir = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentStep, setCurrentStep] = React.useState(0);

  const renderSteps = useMemo(() => {
    switch (currentStep) {
      case 0:
        return (
          <Grid container mt={8} px={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="h4"
                sx={{
                  fontSize: 20,
                  color: "#008765",
                  fontWeight: "bold",
                  textAlign: "center",
                  mt: 4,
                }}
              >
                UTTPS NEDEN GEREKLİ?
              </Typography>
              <Box px={{ xs: 2, sm: 0, md: 0 }}>
                <Typography
                  sx={{
                    fontSize: 16,
                    color: "#191C22",
                    textAlign: "center",
                    mt: 2,
                  }}
                >
                  UTTS, vergi mükellefi araç sahiplerinin akaryakıt giderlerini
                  muhasebe kayıtlarında gider olarak{isMobile ? "" : <br></br>}{" "}
                  gösterebilmeleri amacıyla kurgulanmış, vergi kayıplarını
                  önleyen bir sistemdir.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                backgroundColor: "#E6F3F0",
                borderRadius: "8px",
                my: { xs: 1, sm: 6, md: 6 },
                p: 3,
              }}
            >
              <Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: 32,
                      color: "#000",
                      fontWeight: "500",
                    }}
                  >
                    Ulusal Taşıt Tanıma Sistemi Tebliği
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    pt={1}
                    pb={5}
                    sx={{
                      fontSize: 18,
                      color: "#1C1B1C",
                      fontWeight: "400",
                    }}
                  >
                    UTTS, vergi mükellefi araç sahiplerinin akaryakıt
                    giderlerini muhasebe kayıtlarında gider olarak
                    gösterebilmeleri amacıyla kurgulanmış, vergi kayıplarını
                    önleyen bir sistemdir.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              bgcolor={"white"}
              height={440}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                borderRadius: "8px",
                my: { xs: 1, sm: 6, md: 6 },
                height: "100%",
              }}
            >
              <img
                style={{ maxWidth: "100%", height: "auto" }}
                src={require("../assets/img/uttsnedir.webp")}
                alt="UTTS"
              />
            </Grid>
          </Grid>
        );
      default:
        return <Box>Step not found</Box>;
    }
  }, [currentStep]);

  return (
    <Box mx={{ xs: 2, sm: 6, md: 6 }} my={2}>
      <Header />
      <Box
        py={3}
        sx={{
          backgroundColor: "#002B32",
          borderBottomLeftRadius: "24px",
          borderBottomRightRadius: "24px",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          px={5}
        >
          <Typography
            pt={2}
            variant="h2"
            sx={{
              fontSize: { xs: 15, sm: 25, md: 40, lg: 50, xl: 60 },
              fontWeight: "600",
              color: "#fff",
              textAlign: "center",
            }}
          >
            UTTS Nedir?
          </Typography>
        </Box>

        <Box
          sx={{
            backgroundColor: "#F6F6F6",
            borderRadius: "8px",
            mx: { xs: 1, sm: 2, md: 4 },
          }}
        >
          {renderSteps}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default UttsNedir;
