import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  useMapEvent,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { MapProvider, useOpenStreetMap } from "../../context/MapContext";
import { axiosInstance } from "../../utils/axios";
import { IServiceProvider } from "../../interface";
import { Button, Stack, Typography } from "@mui/material";

// const ImportHeaders = () => (
//   <>
//     <link
//       rel="stylesheet"
//       href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
//       integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY="
//       crossOrigin=""
//     />
//     <script src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
//      integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo="
//      crossOrigin=""></script>

//   </>
// );

const icon = new L.Icon({
  iconUrl: process.env.PUBLIC_URL + "/location-pin-red.png",
  iconSize: [40, 40],
});

interface Props {
  coordinates: {
    lat: number;
    lng: number;
  };
  zoom: number;
  onMapClick: (event: L.LeafletMouseEvent) => void;
  services?: IServiceProvider[];
  scrollWheelZoom?: boolean;
  isFormRendered?: boolean;
  setShow?: Dispatch<SetStateAction<boolean>>;
  form?: any;
  setForm?: Dispatch<SetStateAction<any>>;  
}

const MapClickHandler: React.FC<{
  onClick?: (event: L.LeafletMouseEvent) => void;
}> = ({ onClick }) => {
  useMapEvent("click", (event) => {
    if (onClick) onClick(event);
  });
  return null;
};

const ChangeView: React.FC<{ center: L.LatLngExpression; zoom: number }> = ({
  center,
  zoom,
}) => {
  const map = useMap();
  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom, map]);
  return null;
};

export function OpenStreetMap(props: Props) {
  const { map: currentMap, ...map } = useOpenStreetMap();

  // useEffect(() => {
  //   console.log(currentMap);
  //   currentMap?.locate();
  //   currentMap?.on("locationfound", (e: L.LocationEvent) => {
  //     map.setCoordinates(e.latlng);
  //     currentMap.flyTo(e.latlng, 16);
  //   });

  //   return () => {
  //     currentMap?.off("locationfound");
  //   };
  // }, [currentMap]);

  const mapRef = useRef<L.Map | null>(null);
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView(props.coordinates, mapRef.current.getZoom());
    }
  }, [props.coordinates]);

  const handleMarkerClick = (event: any, index: number) => {
    const { lat, lng } = event.latlng;
    map.setCoordinates({ lat, lng });
    map.setZoom(16);
  };

  return (
    <MapProvider>
      {/* <ImportHeaders /> */}
      <MapContainer
        center={[map.coordinates.lat, map.coordinates.lng]}
        zoom={map.zoom}
        scrollWheelZoom={props.scrollWheelZoom ?? false}
        style={{ width: "100%", height: 500 }}
        zoomControl={false}
        attributionControl={false}
      >
        <LayersControl position="topleft">
          {/* Normal Harita */}
          <LayersControl.BaseLayer checked name="Normal Harita">
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
            />
          </LayersControl.BaseLayer>

          {/* Hibrit (Uydu + Yol bilgileri) */}
          <LayersControl.BaseLayer name="Uydu">
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        <TileLayer
          url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
        />
        <ZoomControl position="topright" />
        <MapClickHandler onClick={props.onMapClick} />
        <ChangeView center={map.coordinates} zoom={map.zoom} />
        {!props.isFormRendered && (
          <Marker icon={icon} position={map.coordinates}></Marker>
        )}
        {props.services?.length &&
          props.services.map((service, index) => {
            if (!service?.coordinates?.lat || !service?.coordinates?.lng)
              return;
            return (
              <Marker
                eventHandlers={{
                  click: (event) => handleMarkerClick(event, index),
                }}
                key={index}
                position={[service.coordinates?.lat, service.coordinates?.lng]}
                icon={icon}
              >
                <Popup>
                  <Stack direction="column" spacing={2}>
                    <Typography>{service.signage_name}</Typography>
                    <Stack direction="row" spacing={2}>
                      <Button
                        onClick={() => {
                          window.open(
                            `https://www.google.com/maps/search/${service?.coordinates?.lat},${service?.coordinates?.lng}`,
                            "_blank"
                          );
                        }}
                        sx={{
                          textTransform: "none",
                          alignSelf: "flex-start",
                        }}
                      >
                        Yol Tarifi
                      </Button>
                      {props.isFormRendered && (
                        <Button
                          onClick={() => {
                            props.setShow!(true);
                            props.setForm!({
                              ...props.form,
                              service_provider: service._id,
                              application_date: [],
                              district: service.address.district,
                              city: service.address.city,
                            });
                          }}
                          sx={{
                            textTransform: "none",
                            alignSelf: "flex-start",
                          }}
                          color="success"
                          variant="contained"
                        >
                          Randevu Al
                        </Button>
                      )}
                      <Button
                        onClick={() => {
                          window.open(
                            `tel:${service?.appointment_phone
                              .replace(/^0/, "+90")
                              .replace(/\s/g, "")
                              .replace("(", "")
                              .replace(")", "")}`,
                            "_blank"
                          );
                        }}
                        sx={{
                          textTransform: "none",
                          alignSelf: "flex-start",
                        }}
                      >
                        İletişim
                      </Button>
                    </Stack>
                  </Stack>
                </Popup>
              </Marker>
            );
          })}
      </MapContainer>
    </MapProvider>
  );
}

export const fetchCoordinates = async (city: string, district?: string) => {
  const replacedCity = city;
  const replacedDistrict = district ?? "";

  if (!replacedCity && !replacedDistrict) {
    return;
  }
  const params = new URLSearchParams();

  params.append("city", replacedCity);
  params.append("district", replacedDistrict);

  try {
    const { data } = await axiosInstance.get(
      `/coordinates?${params.toString()}`
    );

    if (data.districtData) {
      const { lat, lng } = data.districtData;
      return { lat, lng };
    } else if (data.cityData) {
      const { lat, lng } = data.cityData;
      return { lat, lng };
    } else {
      return;
    }
  } catch (error) {
    console.log(error);
    return;
  }
};
