import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import L from "leaflet";

interface MapContextType {
  coordinates: { lat: number; lng: number };
  zoom: number;
  setCoordinates: (coords: { lat: number; lng: number }) => void;
  setZoom: (zoom: number) => void;
  map?: L.Map | null;
}

export const mapIcon = (fileName: string) => (
   new L.Icon({
    iconUrl: process.env.PUBLIC_URL + fileName,
    iconSize: [40, 40],
  })
)

const MapContext = createContext<MapContextType | undefined>(undefined);


export const MapProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [coordinates, setCoordinates] = useState({
    lat: 39.92077,
    lng: 32.85411,
  });
  const [zoom, setZoom] = useState(6);
  const mapRef = useRef<L.Map | null>(null);


  return (
    <MapContext.Provider value={{ coordinates, zoom, setCoordinates, setZoom, map: mapRef.current }}>
      {children}
    </MapContext.Provider>
  );
};

export const useOpenStreetMap = (): MapContextType => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error("useMap must be used within a MapProvider");
  }
  return context;
};

