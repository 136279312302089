import {
  Box,
  Button,
  Typography,
  IconButton,
  Drawer,
  useMediaQuery,
  useTheme,
  Grid,
  Paper,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import NavMenu from "./NavMenu/NavMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { PATHS } from "../../routes";
import { Iconify, RefCatcher } from "../common";

export const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <RefCatcher />
      <Stack
        direction={"row"}
        gap={isMobile ? 1 : 2}
        justifyContent="flex-end"
        sx={{ pb: 2, ...(isMobile ? { flexWrap: "wrap" } : {}) }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <Box
            sx={{
              width: isMobile ? "16px" : "23px",
              height: isMobile ? "16px" : "23px",
              border: "1px solid #F9D3B5",
              backgroundColor: "#FCE9DA",
              borderRadius: "6px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 0,
            }}
          >
            <Iconify width={16} icon="ic:round-mail-outline" />
          </Box>
          <a href="mailto:info@kit-tak.com">
            <Typography
              sx={{
                fontSize: isMobile ? "10px" : "14px",
                lineHeight: "16px",
                fontWeight: 500,
                whiteSpace: "nowrap",
              }}
            >
              info@kit-tak.com
            </Typography>
          </a>
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <Box
            sx={{
              width: isMobile ? "16px" : "23px",
              height: isMobile ? "16px" : "23px",
              border: "1px solid #F9D3B5",
              backgroundColor: "#FCE9DA",
              borderRadius: "6px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 0,
            }}
          >
            <Iconify width={16} icon="ic:round-local-phone" />
          </Box>
          <a href="tel:+908502198559">
            <Typography
              sx={{
                fontSize: isMobile ? "10px" : "14px",
                lineHeight: "16px",
                fontWeight: 500,
                whiteSpace: "nowrap",
              }}
            >
              0850 219 8559
            </Typography>
          </a>
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <Box
            sx={{
              width: isMobile ? "16px" : "23px",
              height: isMobile ? "16px" : "23px",
              border: "1px solid #F9D3B5",
              backgroundColor: "#FCE9DA",
              borderRadius: "6px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 0,
            }}
          >
            <Iconify width={16} icon="ic:round-local-phone" />
          </Box>
          <a href="tel:+905330704841">
            <Typography
              sx={{
                fontSize: isMobile ? "10px" : "14px",
                lineHeight: "16px",
                fontWeight: 500,
                whiteSpace: "nowrap",
              }}
            >
              0533 070 4841
            </Typography>
          </a>
        </Stack>
      </Stack>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#002B32",
          borderTopLeftRadius: "24px",
          borderTopRightRadius: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: { xs: 2, md: 4 },
            pt: 1,
          }}
        >
          {/*     <img
          onClick={() => (window.location.href = "/")}
          src={require("../../assets/img/logo.png")}
          width={isMobile ? 60 : 120}
          height={isMobile ? 60 : 120}
          alt="Logo"
          style={{ cursor: "pointer", marginTop: 10 }}
        /> */}

          <Box
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Logo
              onClick={() => (window.location.href = "/")}
              width={isMobile ? 70 : 130}
              height={isMobile ? 70 : 130}
            />
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                  height: "50px",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
            >
              <Box
                sx={{
                  borderRadius: 10,
                  backgroundColor: "white",
                }}
              >
                <Button
                  onClick={() => (window.location.href = "/utts-kayit-sureci")}
                >
                  <Typography
                    sx={{
                      color: "#002B32",
                      fontSize: 18,
                      fontWeight: "bold",
                      px: 2,
                      py: 1,
                      textTransform: "none",
                    }}
                  >
                    Darphane Ödeme Süreci
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>

          {/* Mobilde Hamburger Menü */}

          {/* Masaüstünde Navigasyon Menüsü */}

          {/*     <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
          <NavMenu
            links={[
              { text: "UTTS Kayıt Süreci", link: "/utts-kayit-sureci" },

              { text: "Montaj Başvurusu", link: "/utts-montaj-basvuru" },
            ]}
            socialLinks={[
              {
                icon: require("../../assets/img/instagram.png"),
                link: "https://www.instagram.com/utts.istanbul/",
              },
              {
                icon: require("../../assets/img/facebook.png"),
                link: "https://www.facebook.com/utts.istanbul",
              },
            ]}
          />
        </Box> */}

          <Box sx={{ display: { md: "flex" }, alignItems: "center" }} gap={2}>
            <Box sx={{ display: { md: "flex" }, alignItems: "center" }}>
              <Box
                sx={{
                  borderRadius: 10,
                  backgroundColor: "green",
                  mr: isMobile ? 3: 4
                }}
              >
                <Button
                  onClick={() =>
                    (window.location.href = "/utts-montaj-basvuru")
                  }
                >
                  <Typography
                    sx={{
                      fontSize: { xs: 14, md: 18 },
                      fontWeight: "bold",
                      px: { md: 2, xs: 1 },
                      py: { md: 1, xs: 0.5 },
                      textTransform: "none",
                      color: "white",
                    }}
                  >
                    UTTS ONLINE RANDEVU
                  </Typography>
                </Button>
              </Box>
            </Box>
            <Box
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
              <Box
                sx={{
                  borderRadius: 10,
                  backgroundColor: "white",
                }}
              >
                <Button
                  onClick={() =>
                    (window.location.href = "/utts-kittak-is-ortagi")
                  }
                >
                  <Typography
                    sx={{
                      color: "#002B32",
                      fontSize: { xs: 14, md: 18 },
                      fontWeight: "bold",
                      px: { md: 2, xs: 1 },
                      py: { md: 1, xs: 0.5 },
                      textTransform: "none",
                    }}
                  >
                    TTS İndirimli Akaryakıt
                  </Typography>
                </Button>
              </Box>
            </Box>
            <Box
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
              <Box
                sx={{
                  borderRadius: 10,
                  backgroundColor: "white",
                }}
              >
                <Button
                  onClick={() => (window.location.href = PATHS.admin.login)}
                >
                  <Typography
                    sx={{
                      color: "#002B32",
                      fontSize: 18,
                      fontWeight: "bold",
                      px: 2,
                      py: 1,
                      textTransform: "none",
                    }}
                  >
                    Bayi Portal Girişi
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { xs: "block", md: "none" }, color: "white" }}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        {/* Mobilde Çekmece Menüsü */}
        <Drawer
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              width: 350,
              backgroundColor: "#002B32",
              color: "white",
            },
          }}
        >
          <NavMenu
            links={
              [
                /* { text: "UTTS Nedir?", link: "/utts-nedir" },
            { text: "UTTS Kayıt Süreci", link: "/utts-kayit-sureci" },
            {
              text: "Sık Sorulan Sorular",
              link: "/utts-sık-sorulan-sorular",
            },
            { text: "Montaj Başvurusu", link: "/utts-montaj-basvuru" }, */
              ]
            }
            socialLinks={[
              {
                icon: require("../../assets/img/instagram.png"),
                link: "https://www.instagram.com/utts.istanbul/",
              },
              {
                icon: require("../../assets/img/facebook.png"),
                link: "https://www.facebook.com/utts.istanbul",
              },
            ]}
            isMobile
            handleDrawerToggle={handleDrawerToggle}
          />
        </Drawer>
      </Box>
    </>
  );
};
