import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Iconify } from "../../components";
import { useMe } from "../../hooks";
import { IServiceProviderStatus, ServiceStatusList } from "../../interface";
import { useEffect, useState } from "react";
import { ServiceSettinsModal } from "../admin";
import { enqueueSnackbar } from "notistack";

export const ServiceOverview = ({ openForm }: { openForm: () => void }) => {
  const { me } = useMe();
  const [showRef, setShowRef] = useState(false);

  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("md"));
  const isTablet = useMediaQuery(breakpoints.down("lg"));

  const secondary = palette.secondary.main;
  const success = palette.success.main;

  const [showStep, setShowStep] = useState(true);
  useEffect(() => {
    if (me?.status === ServiceStatusList.ACCEPTED) {
      setShowStep(false);
    }
    if (me?.status !== ServiceStatusList.ACCEPTED) {
      setShowStep(true);
    }
    
  }, [me]);

  const steps = [
    {
      title: "Gerekli bilgi ve evraklar alındı.",
      status: me?.status === ServiceStatusList.APPROVED_BY_MINT,
      inProgress:
        me?.status === ServiceStatusList.PENDING_APPROVAL ||
        me?.status === ServiceStatusList.ONBOARDING,
    },
    {
      title: "Darphane başvuru süresi başlatıldı.",
      status: me?.status === ServiceStatusList.FIELD_CONTROL,
      inProgress: me?.status === ServiceStatusList.APPROVED_BY_MINT,
    },
    {
      title: "Saha kontrolü süreci başlatıldı.",
      status:
        me?.status === ServiceStatusList.ACCEPTED ||
        me?.status === ServiceStatusList.REJECTED,
      inProgress: me?.status === ServiceStatusList.FIELD_CONTROL,
      helper: "",
    },
    {
      title: "Onay süreci",
      status: me?.status === ServiceStatusList.ACCEPTED,
      inProgress: false,
    },
  ];

  const activeStep = steps.findIndex((s) => s.status);
  const isRejected = me?.status === ServiceStatusList.REJECTED;

  const handleCopyClick = (ref: string) => {
    const url = `${process.env.REACT_APP_FRONTEND_URL}/?ref=${ref}`;
    navigator.clipboard.writeText(url).then(() => {
      enqueueSnackbar("Link kopyalandı.", { variant: "success" });
    });
  };

  const refId = "S-" + ((me as any)?.id ?? "").toString();

  return (
    <Stack gap={2}>
      <Dialog
        open={showRef}
        maxWidth="sm"
        fullWidth
        onClose={() => {
          setShowRef(false);
        }}
      >
        <Stack gap={3} sx={{ p: 3 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{
                fontSize: isMobile ? "18px" : "24px",
                lineHeight: "32px",
                fontWeight: 600,
              }}
            >
              Referans Linkiniz
            </Typography>

            <IconButton
              onClick={() => {
                setShowRef(false);
              }}
            >
              <Iconify icon="ic:baseline-close" />
            </IconButton>
          </Stack>
          <Stack gap={3}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{
                gap: 2,
                p: 3,
                backgroundColor: "secondary.main",
                borderRadius: "12px",
                "&:hover": { cursor: "pointer" },
              }}
              onClick={() => {
                handleCopyClick(refId);
              }}
            >
              <Typography
                sx={{
                  fontSize: isMobile ? "16px" : "18px",
                  lineHeight: "24px",
                  fontWeight: 500,
                  color: "common.white",
                  textAlign: "center",
                  fontFamily: "monospace",
                }}
              >
                {process.env.REACT_APP_FRONTEND_URL}/?ref={refId}
              </Typography>
              <Iconify color="white" icon="ic:round-content-copy" />
            </Stack>
            <Stack
              direction="row"
              gap={2}
              flexWrap="wrap"
              sx={{ borderTop: "1px solid", borderTopColor: "divider", pt: 3 }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleCopyClick(refId);
                }}
                endIcon={<Iconify icon="ic:round-content-copy" />}
              >
                Linki Kopyala
              </Button>
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  window.location.href = `mailto:?subject=Referans Linkiniz&body=${process.env.REACT_APP_FRONTEND_URL}/?ref=${refId}`;
                }}
                endIcon={<Iconify icon="ic:round-email" />}
              >
                Email Gönder
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  const url = `${process.env.REACT_APP_FRONTEND_URL}/?ref=${refId}`;
                  window.open(
                    `https://wa.me/?text=${encodeURIComponent(url)}`,
                    "_blank"
                  );
                }}
                endIcon={<Iconify icon="ic:round-whatsapp" />}
              >
                WhatsApp'tan Paylaş
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>

      <Stack
        sx={{
          p: 3,
          backgroundColor: "#fff",
          borderRadius: 4,
          border: "1px solid",
          borderColor: "divider",
        }}
        gap={2}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack>
            <Typography sx={{ fontSize: "24px", lineHeight: "34px" }}>
              Profil
            </Typography>
            <Typography sx={{ color: "#344054" }}>
              Kişisel bilgilerinizi buradan görüntüleyebilirsiniz
            </Typography>
            {me?.service_provider_code && (
              <Typography mt={1}>
                Bayi Kodu: <span style={{fontWeight: "bold"}}>{me?.service_provider_code}</span>
              </Typography>
            )}
          </Stack>
          {me?.status !== ServiceStatusList.REJECTED && (
            <Stack direction="row" alignItems="center" gap={1}>
              <ButtonBase
                onClick={() => {
                  setShowRef(true);
                }}
                sx={{
                  gap: 1,
                  fontFamily: "inherit",
                  borderRadius: 4,
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Referans Linkim
                <Iconify icon="ic:outline-share" />
              </ButtonBase>
              <ServiceSettinsModal />
              <IconButton
                onClick={() => {
                  openForm();
                }}
              >
                {me?.status !== ServiceStatusList.ACCEPTED ? (
                  <Iconify icon="ic:round-edit" />
                ) : (
                  <Iconify icon="ic:round-remove-red-eye" />
                )}
              </IconButton>
            </Stack>
          )}
        </Stack>
        <Stack
          direction={isMobile ? "column" : "row"}
          gap={2}
          alignItems="center"
        >
          <Box
            sx={{
              width: 170,
              height: 170,
              borderRadius: 4,
              overflow: "hidden",
              fontSize: 0,
            }}
          >
            <img src={"/images/avatar.webp"} alt="Avatar" />
          </Box>
          <Stack gap={1}>
            <Typography sx={{ fontSize: "24px", fontWeight: 500 }}>
              {me?.offical_title}
            </Typography>
            <Typography>{me?.authorized_person_name}</Typography>
            <Divider sx={{ my: 1 }} />
            <Stack direction={isMobile ? "column" : "row"} gap={4}>
              <Stack gap={1}>
                <Stack alignItems="center" flexDirection={"row"} gap={1}>
                  <Iconify icon="ic:round-email" color="#aaa" />
                  <Typography>{me?.authorized_person_email}</Typography>
                </Stack>
                <Stack alignItems="center" flexDirection={"row"} gap={1}>
                  <Iconify icon="ic:round-phone" color="#aaa" />
                  <Typography>{me?.authorized_person_phone}</Typography>
                </Stack>
              </Stack>
              <Stack gap={1}>
                <Stack alignItems="center" flexDirection={"row"} gap={1}>
                  <Iconify icon="ic:round-directions-car" color="#aaa" />
                  <Typography>
                    {(me?.vehicle_types_for_service ?? []).join(", ")}
                  </Typography>
                </Stack>
                <Stack alignItems="center" flexDirection={"row"} gap={1}>
                  <Iconify icon="ic:round-location-on" color="#aaa" />
                  <Typography>
                    {me?.address?.city ?? "-"} {me?.address?.district ?? "-"}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {showStep && (
        <Stack gap={1}>
          <Stack direction={isMobile ? "column" : "row"} gap={2}>
            {steps.map((step, index) => (
              <Stack
                key={index}
                gap={2}
                sx={{ flex: index < steps.length - 1 ? 1 : 0, minWidth: 120 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  justifyContent="flex-start"
                >
                  <Box
                    sx={{
                      backgroundColor:
                        isRejected && index === steps.length - 1
                          ? "error.main"
                          : index <= activeStep ||
                            me?.status === ServiceStatusList.ACCEPTED
                          ? secondary
                          : step.inProgress
                          ? "#025462"
                          : "#002B324D",
                      borderRadius: "42px",
                      minWidth: "42px",
                      minHeight: "42px",
                      width: "42px",
                      height: "42px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {isRejected && index === steps.length - 1 && (
                      <Iconify icon="ic:round-close" color="white" />
                    )}
                    {(index <= activeStep ||
                      me?.status === ServiceStatusList.ACCEPTED) && (
                      <Iconify icon="ic:round-check" color="white" />
                    )}
                  </Box>
                  {index < steps.length - 1 && (
                    <Box
                      width={"100%"}
                      height={4}
                      sx={{
                        borderRadius: 2,
                        position: "relative",
                        overflow: "hidden",
                        backgroundColor:
                          index > activeStep ? "#002B324D" : secondary,
                      }}
                    >
                      {step.inProgress && (
                        <Box
                          sx={{
                            background: "#025462",
                            position: "absolute",
                            inset: 0,
                            right: "50%",
                          }}
                        />
                      )}
                    </Box>
                  )}
                </Stack>
                <Stack gap={0.25}>
                  <Typography
                    sx={{ fontSize: "12px", color: secondary, fontWeight: 300 }}
                  >
                    {index + 1}. Aşama
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: 500,
                    }}
                  >
                    {step.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color:
                        index <= activeStep ||
                        me?.status === ServiceStatusList.ACCEPTED
                          ? success
                          : "#333",
                    }}
                  >
                    {isRejected && index === steps.length - 1 ? (
                      <Box sx={{ color: "error.main" }}>Reddedildi</Box>
                    ) : (
                      <>
                        {step.status || index < activeStep
                          ? "Tamamlandı"
                          : step.inProgress
                          ? "Devam ediyor"
                          : "Beklemede"}
                      </>
                    )}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
