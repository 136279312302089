import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { addDays, format, subDays } from "date-fns";
import { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import { Iconify } from "../../../components";
import {
  IMontageApplication,
  IMontageStatus,
  IServiceProvider,
} from "../../../interface";
import {
  fDate,
  fetcher,
  generateColorsFromString,
} from "../../../utils";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../routes";
import { useAuth } from "../../../context";
import { useMe } from "../../../hooks";

export interface IMontageResponse extends IMontageApplication {
  _id: string;
  service: IServiceProvider;
}

export const ReservationAllSection = () => {
  const { user } = useAuth();
  const {me} = useMe();
  const [selectedService, setSelectedService] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [workingHours, setWorkingHours] = useState<string[]>([]);
  const scrollTargetRef = useRef<HTMLDivElement>(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (user?.role === "service_provider") {
      console.log(user?.id)
      setSelectedService(user.id);
    }
  }, [user]);

  const { data: services } = useSWR<IServiceProvider[]>(
    `/service-provider/application`,
    fetcher
  );

  const prevDay = subDays(new Date(currentDate), 1);
  const nextDay = addDays(new Date(currentDate), 1);

  let { data: prevDayData } = useSWR<IMontageResponse[]>(
    `/montage/application/all?date=${format(prevDay, "yyyy-MM-dd")}${
      selectedService ? "&service=" + selectedService : ""
    }`,
    fetcher
  );

  let { data: nextDayData } = useSWR<IMontageResponse[]>(
    `/montage/application/all?date=${format(nextDay, "yyyy-MM-dd")}${
      selectedService ? "&service=" + selectedService : ""
    }`,
    fetcher
  );

  let prevDayRecordLength = 0;
  let nextDayRecordLength = 0;

  nextDayData
    ?.filter((d) => !d.is_rejected && d.status == IMontageStatus.waiting)
    .map((d) => d.vehicles?.map((v) => (nextDayRecordLength += v.quantity)));

  prevDayData
    ?.filter((d) => !d.is_rejected && d.status == IMontageStatus.waiting)
    .map((d) => d.vehicles?.map((v) => (prevDayRecordLength += v.quantity)));

  let { data, isLoading } = useSWR<IMontageResponse[]>(
    `/montage/application/all?date=${format(currentDate, "yyyy-MM-dd")}${
      selectedService ? "&service=" + selectedService : ""
    }`,
    fetcher
  );

  data = data?.filter(
    (x) => x.status == IMontageStatus.waiting && !x.is_rejected
  );

  let totalApp = 0;
  data?.map((item) => {
    item.vehicles?.map((v) => (totalApp += v.quantity));
  });

  useEffect(() => {
    if (isInitialLoad && data?.length && scrollTargetRef.current) {
      const timer = setTimeout(() => {
        scrollTargetRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        setIsInitialLoad(false);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [data, isInitialLoad]);

  useEffect(() => {
    const updateWorkingHours = () => {
      if (selectedService !== "" && data?.[0]?.service?.working_hours) {
        const hours = data[0].service.working_hours;

        if (hours.length > 0) {
          const sortedHours = [...hours]
            .map((time) => time.trim())
            .sort((a, b) => {
              const [aHour, aMin] = a.split(":").map(Number);
              const [bHour, bMin] = b.split(":").map(Number);
              return aHour + aMin - (bHour + bMin);
            });
          let modifiedHours = [];
          const startHour = parseInt(sortedHours[0].split(":")[0]);
          const endHour = parseInt(
            sortedHours[sortedHours.length - 1].split(":")[0]
          );
          for (let hour = startHour; hour <= endHour; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
              const hourString = hour < 10 ? `0${hour}` : `${hour}`;
              const minuteString = minute < 10 ? `0${minute}` : `${minute}`;
              modifiedHours.push(`${hourString}:${minuteString}`);
            }
          }
          setWorkingHours(modifiedHours);
          return;
        }
      } else if(user?.role === "service_provider" && !!me?.working_hours?.length && me?.working_hours.length > 0) {
        const hours = me?.working_hours;

        if (hours.length > 0) {
          const sortedHours = [...hours]
            .map((time) => time.trim())
            .sort((a, b) => {
              const [aHour, aMin] = a.split(":").map(Number);
              const [bHour, bMin] = b.split(":").map(Number);
              return aHour + aMin - (bHour + bMin);
            });
          let modifiedHours = [];
          const startHour = parseInt(sortedHours[0].split(":")[0]);
          const endHour = parseInt(
            sortedHours[sortedHours.length - 1].split(":")[0]
          );
          for (let hour = startHour; hour <= endHour; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
              const hourString = hour < 10 ? `0${hour}` : `${hour}`;
              const minuteString = minute < 10 ? `0${minute}` : `${minute}`;
              modifiedHours.push(`${hourString}:${minuteString}`);
            }
          }
          setWorkingHours(modifiedHours);
          return;
        }
      } else {
        // Varsayılan saatler
        const defaultHours = [];
        for (let hour = 0; hour <= 23; hour++) {
          for (let minute = 0; minute < 60; minute += 15) {
            const hourString = hour < 10 ? `0${hour}` : `${hour}`;
            const minuteString = minute < 10 ? `0${minute}` : `${minute}`;
            defaultHours.push(`${hourString}:${minuteString}`);
          }
        }
        setWorkingHours(defaultHours);
      }
    };

    updateWorkingHours();
  }, [selectedService, data?.[0]?.service?.working_hours, me?.working_hours]);

  return (
    <Stack gap={3}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack gap={1}>
          <Typography
            sx={{ fontSize: "32px", lineHeight: "40px", fontWeight: 500 }}
          >
            Randevular
          </Typography>
          <Typography
            sx={{ fontSize: "18px", lineHeight: "26px", color: "gray.700" }}
          >
            Yaklaşan randevularınızı buradan takip edebilirsiniz
          </Typography>
          <Typography variant="body1">
            Bugün <span style={{ fontWeight: "bold" }}>{totalApp}</span> araç
            başvurusu bulunmaktadır.
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <IconButton
            color="secondary"
            onClick={() => {
              const d = subDays(new Date(currentDate), 1);
              setCurrentDate(d);
            }}
          >
            <Iconify icon="ic:baseline-chevron-left" />
          </IconButton>
          <Button
            onClick={() => {
              const d = subDays(new Date(currentDate), 1);
              setCurrentDate(d);
            }}
            color="secondary"
          >
            {fDate(subDays(new Date(currentDate), 1))} {"\n"}
            {fDate(subDays(new Date(currentDate), 1), "EEEE")}
          </Button>

          <Tooltip title="Tarih Seçin" arrow>
            <Box
              sx={{
                position: "relative",
                cursor: "pointer",
                borderRadius: 2,
                transition: ".2s",
                border: "1px solid #eee",
                "&:hover": { cursor: "pointer", borderColor: "divider" },
              }}
            >
              <MobileDatePicker
                localeText={{
                  okButtonLabel: "Seç",
                  cancelButtonLabel: "İptal",
                  toolbarTitle: "Tarih Seçin",
                }}
                sx={{
                  "& > .MuiInputBase-root": { pr: 0 },
                  "& input": {
                    textAlign: "center",
                    pl: 0,
                    py: 1,
                    width: "150px",
                    color: "orange",
                  },
                  "& fieldset": { border: "none" },
                }}
                value={currentDate}
                format="dd MMMM yyyy EEEE "
                onChange={(e) => {
                  if (!e) return;
                  setCurrentDate(e);
                }}
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment: (
                        <Iconify
                          sx={{ transform: "translateX(-8px)" }}
                          icon="ic:round-calendar-month"
                        />
                      ),
                    },
                  },
                }}
              />
            </Box>
          </Tooltip>
          <Button
            onClick={() => {
              const d = addDays(new Date(currentDate), 1);
              setCurrentDate(d);
            }}
            color="secondary"
          >
            {fDate(addDays(new Date(currentDate), 1))} {"\n"}
            {fDate(addDays(new Date(currentDate), 1), "EEEE")}
          </Button>
          <IconButton
            color="secondary"
            onClick={() => {
              const d = addDays(new Date(currentDate), 1);
              setCurrentDate(d);
            }}
          >
            <Iconify icon="ic:baseline-chevron-right" />
          </IconButton>
        </Stack>
      </Stack>
      {user?.role === "admin" && (
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" gap={1} alignItems="center">
            <FormControl size="small" sx={{ width: 300 }}>
              <InputLabel>Bayi Seç</InputLabel>
              <Select
                value={selectedService}
                onChange={(e) => {
                  setSelectedService(e.target.value);
                }}
                size="small"
                label="Bayi Seç"
              >
                {services?.map((service) => (
                  <MenuItem
                    key={service._id.toString()}
                    value={service._id.toString()}
                  >
                    {`${service.address.city}/${service.address.district} - ${
                      service.offical_title.split(" ")[0]
                    } ${service.offical_title.split(" ")[1]}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedService && (
              <IconButton
                onClick={() => {
                  setSelectedService("");
                }}
              >
                <Iconify icon="ic:round-clear" />
              </IconButton>
            )}
          </Stack>
        </Stack>
      )}
      <Stack direction="column" alignSelf="flex-end">
        <Typography>
          {fDate(subDays(new Date(currentDate), 1))}:{" "}
          <span style={{ fontWeight: "bold" }}>{prevDayRecordLength}</span> Araç
        </Typography>
        <Typography>
          {fDate(new Date(currentDate))}:{" "}
          <span style={{ fontWeight: "bold" }}>{totalApp}</span> Araç
        </Typography>
        <Typography>
          {fDate(addDays(new Date(currentDate), 1))}:{" "}
          <span style={{ fontWeight: "bold" }}>{nextDayRecordLength}</span> Araç
        </Typography>
      </Stack>
      {isLoading && !data?.length && (
        <Stack gap={2}>
          <Skeleton height={64} />
          <Skeleton height={64} />
          <Skeleton height={64} />
          <Skeleton height={64} />
          <Skeleton height={64} />
          <Skeleton height={64} />
        </Stack>
      )}
      <Stack
        sx={{ border: "1px solid", borderColor: "divider", borderRadius: 2 }}
      >
        <Stack
          direction="row"
          sx={{ borderBottom: "1px solid", borderBottomColor: "divider" }}
        >
          <Box
            sx={{
              width: 100,
              borderRight: "1px solid",
              borderRightColor: "divider",
              pl: 2,
              py: 1,
            }}
          >
            <Typography sx={{ fontSize: "16px", lineHeight: "28px" }}>
              Saatler
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }}></Box>
        </Stack>
        <Stack>
          {!isLoading &&
            workingHours.map((time: string) => (
              <Stack
                key={time}
                ref={time === "08:00" ? scrollTargetRef : null}
                direction="row"
                sx={{
                  borderBottom: "1px solid",
                  borderBottomColor: "divider",
                  "&:last-child": {
                    borderBottom: "none",
                  },
                }}
              >
                <Box
                  sx={{
                    width: 100,
                    minWidth: 100,
                    borderRight: "1px solid",
                    borderRightColor: "divider",
                    py: 1,
                    pl: 2,
                  }}
                >
                  <Typography sx={{ fontSize: "16px", lineHeight: "28px" }}>
                    {time}
                  </Typography>
                </Box>
                <Box sx={{ p: 1, flex: 1 }}>
                  <HourDetail
                    date={currentDate}
                    time={time}
                    data={data ?? [] /*?.filter(x => x.is_rejected === false)*/}
                  />
                </Box>
              </Stack>
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

const HourDetail = ({
  time,
  data,
  date,
}: {
  time: string;
  data: IMontageResponse[];
  date: any;
}) => {
  let selectedDateISO = (date as Date)
    .toLocaleString("tr-TR", { timeZone: "Europe/Istanbul" })
    .split(" ")[0];

  const [day, month, year] = selectedDateISO.split(".");

  const formattedDay = String(Number(day)).padStart(2, "0");
  const formattedMonth = String(Number(month)).padStart(2, "0");

  const newFormatOfDate = `${year}-${formattedMonth}-${formattedDay}`;
  selectedDateISO = newFormatOfDate;

  const { user } = useAuth();
  const navigate = useNavigate();
  const filteredData = data.filter((row) => {
    const times = row.application_date?.filter((t) => {
      const [datePart, timePart] = t.toString().split("T");
      const currentTime = timePart.split(".")[0];

      const returnValue =
        datePart === selectedDateISO && currentTime.substring(0, 5) === time;
      return returnValue;
    });

    if (times?.length) return row;
  });
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill,minmax(210px,1fr))`,
        gridTemplateRows: "masonry",
        gridAutoFlow: "column",
        gap: 1,
        width: "100%",
      }}
    >
      {filteredData.map((row, index) => {
        const colors = generateColorsFromString(row.company_name);

        // const carCount = row.vehicles.reduce((total, data) => {
        //   return total + data.quantity;
        // }, 0);

        return (
          <Box
            key={index}
            sx={{
              ...colors,
              borderRadius: 2,
              border: "2px solid rgba(0,0,0,.25)",
              p: 1,
              pl: 2,
              px: 3,
              boxShadow: "0 20px 20px rgba(0,0,0,.15)",
              position: "relative",
              overflow: "hidden",
              verticalAlign: "top",
              cursor: "pointer",
              width: "100%",
            }}
            onClick={() => {
              navigate(PATHS.admin.reservation.detail.get(row._id));
            }}
          >
            <Box
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                height: "100%",
                width: 10,
                ...colors,
              }}
            />

            {user?.role === "admin" && (
              <Stack spacing={2}>
                <Stack direction="row" gap={2}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontWeight: 250,
                    }}
                  >
                    {`${row.service.offical_title.split(" ")[0]} ${
                      row.service.offical_title.split(" ")[1] ?? ""
                    }`}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontWeight: 250,
                    }}
                  >{`${row.address.city} / ${row.address.district}`}</Typography>
                </Stack>

                <Stack direction="row" spacing={2}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontWeight: 250,
                      color: "orange",
                    }}
                  >{`${row.company_name.split(" ")[0]} ${
                    row.company_name.split(" ")[1] ?? ""
                  }`}</Typography>

                  <Typography
                    sx={{
                      color: "orange",
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontWeight: 250,
                    }}
                  >
                    {row.authorized_person_phone}
                  </Typography>
                </Stack>
              </Stack>
            )}
            {user?.role == "service_provider" && (
              <Stack spacing={2}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    lineHeight: "18px",
                    fontWeight: 250,
                  }}
                >{`${row.company_name.split(" ")[0]} ${
                  row.company_name.split(" ")[1] ?? ""
                }`}</Typography>

                <Typography
                  sx={{
                    fontSize: "15px",
                    lineHeight: "18px",
                    fontWeight: 250,
                  }}
                >
                  {row.authorized_person_phone}
                </Typography>
              </Stack>
            )}

            {/* <Stack direction="row" gap={1}>
              <Iconify icon="mdi:car" />
              <Typography sx={{ fontSize: "14px", lineHeight: "20px" }}>
                {carCount} Taşıt
              </Typography>
            </Stack> */}
            {row.is_rejected && <div>Reddedildi.</div>}
            {row.type === "mobile" && (
              <Tooltip title="Mobil Montaj" arrow>
                <Iconify icon="hugeicons:mobile-navigator-01" />
              </Tooltip>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
