import { Navigate, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { axiosInstance, fetcher } from "../../../utils";
import {
  CircularProgressModal,
  ServiceDetailModal,
  ServiceStatusChanger,
} from "../../../components";
import {
  Box,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { IAddress, IServiceProvider, ServiceStatusList } from "../../../interface";
import { PATHS } from "../../../routes";
import { ServiceLevelTypes } from "../../../data";

const LabelStyle = (theme: any) => ({
  fontSize: "14px",
  lineHeight: "20px",
  color: theme.palette.grey[700],
});

export const ServiceProfileSection = () => {
  const { id } = useParams();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [statusDisabled, setStatusDisabled] = useState(false);

  const onStatusChangeValidation = (data: IServiceProvider): boolean => {
    const requiredFields: (keyof IServiceProvider)[] = [
      "authorized_person_name",
      "authorized_person_email",
      "authorized_person_phone",
      "signage_name",
      "offical_title",
      "tax_office",
      "tax_number",
      "business_type",
      "workplace_type",
      "total_employees",
      "employees_for_installation",
      "appointment_phone",
      "working_days",
      "total_vehicle_capacity",
      "vehicles_for_installation",
      "vehicle_types_for_service",
      "technicians",
      "files",
      "status",
      "working_hours",
    ];

    const requiredAddressFields: (keyof IAddress)[] = [
      "city",
      "district",
      "fullAddress",
    ];

    let isValid = true;
    for (const field of requiredFields) {
      const value = data[field];
      
      if (value == null || value.toString().trim() === "") {
        console.log("value validation", field, value);
        isValid = false;
        return false;
      }
          

      // Array kontrolü
      if(field === "files" && !(value as any[]).some((f: any) => f != null)){
        console.log("file validation");
        isValid = false;
        return false;
      }
      else if (Array.isArray(value) && value.length === 0){
         
        console.log("array validation", field, value);
        isValid = false;
        return false;
        
      }
    }

    // Address kontrolü
    if (!data.address || typeof data.address !== "object") {
        console.log("address validation", data.address);
      isValid = false;
    }

    for (const field of requiredAddressFields) {
      const value = data.address[field];
      if (value == null || value.toString().trim() === "") {
        console.log("address val validation", field, value)
        isValid = false;
      }
    }
    return isValid;
  };


  const { data, isLoading, error } = useSWR<IServiceProvider>(
    `/service-provider/profile/${id}`,
    fetcher
  );

  useEffect(() => {
    if(data){
      const isValid = onStatusChangeValidation(data);
      if(!isValid)
        setStatusDisabled(true);
      const dc = data.device_count;
      const mdc = data.mobile_device_count;
      if(!dc && !mdc){
        console.log("useeffect count validation ", dc, mdc)
        setStatusDisabled(true);
      }
    }
  }, [data])
    
  const onChange = async (key: string, value: number | string) => {
    setLoading(true);

    try {
      await axiosInstance.put(`/service-provider/${id}`, {
        [key]: value,
      });

      mutate(`/service-provider/profile/${id}`);

      enqueueSnackbar("Bayii durumu değiştirildi.", { variant: "success" });
    } catch (error) {}

    setLoading(false);
  };

  if (!id) {
    return null;
  }

  if (isLoading && !data) {
    return <CircularProgressModal />;
  }

  if (data?.status === ServiceStatusList.PENDING_APPROVAL) {
    return <Navigate to={PATHS.admin.servicesProviders.application} />;
  }

  if (data) {
    return (
      <Stack gap={2}>
        <ServiceDetailModal
          isModal={false}
          selectedService={data}
          setSelectedService={() => {}}
        />
        <Box
          sx={{
            p: 3,
            border: "1px solid",
            borderColor: "divider",
            borderRadius: 4,
            background: "#fff",
          }}
        >
          <Stack gap={3}>
            <Stack direction="row" gap={2}>
              
                <Stack gap={1} sx={{ flex: 1 }}>
                  <Typography sx={LabelStyle(theme)}>Bayi Durumu</Typography>
                  <ServiceStatusChanger
                    id={id}
                    status={data.status}
                    onChange={() => {
                      mutate(`/service-provider/profile/${id}`);
                    }}
                    disabled={statusDisabled}
                  />
                </Stack>
              
              <Box sx={{ flex: 1 }} />
              <Box sx={{ flex: 1 }} />
            </Stack>
            <Stack gap={2} direction="row" sx={{ opacity: loading ? 0.5 : 1 }}>
              <Stack gap={1} flex={1}>
                <Typography sx={LabelStyle(theme)}>
                  Bayiye Verilecek Sabit Cihaz Sayısı
                </Typography>
                <Select
                  disabled={loading}
                  size="small"
                  value={data.device_count ?? 0}
                  onChange={(e) => {
                    const val = e.target.value as number;
                    onChange("device_count", val);
                  }}
                >
                  {Array(4)
                    .fill("")
                    .map((val, index) => (
                      <MenuItem key={index} value={index}>
                        {index}
                      </MenuItem>
                    ))}
                </Select>
              </Stack>
              <Box sx={{ flex: 1 }} />
              <Box sx={{ flex: 1 }} />
            </Stack>
            <Stack direction="row" gap={2} sx={{ opacity: loading ? 0.5 : 1 }}>
              <Stack gap={1} flex={1}>
                <Typography sx={LabelStyle(theme)}>
                  Bayiye Verilecek Mobil Cihaz Sayısı
                </Typography>
                <Select
                  disabled={loading}
                  size="small"
                  value={data.mobile_device_count ?? 0}
                  onChange={(e) => {
                    const val = e.target.value as number;
                    onChange("mobile_device_count", val);
                  }}
                >
                  {Array(4)
                    .fill("")
                    .map((val, index) => (
                      <MenuItem key={index} value={index}>
                        {index}
                      </MenuItem>
                    ))}
                </Select>
              </Stack>
              <Stack gap={1} flex={1}>
                <Typography sx={LabelStyle(theme)}>Bayiilik Tipi</Typography>
                <Select
                  disabled={loading}
                  value={data.level ?? 0}
                  onChange={(e) => {
                    const val = e.target.value as number;
                    onChange("level", val);
                  }}
                  size="small"
                >
                  {ServiceLevelTypes.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Box sx={{ flex: 1 }} />
            </Stack>
          </Stack>
        </Box>
      </Stack>
    );
  }

  return null;
};
