import React, { useMemo } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Footer, Header } from "../components";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const AracMontajBasvuru = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentStep, setCurrentStep] = React.useState(0);

  const renderSteps = useMemo(() => {
    const stepsData = [
      {
        stepNumber: "Adım 1",
        title: "Kullanıcı Hesabı Açma",
        description:
          "Başvuru sahipleri <a href='https://utts.gov.tr/basvuru?type=tasit-sahibi' style='text-decoration:underline' target='_blank'>UTTS Başvuru Süreci</a> adresine giriş yaptıktan sonra, başvuru işlemini başlatmaktadır. Tüm süreç açılan UTTS Kullanıcı Hesabı üzerinden güvenle yürütülmektedir.",
        image: require("../assets/img/illustration.webp"),
      },
      {
        stepNumber: "Adım 2",
        title: "Belgelerin İbrazı",
        description:
          "Başvuru sahipleri, TTB (Taşıt Tanıma Birimi) montajı yaptırmak istediği araca ilişkin belge ve gerekli bilgileri ilgili sisteme yüklemektedir.",
        image: require("../assets/img/illustration2.webp"),
      },
      {
        stepNumber: "Adım 3",
        title: "Ödeme",
        description:
          "Belgelerin sunulmasından sonra ödeme, başvuru sahipleri tarafından Darphane'nin vermiş olduğu IBAN üzerinden veya kredi kartı ile tamamlanmaktadır.",
        image: require("../assets/img/illustration3.webp"),
      },
      {
        stepNumber: "Adım 4",
        title: "Montaj Randevusu",
        description:
          "Darphane ödeme işlemini gerçekleştirdikten sonra <a style='color: #008765; font-weight: bold;' href='https://kit-tak.com' target='_blank'>kit-tak.com</a> web sitemizden online montaj randevusu oluşturarak. hiç beklemeden, 15 dakika içerisinde UTTS montajınızı gerçekleştirin.",
        image: require("../assets/img/illustration4.webp"),
      },
      {
        stepNumber: "Adım 5",
        title: "UTTS",
        description:
          "Montajı tamamlanmış araç sahipleri, UTTS sistemine sahip olan akaryakıt istasyonlarının pompalarından yakıt alabilmekte ve gider gösterebilmektedir.",
        image: require("../assets/img/illustration5.webp"),
      },
    ];

    const currentData = stepsData[currentStep];

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              backgroundColor: "#E6F3F0",
              borderRadius: "8px",
              height: "100%",
              p: { xs: 2, sm: 3 },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: 22, sm: 24 },
                color: "#008765",
                fontWeight: "bold",
              }}
            >
              {currentData.stepNumber}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: 28, sm: 32 },
                color: "#000",
                fontWeight: "500",
                mt: 1,
              }}
            >
              {currentData.title}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: 16, sm: 18 },
                color: "#1C1B1C",
                fontWeight: "400",
                mt: 2,
              }}
            >
              <span
                dangerouslySetInnerHTML={{ __html: currentData.description }}
              />
            </Typography>
            {currentStep === 2 && (
              <Typography mt={1}>
                <a
                  href="https://cdn-document.utts.gov.tr/utts-tasit-sahibi-mukellef-fiyat-listesi.pdf"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#008765", fontWeight: "bold" }}
                >
                  Taşıt Sahibi Mükellef -2025 Yılı Birim Fiyat Listesi
                </a>
              </Typography>
            )}
            {currentStep < 4 && (
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ my: 3 }}
                  onClick={() => {
                    if (currentStep < 4) {
                      setCurrentStep(currentStep + 1);
                    }
                  }}
                >
                  Devam
                </Button>
              </div>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "8px",
              height: "100%",
              p: { xs: 2, sm: 3 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={currentData.image}
              alt="UTTS"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }, [currentStep]);

  return (
    <Container>
      <Box mx={{ xs: 2, sm: 4, md: 6 }} my={2}>
        <Header />
        <Box
          py={3}
          sx={{
            backgroundColor: "#002B32",
            borderBottomLeftRadius: "24px",
            borderBottomRightRadius: "24px",
          }}
        >
          <Box
            display={"flex"}
            flexDirection={{ xs: "row", sm: "row" }}
            justifyContent={"space-between"}
            alignItems={"center"}
            px={{ xs: 1, sm: 5 }}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              onClick={() => {
                if (currentStep > 0) {
                  setCurrentStep(currentStep - 1);
                }
              }}
              sx={{
                borderRadius: "50%",
                width: 50,
                height: 50,
                backgroundColor: "#ffffff",
                "&:hover": {
                  backgroundColor: "#dedede",
                },
              }}
            >
              <ArrowBackIosNewIcon sx={{ color: "#000" }} />
            </IconButton>
            <Typography
              pt={2}
              variant="h2"
              sx={{
                fontSize: { xs: 22, sm: 30, md: 40 },
                fontWeight: "600",
                color: "#fff",
                textAlign: "center",
                flexGrow: 1,
              }}
            >
              Araç Montajı Başvuru Süreci
            </Typography>
            <IconButton
              onClick={() => {
                if (currentStep < 4) {
                  setCurrentStep(currentStep + 1);
                }
              }}
              sx={{
                borderRadius: "50%",
                width: 50,
                height: 50,
                backgroundColor: "#ffffff",
                "&:hover": {
                  backgroundColor: "#dedede",
                },
              }}
            >
              <ArrowForwardIosIcon sx={{ color: "#000" }} />
            </IconButton>
          </Box>
          <Typography
            py={2}
            variant="h5"
            sx={{ color: "#ffffffb8", textAlign: "center" }}
          >
            <span style={{ color: "#fff" }}>{currentStep + 1} / 5</span> Adım
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              px: { xs: 2, sm: 4 },
            }}
          >
            {[...Array(5)].map((_, index) => (
              <Box
                key={index}
                sx={{
                  mx: 0.5,
                  flexGrow: 1,
                  height: 4,
                  backgroundColor: currentStep >= index ? "#fff" : "#ffffff4c",
                }}
              />
            ))}
          </Box>
          <Box
            sx={{
              backgroundColor: "#F6F6F6",
              borderRadius: "8px",
              mt: 4,
              mx: { xs: 1, sm: 2, md: 4 },
              p: { xs: 2, sm: 3 },
            }}
          >
            {renderSteps}
          </Box>
        </Box>
        <Footer />
      </Box>
    </Container>
  );
};

export default AracMontajBasvuru;
