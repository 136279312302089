import { toZonedTime } from "date-fns-tz";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import useSWR, { mutate } from "swr";
import {
  axiosInstance,
  fDate,
  fDateTime,
  fetcher,
  formatApplicationDates,
} from "../../../utils";
import { useDebouncedValue, useMe } from "../../../hooks";
import { saveAs } from "file-saver";
import XLSX from "xlsx-js-style";
import {
  Alert,
  Box,
  Button,
  ButtonBase,
  Chip,
  CircularProgress,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { PATHS } from "../../../routes";
import {
  CircularProgressModal,
  Iconify,
  MontageCalendar,
  MontageStatusChanger,
  MontageTimePicker,
} from "../../../components";
import {
  IMontageApplication,
  IMontageStatus,
  IMontageStatusLabel,
  IServiceProvider,
  MontageRejectReason,
  MontageRejectReasonLabel,
  ServiceStatusList,
} from "../../../interface";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { enqueueSnackbar } from "notistack";
import { format, parseISO } from "date-fns";
import { tr } from "date-fns/locale";
import { useAuth } from "../../../context";
import { IMontageResponse } from "../reservation";
import { OpenStreetMap } from "../../../components/common/Map";
import { useOpenStreetMap } from "../../../context/MapContext";
import { LeafletMouseEvent } from "leaflet";
import * as React from "react";
import { alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DangerousIcon from "@mui/icons-material/Dangerous";
import WrongLocationIcon from "@mui/icons-material/WrongLocation";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.grey[100],
  },
}));

const ModalItem = ({
  title,
  value,
  id,
  sx,
  arrow,
}: {
  title: string;
  value?: string;
  id?: string;
  sx?: any;
  arrow?: boolean;
}) => {
  return (
    <Stack gap={1} sx={{ flex: 1, ...sx }}>
      <Typography
        sx={{
          fontSize: "16px",
          lineHeight: "24px",
          color: "grey.700",
        }}
      >
        {title}
      </Typography>
      {value && (
        <Stack direction="row" gap={1} alignItems={"center"}>
          <Typography
            {...(id &&
              id == "phone-number" && {
                component: "a",
                href: `tel:${value
                  .replace(/^0/, "+90")
                  .replace(/\s/g, "")
                  .replace("(", "")
                  .replace(")", "")}`,
              })}
            sx={{
              fontSize: "20px",
              lineHeight: "30px",
              color: "gray.900",
              fontWeight: 500,
              ...(id &&
                id == "phone-number" && {
                  cursor: "pointer",
                }),
            }}
          >
            {value}
          </Typography>
          {arrow && <Iconify icon="mdi:arrow-right" />}
        </Stack>
      )}
    </Stack>
  );
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

export function OptionsButton({
  selectedMontage,
  setSelectedMontage,
}: {
  selectedMontage: any;
  setSelectedMontage: Dispatch<React.SetStateAction<string>>;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openCustomModal, setOpenCustomModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [customReason, setCustomReason] = useState("");
  const [error, setError] = useState("");
  const [rejectLoading, setRejectLoading] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value?: string) => {
    if (value) {
      setCustomReason(value);
      setOpenConfirmModal(true);
    }
    setAnchorEl(null);
  };

  const handleCustomReasonSubmit = async () => {
    if (!customReason.trim()) {
      setError("Lütfen bir red sebebi giriniz");
      return;
    }
    setOpenCustomModal(false);
    setOpenConfirmModal(true);
  };

  const handleConfirm = async () => {
    try {
      setRejectLoading(true);
      await axiosInstance.put(`/montage/application/${selectedMontage}`, {
        is_rejected: true,
        rejection_reason: customReason,
      });
      enqueueSnackbar(
        "Randevu iptal edildi. Reddetme Nedeni: " +
          (MontageRejectReasonLabel[customReason as MontageRejectReason] ??
            "Diğer"),
        { variant: "success" }
      );

      setOpenConfirmModal(false);
      setOpenCustomModal(false);
      setCustomReason("");
      setError("");
      setRejectLoading(false);
      setSelectedMontage("");
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Bir hata oluştu.", { variant: "error" });
    } finally {
      setRejectLoading(false);
    }
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        color="error"
      >
        REDDET
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose(MontageRejectReason.wrong_montage);
          }}
          disableRipple
        >
          <DangerousIcon />
          Yanlış Montaj
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose(MontageRejectReason.wrong_location);
          }}
          disableRipple
        >
          <WrongLocationIcon />
          Uzak Mesafe
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenCustomModal(true);
          }}
          disableRipple
        >
          <MoreHorizIcon />
          Diğer
        </MenuItem>
      </StyledMenu>

      {/* Özel Red Sebebi Modalı */}
      <Modal
        open={openCustomModal}
        onClose={() => setOpenCustomModal(false)}
        aria-labelledby="custom-reason-modal"
        aria-describedby="custom-rejection-reason"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Red Sebebi
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={
              MontageRejectReasonLabel[customReason as MontageRejectReason] ??
              customReason
            }
            onChange={(e) => {
              setCustomReason(e.target.value);
              setError("");
            }}
            error={!!error}
            helperText={error}
            placeholder="Red sebebini giriniz..."
            sx={{ mb: 2 }}
          />
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              onClick={() => {
                setOpenCustomModal(false);
                setCustomReason("");
                setError("");
              }}
              variant="outlined"
              color="error"
            >
              İptal
            </Button>
            <Button
              onClick={handleCustomReasonSubmit}
              variant="contained"
              color="secondary"
            >
              Randevuyu iptal et
            </Button>
          </Stack>
        </Box>
      </Modal>

      {/* Onay Modalı */}
      <Dialog
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 2, minWidth: 400 }}>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Onay
          </Typography>
          <Typography sx={{ mb: 3 }}>
            Randevuyu iptal etmek istediğinizden emin misiniz?
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              onClick={() => setOpenConfirmModal(false)}
              variant="outlined"
              color="inherit"
            >
              Hayır
            </Button>
            <Button
              onClick={handleConfirm}
              variant="contained"
              color="secondary"
              autoFocus
              disabled={rejectLoading}
              startIcon={
                rejectLoading ? (
                  <CircularProgress color="secondary" size={20} />
                ) : null
              }
            >
              Evet
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </div>
  );
}

export const MontageDetailModal = ({
  selectedMontage,
  setSelectedMontage,
}: {
  selectedMontage: string;
  setSelectedMontage: Dispatch<React.SetStateAction<string>>;
}) => {
  const { user } = useAuth();

  const [selectedDateTimes, setSelectedDateTimes] = useState<
    { date: string; time: string }[]
  >([]);
  const [selectedService, setSelectedService] = useState("");
  const [postError, setError] = useState("");
  const map = useOpenStreetMap();
  // const [selectedPosition, setSelectedPosition] =
  //   useState<google.maps.LatLngLiteral | null>(null);
  const [savedPosition, setSavedPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [show, setShow] = useState(false);
  // const [map, setMap] = useState<google.maps.Map | null>(null);
  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  // });

  // const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>({
  //   lat: 40.98691,
  //   lng: 28.76995,
  // });

  // const onLoad = useCallback(function callback(map: google.maps.Map) {
  //   map.setZoom(10);
  //   map.setCenter(mapCenter);

  //   setMap(map);
  // }, []);

  // const onUnmount = useCallback(function callback(map: any) {
  //   setMap(null);
  // }, []);

  // const handleMapClick = (event: google.maps.MapMouseEvent) => {
  //   if (event.latLng) {
  //     setSelectedPosition({
  //       lat: event.latLng.lat(),
  //       lng: event.latLng.lng(),
  //     });
  //   }
  // };

  const { data: services } = useSWR<IServiceProvider[]>(
    `/service-provider/application`,
    fetcher
  );

  const { data, error, isLoading } = useSWR<
    IMontageApplication & { service_provider: IServiceProvider }
  >(
    selectedMontage ? `/montage/application/${selectedMontage}` : null,
    fetcher,
    { keepPreviousData: false }
  );

  const filteredServices =
    data?.type == "mobile"
      ? services?.filter(
          (f) => f.mobile_device_count && f.mobile_device_count != 0
        )
      : services;

  const onSave = async ({
    position,
    dates,
    service,
  }: {
    position: google.maps.LatLngLiteral;
    dates: string[];
    service: string;
  }) => {
    setError("");
    try {
      await axiosInstance.post(
        `/service-provider/${service}/reservation/mobile`,
        {
          montage: selectedMontage,
          position,
          dates,
        }
      );

      mutate(`/montage/application/${selectedMontage}`);
      enqueueSnackbar("İşlem Başarılı", { variant: "success" });
    } catch (error: any) {
      let errorString = "Beklenmedik bir hata oluştu.";
      console.log(error);
      const { data } = error.response ?? {};
      if (data) {
        const { errors } = data;
        if (errors) {
          errorString = errors
            ? errors?.join("<br/>")
            : "Beklenmedik bir hata oluştu.";
          console.log(errors);
        }
      }
      setError(errorString);
      // enqueueSnackbar(e.message, { variant: "error" });
    }
  };
  useEffect(() => {
    if (data) {
      if (data?.type !== "mobile") return;
      if (!data?.location?.lat || !data?.location?.lng) return;

      const mapCenter = {
        lat: data?.location?.lat,
        lng: data?.location?.lng,
      };
      map.setZoom(17);
      map.setCoordinates(mapCenter);

      //setSavedPosition(mapCenter);
    }
  }, [data]);

  const handleMapClick = (event: LeafletMouseEvent) => {
    // if (event.latLng) {
    //   const position = {
    //     lat: event.latLng.lat(),
    //     lng: event.latLng.lng(),
    //   };
    //   setSelectedPosition(position);
    //   onChangeForm("coordinates", position);
    // }
    const { lat, lng } = event.latlng;

    const position = {
      lat,
      lng,
    };

    map.setCoordinates(position);

    //onChangeForm("coordinates", position);
    //setSavedPosition(position);
  };

  useEffect(() => {
    if (data) {
      setSelectedService(data?.service_provider?._id ?? "");
      if(data?.location?.lat && data?.location?.lng) {
        map.setCoordinates({
          lat: data?.location?.lat,
          lng: data?.location?.lng,
        });
      }
      map.setZoom(12);
    }
  }, [data]);

  const onMapSubmit = async (lat: number, lng: number) => {
    try {
      if (!lat || !lng) {
        enqueueSnackbar("Haritada yer işaretleyin.", { variant: "error" });
        return;
      }

      await axiosInstance.put(`/montage/application/${selectedMontage}`, {
        location: {
          lat,
          lng,
        },
      });

      enqueueSnackbar("Konum güncellendi.", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Bir hata oluştu.", { variant: "error" });
      console.log(e);
      return;
    }
  };

  const updateDateTimes = async () => {
    const timesWithUTC: { date: string; time: string }[] = [];

    const times = selectedDateTimes;
    times.forEach((row: { time: string; date: string }) => {
      const utcDate = new Date();

      const { time, date } = row; // yyyy.MM.dd hh:mm

      const dateParts = date.split(".");
      const timeParts = time.split(":");

      utcDate.setUTCHours(parseInt(timeParts[0]));
      utcDate.setUTCMinutes(parseInt(timeParts[1]));

      utcDate.setUTCDate(parseInt(dateParts[2]));
      utcDate.setUTCMonth(parseInt(dateParts[1]) - 1);
      utcDate.setUTCFullYear(parseInt(dateParts[0]));
      utcDate.setUTCSeconds(0);
      utcDate.setUTCMilliseconds(0);

      timesWithUTC.push({
        date: utcDate.toISOString(),
        time: time,
      });
    });

    if (!window.confirm("Devam etmek istiyor musunuz?")) {
      return;
    }
    try {
      await axiosInstance.put(`/montage/application/${selectedMontage}`, {
        application_date: timesWithUTC.map((row) => row.date),
        service_provider: selectedService,
        ...(data?.application_date?.length
          ? {
              isDateUpdate: true,
            }
          : {
              isDateUpdate: false,
            }),
      });
      mutate(`/montage/application/${selectedMontage}`);
      enqueueSnackbar("Montaj randevu tarihleri düzenlendi.", {
        variant: "success",
      });

      setSelectedDateTimes([]);
    } catch (e) {
      enqueueSnackbar("Bir hata oluştu.", {
        variant: "error",
      });
      console.log(e);

      return;
    }
  };

  const totalVehicles =
    data?.vehicles?.reduce((total: number, row: any) => {
      total += row.quantity;
      return total;
    }, 0) ?? 0;

  if (!selectedMontage) return null;

  if (isLoading) {
    return <CircularProgressModal />;
  }

  if (!data) return null;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "grey.50",
          zIndex: 1,
          mt: "-64px",
        }}
      >
        <Stack gap={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              sx={{ fontSize: "24px", lineHeight: "48px", fontWeight: 500 }}
            >
              Montaj Başvuru Bilgileri
            </Typography>
            <IconButton
              onClick={() => {
                setSelectedMontage("");
              }}
            >
              <Iconify icon="ic:baseline-close" />
            </IconButton>
          </Stack>
          <Box
            sx={{
              border: "1px solid",
              borderColor: "divider",
              borderRadius: 4,
              backgroundColor: "#fff",
            }}
          >
            <Stack
              sx={{
                "&>*": {
                  borderBottom: "1px solid",
                  borderBottomColor: "divider",
                  p: 3,
                },
                "&>*:last-child": {
                  borderBottom: "0px",
                },
              }}
            >
              <Stack direction="row">
                <ModalItem
                  title="Firma İsmi"
                  value={data?.company_name ?? "-"}
                />
                <ModalItem
                  title="Montaj Türü"
                  value={data?.type === "mobile" ? "Mobil" : "Standart"}
                />
                <Box sx={{ flex: 1 }} />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  title="Yetkili İsmi Soyisim"
                  value={data.authorized_person_name}
                />
                <ModalItem
                  title="Yetkili Mail Adresi"
                  value={data.authorized_person_email}
                />
                <ModalItem
                  title="Yetkili Telefon Numarası"
                  value={data.authorized_person_phone}
                  id="phone-number"
                />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  title={`Taşıt Bilgisi (${totalVehicles} Araç)`}
                  value={(data.vehicles ?? [])
                    .map((v: any) => `${v.vehicle_type} ${v.quantity} Adet`)
                    .join(" - ")}
                />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  title={`Vergi Numarası`}
                  value={data.tax_number ? data.tax_number.toString() : "-"}
                />
              </Stack>
              <Stack direction="row">
                <ModalItem title="İl" value={data.address?.city ?? "-"} />
                <ModalItem title="İlçe" value={data.address?.district ?? "-"} />
                <ModalItem title="Adres" value={data.address?.fullAddress ?? "-"} />
                {/* <ModalItem title="Sokak" value={data.address?.street ?? "-"} /> */}
              </Stack>
              <Stack gap={3}>
                <Stack direction="row">
                  {data.service_provider && user?.role === "admin" && ( 
                    <div style={{ flex: 1 }}>
                      <Tooltip title="Bayii Detayı" arrow>
                        <span style={{ display: "inline-block" }}>
                          <Link
                            to={
                              PATHS.admin.servicesProviders.root +
                              `?search=${data.service_provider.offical_title}`
                            }
                          >
                            <ModalItem
                              arrow
                              title="Randevu Alınan Bayi Bilgisi"
                              value={data.service_provider.signage_name}
                            />
                          </Link>
                        </span>
                      </Tooltip>
                    </div>
                  )}
                  {/* {user?.role === "service_provider" &&
                    data.application_date?.length === 0 &&
                    data.type === "service" && (
                      <ModalItem
                        title="Randevu Tarihi"
                        value="Atama bekliyor."
                      />
                    )} */}
                  {!!(data?.application_date ?? []).length && (
                    <ModalItem
                      title="Randevu Tarihi"
                      value={
                        formatApplicationDates(
                          data?.application_date as unknown as string[]
                        )
                        // .map((date: any) => {
                        //   const parsedDate = parseISO(date);
                        //   const zonedDate = toZonedTime(parsedDate, "UTC");
                        //   return format(zonedDate, "dd MMM yyyy HH:mm", {
                        //     locale: tr,
                        //   });
                        // })
                        // .join(" - ")
                      }
                    />
                  )}
                </Stack>
                <Stack direction="row">
                  <ModalItem
                    title="Aktivasyon Kodu"
                    value={data.mint_code ? "Alındı." : "Alınmadı."}
                  />
                  {data.type === "mobile" && data?.location?.lat && data?.location?.lng && (
                    <Stack sx={{ flex: 1 }}>
                      <ModalItem title="Konum" />
                      <a
                        target="_blank"
                        href={`https://www.google.com/maps/search/${data.location.lat},${data.location.lng}`}
                        rel="noreferrer"
                      >
                        <Stack direction="row" gap={0.25} alignItems="center">
                          Google'da Göster
                          <Iconify icon="mdi:map-marker-outline" />
                        </Stack>
                      </a>
                    </Stack>
                  )}
                  {user?.role === "admin" &&
                    data.reference &&
                    data.reference.length === 6 && (
                      <div style={{ flex: 1 }}>
                        <Tooltip title="Referansı Göster" arrow>
                          <span style={{ display: "inline-block" }}>
                            <Link
                              to={`/admin/referance?search=${data.reference}`}
                            >
                              <ModalItem
                                sx={{ flex: 0 }}
                                title="Referans"
                                value={data.reference ?? "-"}
                                arrow
                              />
                            </Link>
                          </span>
                        </Tooltip>
                      </div>
                    )}
                </Stack>
              </Stack>
              {data?.service_provider?.offical_title && (
                <Stack direction="row" gap={3}>
                  <Stack gap={1} sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "grey.700",
                      }}
                    >
                      Montaj Durumu
                    </Typography>
                    <MontageStatusChanger
                      id={selectedMontage}
                      status={data.status ?? ""}
                    />
                  </Stack>
                  <Stack gap={1} sx={{ flex: 1 }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "grey.700",
                      }}
                    >
                      Tamamlanan Araç Sayısı
                    </Typography>
                    <MontageStatusChanger
                      id={selectedMontage}
                      status={data.completed_vehicle_count ?? ""}
                      forceKey={"completed_vehicle_count"}
                      total_vehicle={totalVehicles ?? 0}
                    />
                  </Stack>
                </Stack>
              )}
              {user?.role === "admin" && (
                <Stack direction="row" gap={3} alignItems="center">
                  <FormControl size="small" sx={{ minWidth: 200 }}>
                    <InputLabel>Bayi Seç</InputLabel>
                    <Select
                      //@ts-ignore
                      value={selectedService}
                      onChange={(e) => {
                        setSelectedService(e.target.value);
                      }}
                      size="small"
                      label="Bayi Seç"
                    >
                      {filteredServices?.map((service) => (
                        <MenuItem
                          key={service?._id?.toString()}
                          value={service?._id?.toString()}
                        >
                          {`${
                            service.address
                              ? `${
                                  service.address.city
                                    ? service.address.city
                                    : ""
                                }-${
                                  service.address.district
                                    ? "" + service.address.district
                                    : ""
                                }`
                              : ""
                          }-${service.signage_name ?? "-"}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <MontageTimePicker
                    count={totalVehicles}
                    service={selectedService}
                    onClose={() => {}}
                    onSave={(resp) => {
                      setSelectedDateTimes(resp);
                    }}
                    showButton
                    show={show}
                    setShow={setShow}
                  />
                  {!!selectedDateTimes.length && (
                    <Stack
                      sx={{ ml: "auto" }}
                      direction="row"
                      alignItems="center"
                      gap={1}
                    >
                      <Typography sx={{ fontSize: "12px" }}>
                        {selectedDateTimes.length} adet randevu seçildi.
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Iconify icon="ic:round-save" />}
                        onClick={() => {
                          updateDateTimes();
                        }}
                      >
                        Yeni Randevuları Kaydet
                      </Button>
                    </Stack>
                  )}
                </Stack>
              )}
            </Stack>
          </Box>
          {data.type === "service" && (
            <div>
              {data.is_rejected ? (
                <Typography
                  sx={{ py: 2, color: "error.main", fontWeight: 600 }}
                >
                  Reddedildi.
                </Typography>
              ) : (
                user?.role != "service_provider" && (
                  <OptionsButton
                    selectedMontage={selectedMontage}
                    setSelectedMontage={setSelectedMontage}
                  />
                )
              )}
            </div>
          )}
          {
            // !data.service_provider && //
            data.type === "mobile" && (
              <Stack gap={2}>
                <Stack
                  sx={{
                    borderRadius: 4,
                    overflow: "hidden",
                    ...(user?.role === "service_provider"
                      ? { userSelect: "none", pointerEvents: "none" }
                      : {}),
                  }}
                >
                  {/* <GoogleMap
                      mapContainerStyle={{ height: 500 }}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      //onClick={handleMapClick}
                    >

                      
                    </GoogleMap>  */}

                  <OpenStreetMap
                    coordinates={map.coordinates}
                    zoom={map.zoom}
                    onMapClick={handleMapClick}
                  />
                  <Button
                    sx={{ justifySelf: "flex-start", flexGrow: 0 }}
                    color="secondary"
                    variant="contained"
                    onClick={() =>
                      onMapSubmit(map?.coordinates?.lat ?? 0, map?.coordinates?.lng ?? 0)
                    }
                  >
                    Konumu Kaydet
                  </Button>
                </Stack>

                {postError && <Alert color="error">{postError}</Alert>}
                {!data.is_rejected && user?.role != "service_provider" ? (
                  <Stack direction="row" gap={2}>
                    <OptionsButton
                      selectedMontage={selectedMontage}
                      setSelectedMontage={setSelectedMontage}
                    />
                  </Stack>
                ) : (
                  <Typography
                    sx={{ py: 2, color: "error.main", fontWeight: 600 }}
                  >
                    Reddedildi.
                  </Typography>
                )}
              </Stack>
            )
          }
        </Stack>
      </Box>
      <MontageCalendar
        handleClose={() => {
          setSavedPosition(null);
        }}
        open={!!savedPosition}
        montage={data}
        onSaved={({ dates, service }) => {
          if (!savedPosition) {
            return;
          }
          onSave({ dates, service, position: savedPosition });
        }}
        maxCount={
          data.vehicles?.reduce((total, vehilce) => {
            total += vehilce.quantity;
            return total;
          }, 0) ?? 0
        }
      />
    </>
  );
};

const TableLableStyle = {
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 500,
  color: "#3D3D3D",
};

const escapeCSV = (value: string) => {
  if (value == null) return "";
  let stringValue = String(value);
  if (
    stringValue.includes('"') ||
    stringValue.includes(",") ||
    stringValue.includes("\n")
  ) {
    stringValue = `"${stringValue.replace(/"/g, '""')}"`;
  }
  return stringValue;
};

export const MontageListSection = () => {
  const { user } = useAuth();
  const [selectedMontage, setSelectedMontage] = useState("");
  const [debouncedSearch, search, setSearch] = useDebouncedValue("");
  const [statusFilter, setStatusFilter] = useState("waiting");

  const params = new URLSearchParams();
  if (debouncedSearch) params.append("search", debouncedSearch as string);

  const serviceView = user?.role === "service_provider";

  if (user && user?.role === "service_provider") {
    params.append("service_provider", user?.id);
  }

  const { data: montageList, isLoading } = useSWR<any[]>(
    `/montage/application?${params.toString()}`,
    fetcher
  );

  useEffect(() => {
    mutate(`/montage/application?${params.toString()}`);
  }, [setSelectedMontage]);

  const exportToExcel = () => {
    const header = [
      "Oluşturulma Tarihi",
      "Randevu Tipi",
      "Oluşturan Yetkili",
      "Şehir",
      "İlçe",
      "Montaj Yapılacak Araç Sayısı",
      "Araç Bilgisi",
      "Yetkili Kişi Adı",
      "Yetkili Kişi E-mail",
      "Yetkili Kişi Telefon",
      "Referans Kodu",
      "Bayi Kodu",
      "Montajın Gerçekleşeceği Bayi",
      "Montajın Gerçekleştirileceği Tarih",
      "Darphane Onayı",
      "Durum",
      "Tamamlanan Araç Sayısı",
      "ID",
    ];

    const rows = [
      header,
      ...(montageList ?? []).map((item) => [
        format(
          toZonedTime(parseISO(item.createdAt), "UTC"),
          "dd MMM yyyy HH:mm",
          {
            locale: tr,
          }
        ) ?? "",
        item.type === "mobile" ? "Mobil" : "Standart",
        item.is_admin ? "Admin" : item.is_service_provider ? "Bayi" : "Kullanıcı",
        item.address?.city ?? "",
        item.address?.district ?? "",
        item.vehicles.reduce((acc: number, q: any) => acc + q.quantity, 0) ??
          "",
        item.vehicles
          .map((v: any) => `${v.quantity} ${v.vehicle_type}`)
          .join(" - ") ?? "",
        item.authorized_person_name ?? "",
        item.authorized_person_email ?? "",
        item.authorized_person_phone ?? "",
        item.reference ?? "",
        item.service_provider?.service_provider_code ?? "",
        item.service_provider?.offical_title ?? "",
        item.application_date
          .map((date: string) => {
            const parsedDate = parseISO(date);
            const zonedDate = toZonedTime(parsedDate, "UTC");
            return format(zonedDate, "dd MMM yyyy HH:mm", {
              locale: tr,
            });
          })
          .join(" - "),
        item.mint_code ? "Alındı" : "Alınmadı",
        // @ts-ignore
        item.is_rejected ? "Reddedildi" : IMontageStatusLabel[item.status],
        item.completed_vehicle_count ?? "",
        item._id ?? "",
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(rows);

    ws["!cols"] = header.map(() => ({ wch: 30, hch: 10 }));
    ws["!rows"] = [{hpx: 30}]; 

    const headerRange = XLSX.utils.decode_range(ws["!ref"] as string);

    for (let C = headerRange.s.c; C <= headerRange.e.c; C++) {
      let cellRef = XLSX.utils.encode_cell({ r: 0, c: C });
      if (ws[cellRef]) {
        ws[cellRef].s = {
          font: { bold: true },
          alignment: { vertical: "center", horizontal: "center" },
        };
      }
    }

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    saveAs(blob, `Montaj-Basvuruları-${new Date().valueOf()}.xlsx`);
  };

  const exportCSV = () => {
    exportToExcel();
    return;

    // const header = [
    //   "ID",
    //   "Firma Adı",
    //   "Yetkili Kişi Adı",
    //   "Yetkili Kişi E-mail",
    //   "Yetkili Kişi Telefon",
    //   "Şehir",
    //   "İlçe",
    //   "Cadde",
    //   "Referans Kodu",
    //   "Bayii",
    //   "Darphane Onayı",
    //   "Araç Bilgisi",
    //   "Başvuru Bilgisi",
    //   "Başvuru Tipi",
    //   "Durum",
    //   "Oluşturulma Tarihi",
    //   "Tamamlanan Araç Sayısı",
    // ];

    // const csvRows = [
    //   header.join(","),
    //   ...(montageList ?? []).map((item) => {
    //     const row: any = [
    //       escapeCSV(item._id),
    //       escapeCSV(item.company_name),
    //       escapeCSV(item.authorized_person_name),
    //       escapeCSV(item.authorized_person_phone),
    //       escapeCSV(item.authorized_person_email),
    //       escapeCSV(item.address?.city ?? ""),
    //       escapeCSV(item.address?.district ?? ""),
    //       escapeCSV(item.address?.street ?? ""),
    //       escapeCSV(item.reference ?? ""),
    //       escapeCSV(item.service_provider?.offical_title ?? ""),
    //       escapeCSV(item.mint_code ? "Alındı" : "Alınmadı"),
    //       escapeCSV(
    //         item.vehicles
    //           .map((v: any) => `${v.quantity} ${v.vehicle_type}`)
    //           .join(" - ")
    //       ),
    //       escapeCSV(
    //         item.application_date
    //           .map((date: string) => {
    //             const parsedDate = parseISO(date);
    //             const zonedDate = toZonedTime(parsedDate, "UTC");
    //             return format(zonedDate, "dd MMM yyyy HH:mm", {
    //               locale: tr,
    //             });
    //           })
    //           .join(" - ")
    //       ),
    //       escapeCSV(item.type === "mobile" ? "Mobil" : "Standart"),
    //       escapeCSV(
    //         // @ts-ignore
    //         item.is_rejected ? "Reddedildi" : IMontageStatusLabel[item.status]
    //       ),
    //       escapeCSV(
    //         format(item.createdAt, "dd MMM yyyy HH:mm", { locale: tr })
    //       ),
    //       escapeCSV(item.completed_vehicle_count ?? ""),
    //     ];

    //     return row.join(",");
    //   }),
    // ];

    // const csvString = csvRows.join("\n");

    // const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });

    // const url = window.URL.createObjectURL(blob);
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = `basvurular-${new Date().valueOf()}.csv`;

    // document.body.appendChild(a);
    // a.click();
    // window.URL.revokeObjectURL(url);
  };

  const futureMontageList = montageList?.filter((item) => {
    const now = new Date().valueOf();
    const times = (item.application_date ?? [])
      .map((t: string) => new Date(t).valueOf())
      .sort((a: number, b: number) => a - b);

    return (
      times[0] > now &&
      item.status == IMontageStatus.waiting &&
      !item.is_rejected
    );
  });


  const futureMontageVehicleCount = futureMontageList?.reduce((acc, item) => {
    const vehicleSum = item.vehicles?.reduce(
      (sum: any, vehicle: any) => sum + (vehicle.quantity || 0),
      0
    );
    return acc + (vehicleSum || 0);
  }, 0);

  return (
    <>
      {selectedMontage && (
        <MontageDetailModal
          selectedMontage={selectedMontage}
          setSelectedMontage={setSelectedMontage}
        />
      )}
      {!selectedMontage && (
        <Stack sx={{ gap: 2 }}>
          <Stack
            gap={2}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* Sola yaslanan elemanlar */}
            <Stack direction="row" gap={1} alignItems="center">
              <TextField
                placeholder="Ara"
                sx={{ maxWidth: 200 }}
                size="small"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              {user?.role === "service_provider" &&
                (futureMontageVehicleCount != 0 ? (
                  <Typography sx={{ fontSize: "12px" }}>
                    Yakın zamanda <b>{futureMontageVehicleCount}</b> adet araç
                    montaj başvurunuz bulunmakta.
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: "12px" }}>
                    Yakın zamanda montaj başvurunuz bulunmamaktadır.
                  </Typography>
                ))}
            </Stack>

            {/* Sağa yaslanan elemanlar */}
            <Stack direction="row" alignItems="center" gap={2}>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="status-filter">Durum</InputLabel>
                <Select
                  labelId="status-filter"
                  id="status-filter"
                  label="Durum"
                  onChange={(e) => {
                    setStatusFilter(e.target.value as string);
                  }}
                  value={statusFilter}
                >
                  {Object.entries(IMontageStatusLabel).map((value, key) => (
                    <MenuItem value={value[0]} key={key}>
                      {value[1]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {user?.role === "service_provider" && (
                <Link
                  target="_blank"
                  to={`/utts-montaj-basvuru?ref=S-${user?.id}`}
                >
                  <Stack direction="row" alignItems="center" gap={1}>
                    Montaj Oluştur
                    <Iconify icon="solar:alt-arrow-right-line-duotone" />
                  </Stack>
                </Link>
              )}
            </Stack>
          </Stack>

          <TableContainer sx={{ maxHeight: "70vh", overflow: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={{ backgroundColor: "grey.200" }}>
                  <TableCell
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "grey.200",
                      zIndex: 2,
                    }}
                  >
                    <Typography sx={TableLableStyle}>
                      Randevu Oluşturulma Tarihi
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "grey.200",
                      zIndex: 2,
                    }}
                  >
                    <Typography sx={TableLableStyle}>
                      Oluşturan Yetkili
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "grey.200",
                      zIndex: 2,
                    }}
                  >
                    <Typography sx={TableLableStyle}>Montaj Tarihi</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "grey.200",
                      zIndex: 2,
                    }}
                  >
                    <Typography sx={TableLableStyle}>İsim</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "grey.200",
                      zIndex: 2,
                    }}
                  >
                    <Typography sx={TableLableStyle}>Şirket</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "grey.200",
                      zIndex: 2,
                    }}
                  >
                    <Typography sx={TableLableStyle}>Araç Sayısı</Typography>
                  </TableCell>
                  {!serviceView && (
                    <TableCell
                      sx={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: "grey.200",
                        zIndex: 2,
                      }}
                    >
                      <Typography sx={TableLableStyle}>Bayi</Typography>
                    </TableCell>
                  )}
                  <TableCell
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "grey.200",
                      zIndex: 2,
                    }}
                  >
                    <Typography sx={TableLableStyle}>Durum</Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "grey.200",
                      zIndex: 2,
                    }}
                  >
                    {montageList?.length && (
                      <ButtonBase
                        onClick={() => {
                          exportCSV();
                        }}
                        sx={{
                          px: 1,
                          borderRadius: 2,
                          fontFamily: "inherit",
                          gap: 1,
                        }}
                      >
                        Tümünü İndir
                        <Iconify icon="vscode-icons:file-type-excel" />
                      </ButtonBase>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading && !montageList?.length && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography sx={{ textAlign: "center", opacity: 0.75 }}>
                        Veri Bulunamadı
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {isLoading && (
                  <>
                    {Array(5)
                      .fill("")
                      .map((row, index) => (
                        <StyledTableRow key={index}>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          {!serviceView && (
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                          )}
                        </StyledTableRow>
                      ))}
                  </>
                )}
                {statusFilter != "rejected"
                  ? montageList
                      ?.filter(
                        (x) => !x.is_rejected && x.status === statusFilter
                      )
                      .map((row, index) => (
                        <StyledTableRow key={index}>
                          <TableCell>
                            <Typography sx={{ fontSize: "14px" }}>
                              {fDateTime(row.createdAt)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Chip
                              label={
                                row.is_admin
                                  ? "Admin"
                                  : row.is_service_provider
                                  ? "Bayi"
                                  : "Kullanıcı"
                              }
                              size="small"
                              color={
                                row.is_admin
                                  ? "error"
                                  : row.is_service_provider
                                  ? "warning"
                                  : "success"
                              }
                              variant="filled"
                            />
                          </TableCell>
                          <TableCell>
                            <Typography sx={{ fontSize: "14px" }}>
                              {fDateTime(row.application_date[0])}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableLableStyle}>
                              {(
                                row.authorized_person_name as string
                              )?.toLocaleUpperCase() ?? "-"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableLableStyle}>
                              {(row.company_name as string).toLocaleUpperCase()}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableLableStyle}>
                              {row.vehicles?.reduce(
                                (acc: number, q: any) => acc + q.quantity,
                                0
                              )}
                            </Typography>
                          </TableCell>
                          {!serviceView && (
                            <TableCell>
                              <Stack
                                alignItems="center"
                                direction="row"
                                gap={1}
                              >
                                <Tooltip
                                  title={
                                    row?.service_provider?.offical_title ??
                                    "Atama Bekleniyor"
                                  }
                                  arrow
                                >
                                  <Chip
                                    size="small"
                                    label={
                                      (
                                        (row?.service_provider
                                          ?.offical_title as string) ??
                                        "Atama Bekleniyor"
                                      ).substring(0, 18) +
                                      (row?.service_provider?.offical_title
                                        ?.length > 18
                                        ? "..."
                                        : "")
                                    }
                                    color={
                                      row?.service_provider?.offical_title
                                        ? "secondary"
                                        : "warning"
                                    }
                                  />
                                </Tooltip>
                                {row.type === "mobile" && (
                                  <Tooltip title="Mobil Montaj" arrow>
                                    <span style={{ fontSize: 0 }}>
                                      <Iconify icon="hugeicons:mobile-navigator-01" />
                                    </span>
                                  </Tooltip>
                                )}
                              </Stack>
                            </TableCell>
                          )}
                          {row.is_rejected ? (
                            <TableCell>Reddedildi</TableCell>
                          ) : (
                            <>
                              <TableCell>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  {/* @ts-ignore */}
                                  {IMontageStatusLabel[row.status]}
                                  {user?.role === "admin" &&
                                    row.type === "service" &&
                                    row.application_date.length === 0 && (
                                      <Tooltip
                                        arrow
                                        title="Lütfen randevu oluşturun."
                                      >
                                        <span style={{ fontSize: 0 }}>
                                          <Iconify
                                            color="warning.main"
                                            icon="ic:round-warning"
                                          />
                                        </span>
                                      </Tooltip>
                                    )}
                                </Stack>
                              </TableCell>
                            </>
                          )}
                          <TableCell>
                            <Link
                              to="#"
                              onClick={() => {
                                setSelectedMontage(row._id);
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                gap={1}
                                justifyContent="end"
                              >
                                Daha fazlası
                                <Iconify icon="solar:alt-arrow-right-line-duotone" />
                              </Stack>
                            </Link>
                          </TableCell>
                        </StyledTableRow>
                      ))
                  : montageList
                      ?.filter((x) => x.is_rejected == true)
                      .map((row, index) => (
                        <StyledTableRow key={index}>
                          <TableCell>
                            <Typography sx={{ fontSize: "14px" }}>
                              {fDateTime(row.createdAt)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={{ fontSize: "14px" }}>
                              <Chip
                                label={
                                  row.is_admin
                                    ? "Admin"
                                    : row.is_service_provider
                                    ? "Bayi"
                                    : "Kullanıcı"
                                }
                                size="small"
                                color={
                                  row.is_admin
                                    ? "error"
                                    : row.is_service_provider
                                    ? "warning"
                                    : "success"
                                }
                                variant="filled"
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={{ fontSize: "14px" }}>
                              {fDateTime(row.application_date[0])}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableLableStyle}>
                              {(
                                row.authorized_person_name as string
                              ).toLocaleUpperCase()}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableLableStyle}>
                              {(row.company_name as string).toLocaleUpperCase()}
                            </Typography>
                          </TableCell>
                          {!serviceView && (
                            <TableCell>
                              <Stack
                                alignItems="center"
                                direction="row"
                                gap={1}
                              >
                                <Tooltip
                                  title={
                                    row?.service_provider?.offical_title ??
                                    "Atama Bekleniyor"
                                  }
                                  arrow
                                >
                                  <Chip
                                    size="small"
                                    label={
                                      (
                                        (row?.service_provider
                                          ?.offical_title as string) ??
                                        "Atama Bekleniyor"
                                      ).substring(0, 18) +
                                      (row?.service_provider?.offical_title
                                        ?.length > 18
                                        ? "..."
                                        : "")
                                    }
                                    color={
                                      row?.service_provider?.offical_title
                                        ? "secondary"
                                        : "warning"
                                    }
                                  />
                                </Tooltip>
                                {row.type === "mobile" && (
                                  <Tooltip title="Mobil Montaj" arrow>
                                    <span style={{ fontSize: 0 }}>
                                      <Iconify icon="hugeicons:mobile-navigator-01" />
                                    </span>
                                  </Tooltip>
                                )}
                              </Stack>
                            </TableCell>
                          )}
                          {row.is_rejected ? (
                            <TableCell>Reddedildi</TableCell>
                          ) : (
                            <>
                              <TableCell>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  {/* @ts-ignore */}
                                  {IMontageStatusLabel[row.status]}
                                  {user?.role === "admin" &&
                                    row.application_date.length === 0 && (
                                      <Tooltip
                                        arrow
                                        title="Lütfen randevu oluşturun."
                                      >
                                        <span style={{ fontSize: 0 }}>
                                          <Iconify
                                            color="warning.main"
                                            icon="ic:round-warning"
                                          />
                                        </span>
                                      </Tooltip>
                                    )}
                                </Stack>
                              </TableCell>
                            </>
                          )}
                          <TableCell>
                            <Link
                              to="#"
                              onClick={() => {
                                setSelectedMontage(row._id);
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                gap={1}
                                justifyContent="end"
                              >
                                Daha fazlası
                                <Iconify icon="solar:alt-arrow-right-line-duotone" />
                              </Stack>
                            </Link>
                          </TableCell>
                        </StyledTableRow>
                      ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}
    </>
  );
};
