import useSWR from "swr";
import { fDateTime, fetcher } from "../../../utils";
import {
  ButtonBase,
  IconButton,
  Skeleton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useDebouncedValue } from "../../../hooks";
import {  ServiceStatusList } from "../../../interface";
import { useSearchParams } from "react-router-dom";
import { Iconify } from "../../../components";
import { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import XLSX from "xlsx-js-style";

const TableLableStyle = {
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 500,
  color: "#3D3D3D",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.grey[100],
  },
}));

export const ProspectListSection = () => {
  const [searchParams] = useSearchParams();
  const [debouncedSearch, search, setSearch] = useDebouncedValue("");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (searchParams.get("search")) {
      setSearch(searchParams.get("search")!);
    }
  }, [searchParams.get("search")]);

  const params = new URLSearchParams();
  if (debouncedSearch) params.append("search", debouncedSearch);


  const { data, isLoading } = useSWR<any[]>(
    `/montage/pre?${params.toString()}`,
    fetcher
  );

 
  const exportToExcel = () => {
    const header = [
      "Firma Adı",
      "Yetkili Kişi E-mail",
      "Yetkili Kişi Telefon",
      "Araç Tipi",
      "Başvuru Tarihi",
    ];

    const rows = [
      header,
      ...(data ?? []).map((item) => [
        item.company_name ?? "",
        item.authorized_person_email ?? "",
        item.authorized_person_phone ?? "",
        item.vehicles?.map((v: any) => (
          `${v.quantity} ${v.vehicle_type}`
        )).join(", "),
        item.createdAt ? fDateTime(item.createdAt) : "-"
      ]),
    ];

   
      const ws = XLSX.utils.aoa_to_sheet(rows);
  
      ws["!cols"] = header.map(() => ({ wch: 30, hch: 10 }));
      ws["!rows"] = [{hpx: 30}]; 
  
      const headerRange = XLSX.utils.decode_range(ws["!ref"] as string);
  
      for (let C = headerRange.s.c; C <= headerRange.e.c; C++) {
        let cellRef = XLSX.utils.encode_cell({ r: 0, c: C });
        if (ws[cellRef]) {
          ws[cellRef].s = {
            font: { bold: true },
            alignment: { vertical: "center", horizontal: "center" },
          };
        }
      }
  
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  
      saveAs(blob, `Montaj-Basvuruları-IlkAdim-${new Date().valueOf()}.xlsx`);
  };

  return (
    <Stack gap={2}>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        direction="row"
      >
        <Stack direction="column" gap={1}>
          <TextField
            placeholder="Ara"
            sx={{ maxWidth: 350 }}
            size="small"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap={2}>
          {filter && (
            <IconButton
              onClick={() => {
                setFilter("");
              }}
            >
              <Iconify icon="ic:round-clear" />
            </IconButton>
          )}
        </Stack>
      </Stack>
      <TableContainer sx={{ maxHeight: "70vh", overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ backgroundColor: "grey.200" }}>
              <TableCell
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }}
              >
                <Typography sx={TableLableStyle}>Başvuru Tarihi</Typography>
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }}
              >
                <Typography sx={TableLableStyle}>Firma Adı</Typography>
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }}
              >
                <Typography sx={TableLableStyle}>E-Posta</Typography>
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }}
              >
                <Typography sx={TableLableStyle}>Telefon</Typography>
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }}
              >
                <Typography sx={TableLableStyle}>Araç</Typography>
              </TableCell>

              <TableCell
                align="right"
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "grey.200",
                  zIndex: 2,
                }}
              >
                {data?.length ? (
                  <ButtonBase
                    onClick={() => {
                      exportToExcel();
                    }}
                    sx={{
                      px: 1,
                      borderRadius: 2,
                      fontFamily: "inherit",
                      gap: 1,
                    }}
                  >
                    Tümünü İndir
                    <Iconify icon="vscode-icons:file-type-excel" />
                  </ButtonBase>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && !data?.length && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography sx={{ textAlign: "center", opacity: 0.75 }}>
                    Veri Bulunamadı
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {isLoading && !data?.length && (
              <>
                {Array(5)
                  .fill("")
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </>
            )}
            {data?.map((row) => (
              <StyledTableRow>
                <TableCell>
                  <Typography sx={{}}>
                    {row.createdAt ? fDateTime(row.createdAt) : "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={TableLableStyle}>
                    {row.company_name ?? "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={TableLableStyle}>
                    {row.authorized_person_email ?? "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={TableLableStyle}>
                    {row.authorized_person_phone ?? "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={TableLableStyle}>
                    {row.vehicles
                      ?.map((v: any) => `${v.quantity} ${v.vehicle_type}`)
                      .join(", ")}
                  </Typography>
                </TableCell>

                <TableCell align="right"></TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
