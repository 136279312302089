import { Box, Button, CircularProgress, Container, Stack, Typography, useTheme } from "@mui/material";
import React, { useState, useTransition } from "react";
import { mutate } from "swr";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { Header, IconError, IconSuccess } from "../components";
import { axiosInstance } from "../utils";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useNavigate } from "react-router-dom";

function MontageCancellation() {
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [success, setSuccess] = useState(false);
  const { id } = useParams();
  
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const onAccept = async () => {
    setLoading(true);
    setConfirmed(true);
    try {
      await axiosInstance.get(`/montage/application/cancel/${id}`);
      setSuccess(true);
      setTimeout(() => navigate('/'), 1000); 
    } catch (error) {
      
      setSuccess(false);
    }
    setLoading(false);
  };

  const onReject = () => navigate("/");

  return (
    <Container sx={{ mt: 2 }}>
      <Header />
      <Box
        sx={{
          backgroundColor: "#002B32",
          borderBottomLeftRadius: "24px",
          borderBottomRightRadius: "24px",
          pb: 4,
        }}
      >
        <Box sx={{ mx: 4, backgroundColor: "#fff", borderRadius: 4, p: 3 }}>
          {!loading && !confirmed && (
            <Stack
              alignItems="center"
              gap={3}
              sx={{ py: 4 }}
              direction="column"
            >
              <Typography
                sx={{
                  fontSize: "30px",
                  lineHeight: "45px",
                  textAlign: "center",
                  mb: "48px",
                  ...(isMobile
                    ? { fontSize: "34px", lineHeight: "46px", mb: "24px" }
                    : {}),
                }}
              >
                Montaj Başvurusunu iptal etmek istiyor musunuz ?
              </Typography>
              <Stack direction="row" gap={3}>
                <Button
                  size="large"
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    onAccept();
                    setConfirmed(true);
                  }}
                >
                  Evet
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    onReject();
                    setConfirmed(false);
                  }}
                >
                  Hayır
                </Button>
              </Stack>
            </Stack>
          )}
          {loading && (
            <Box
              sx={{
                position: "absolute",
                zIndex: 2,
                backgroundColor: "rgba(0,0,0,.3)",
                inset: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="secondary" />
            </Box>
          )}
          {!loading && confirmed && success && (
            <Stack alignItems="center" gap={3} sx={{ py: 4 }}>
              <IconSuccess />

              <Typography
                sx={{
                  fontSize: "32px",
                  lineHeight: "40px",
                  textAlign: "center",
                }}
              >
                {/* // {form.type === "mobile"
                //   ? "Mobil Randevu Talebiniz Alınmıştır"
                //   : "Randevunuz Başarıyla Oluşturulmuştur"} */}
              </Typography>
              <Typography
                sx={{
                  fontSize: "24px",
                  lineHeight: "34px",
                  textAlign: "center",
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: "Montaj başvurusu iptal edilmiştir.",
                  }}
                />
              </Typography>
            </Stack>
          )}
          {!loading && confirmed && !success && (
            <Stack alignItems="center" gap={3} sx={{ py: 4 }}>
              <IconError />
              <Typography
                sx={{
                  fontSize: "32px",
                  lineHeight: "40px",
                  textAlign: "center",
                }}
              >
                {/* // {form.type === "mobile"
                //   ? "Mobil Randevu Talebiniz Alınmıştır"
                //   : "Randevunuz Başarıyla Oluşturulmuştur"} */}
              </Typography>
              <Typography
                sx={{
                  fontSize: "24px",
                  lineHeight: "34px",
                  textAlign: "center",
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: "Başvuru iptal edilirken bir hata oluştu.",
                  }}
                />
              </Typography>
            </Stack>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default MontageCancellation;
