/* eslint-disable jsx-a11y/alt-text */
// src/components/MontajBasvuruForm.tsx

import React, { memo, useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  Checkbox,
  Button,
  Container,
  useTheme,
  useMediaQuery,
  Stack,
  Divider,
  Tooltip,
  Alert,
  FormHelperText,
} from "@mui/material";
import {
  CircularProgressModal,
  Footer,
  Header,
  Iconify,
  IconSuccess,
  MontageTimePicker,
} from "../components";
import useSWR from "swr";
import { axiosInstance, fDateTime, fetcher } from "../utils";
import { format, set } from "date-fns";
import { tr } from "date-fns/locale";
import { useStoredRef } from "../hooks";
import { ServiceVehicleTypes } from "../data";
import { enqueueSnackbar } from "notistack";
import MaskedInput from "react-text-mask";
import { useOpenStreetMap } from "../context/MapContext";
import { fetchCoordinates, OpenStreetMap } from "../components/common/Map";
import { LeafletMouseEvent } from "leaflet";
import { IServiceProvider } from "../interface";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context";
import {styled} from "styled-components";


const ShowTooltip = ({
  children,
  show,
  title,
}: {
  children: any;
  show: boolean;
  title: string;
}) => {
  if (!show) {
    return children;
  }

  return (
    <Tooltip arrow title={title} color="error">
      <span style={{ opacity: 0.5 }}>{children}</span>
    </Tooltip>
  );
};

const MontajBasvuruForm = memo(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const isPc = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));


  const { reference, clearReference } = useStoredRef();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>("");
  const [success, setSuccess] = useState(false);

  const [fieldErrors, setFieldErrors] = useState<any>({});

  const [KVKKChecked, setKVKKChecked] = useState(false);
  
  const [step, setStep] = useState(0);
  
  const [show, setShow] = useState(false);
  
  const { palette } = useTheme();
  const map = useOpenStreetMap();
  
  const secondary = palette.secondary.main;
  
  const { user } = useAuth();

  const CustomHeader = styled.p`
    font-size: ${isMobile ? "34px" : "60px"};
    line-height: ${isMobile ? "46px": "90px"};
    align-self: center;
    justify-self: center;
    color: #fff;
    margin-bottom: ${isMobile ? "24px":"48px"};
    display: inline-block;
  `;

  // const [selectedPosition, setSelectedPosition] =
  //   useState<google.maps.LatLngLiteral | null>(null);
  // const [savedPosition, setSavedPosition] =
  //   useState<google.maps.LatLngLiteral | null>(null);
  // const [map, setMap] = useState<google.maps.Map | null>(null);
  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  // });

  // const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>({
  //   lat: 40.98691,
  //   lng: 28.76995,
  // });

  // const onLoad = useCallback(function callback(map: google.maps.Map) {
  //   map.setZoom(10);
  //   map.setCenter(mapCenter);

  //   setMap(map);
  // }, []);

  // const onUnmount = useCallback(function callback(map: any) {
  //   setMap(null);
  // }, []);

  
  const [vehicleForm, setVehicleForm] = useState({
    quantity: 1,
    vehicle_type: "",
  });

  const navigate = useNavigate();


  const [form, setForm] = useState<any>({
    company_name: "",
    authorized_person_email: "",
    authorized_person_phone: "",
    city: "",
    district: "",
    vehicles: [],
    type: "service",
    mint_code: false,
    application_date: [],
    lat: 0,
    lng: 0,
    service_provider: "",
    fullAddress: "",
    reference: reference ?? "",
  });

  useEffect(() => {
    setForm((prev: any) => ({
      ...prev,
      reference: reference ?? "",
      ...(reference && {
        is_service_provider: true,
      }),
      ...(user?.role === "admin" && {
        is_admin: true
      })
    }));
  }, [user?.role, reference]);


  const { data: services } = useSWR("/service-provider/application", fetcher);
  const { data: citys } = useSWR("/montage/city", fetcher);

  const filteredCitys = useMemo(() => {
    if(Array.isArray(services) && typeof citys === "object")
    {
      return Object.keys(citys) // citys içindeki tüm şehir isimlerini al
        .filter((cityName) =>
          services.some((service) => service?.address?.city === cityName)
        )
        .map((cityName) => ({
          city: cityName,
          ...citys[cityName], // citys içindeki o şehrin detaylarını al
        }));
    }
    else
      return [];

    }, [citys, services]);

  const computedDistict = useMemo(() => (form.city && citys ? citys[form.city]
  ?.filter((f: any) => services?.some((s: any) => s?.address?.district === f?.district))
  .map((d: any) => ({
    ...d
  })) : []), [form.city, citys]);

  const selectedDistrict =
    computedDistict?.find((row: any) => row?.district === form?.district) ??
    null;

  const totalVehicles =
    form.vehicles?.reduce((total: number, row: any) => {
      total += row.quantity;
      return total;
    }, 0) ?? 0;

  const steps = [
    {
      title: "1.adım",
      status: step === 0,
      inProgress: step === 0,
    },
    {
      title: "Darphane başvuru süresi başlatıldı.",
      status: step === 1,
      inProgress: step === 1,
    },

  ];

  const activeStep = step;

  const styles = {
    row: {
      flexDirection: "row",
      gap: 3,
      ...(isMobile ? { flexDirection: "column" } : {}),
    },
    formControl: {
      flex: 1,
      ...(isMobile
        ? { width: "100% !important", flex: 1, minWidth: "100%" }
        : {}),
    },
    nativeLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    divider: {},
    vehicle_input: {
      maxWidth: "180px",
      ...(isMobile ? { maxWidth: "initial", width: "100%" } : {}),
    },
  };;

  const handleMapClick = (event: LeafletMouseEvent) => {
    // }
    const { lat, lng } = event.latlng;

    const position = {
      lat,
      lng,
    };

    map.setCoordinates(position);

    setForm((prev: any) => ({
      ...prev,
      lat: lat,
      lng: lng,
    }));
  };

  const onNextStep = async () => {
    try {
      const {
        company_name,
        authorized_person_email,
        authorized_person_phone,
        vehicles,
      } = form;

      const formPart = {
        company_name,
        authorized_person_email,
        authorized_person_phone,
        vehicles,
      };
      let isValid = true;

      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      Object.entries(formPart).map(([key, value]) => {
        if (
          !value ||
          value === "" ||
          (Array.isArray(value) && value.length === 0) ||
          (key === "authorized_person_phone" && value.length !== 17) ||
          (key === "authorized_person_email" && !emailRegex.test(value))
        ) {
          Array.isArray(value) && console.log(key, value, value.length);
          setFieldErrors((f: any) => {
            let errorMsg = "";
            switch (key) {
              case "company_name":
                errorMsg = "Firma İsmi alanı boş olamaz.";
                break;
              case "authorized_person_email":
                if (!emailRegex.test(value)) {
                  errorMsg = "Mail adresi geçerli olmalıdır.";
                } else if (value.length === 0) {
                  errorMsg = "Yetkili Mail Adresi alanı boş olamaz.";
                }
                break;
              case "authorized_person_phone":
                if (value === "") {
                  errorMsg = "Yetkili Telefon Numarası alanı boş olamaz.";
                } else if (value !== "" && value.length !== 17) {
                  errorMsg = "Telefon numarası 11 haneli olmalıdır.";
                }
                break;
              case "vehicles":
                errorMsg = "Araç eklenmelidir.";
                break;
            }
            isValid = false;
            return {
              ...f,
              [key]: errorMsg,
            };
          });
        }
      });

      if (!isValid) {
        // enqueueSnackbar("Boş alan bırakılamaz.", { variant: "error" });
        return;
      }

      if (!KVKKChecked) {
        enqueueSnackbar(
          "Devam etmek için KVKK metnini onaylamanız gerekiyor.",
          { variant: "error" }
        );
        return;
      }

      const response = await axiosInstance.post('/montage/application/pre', formPart);
      if(response.status == 200 || response.status == 201){
        setStep(1);
      }
     
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Bir hata oluştu.", { variant: "error" });
      return;
    }
  };

  const onSubmit = async () => {

    if (!form.city || !form.district) {
      setError("Şehir yada ilçe seçiniz.");
      enqueueSnackbar("Şehir yada ilçe seçiniz.", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      return;
    }
    if (form.type === "service" && (!form.lat || !form.lng)) {
      setError("Lütfen haritan konum seçiniz.");
      enqueueSnackbar("Lütfen haritan konum seçiniz.", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      return;
    }

    if (totalVehicles === 0) {
      setError("Lütfen araç girişi yapınız.");
      enqueueSnackbar("Lütfen araç girişi yapınız.", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      return;
    }
    if (form.type === "service" && form.application_date.length === 0 && totalVehicles <= 15) {
      setError("Lütfen randevu tarihini belirleyin.");
      enqueueSnackbar("Lütfen randevu tarihini belirleyin.", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      return;
    }

    setError("");

    const timesWithUTC: { date: string; time: string }[] = [];

    const times = form.application_date;
    times.forEach((row: { time: string; date: string }) => {
      const utcDate = new Date();

      const { time, date } = row; // yyyy.MM.dd hh:mm

      const dateParts = date.split(".");
      const timeParts = time.split(":");

      utcDate.setUTCHours(parseInt(timeParts[0]));
      utcDate.setUTCMinutes(parseInt(timeParts[1]));

      utcDate.setUTCDate(parseInt(dateParts[2]));
      utcDate.setUTCMonth(parseInt(dateParts[1]) - 1);
      utcDate.setUTCFullYear(parseInt(dateParts[0]));
      utcDate.setUTCSeconds(0);
      utcDate.setUTCMilliseconds(0);

      timesWithUTC.push({
        date: utcDate.toISOString(),
        time: time,
      });
    });

    const body = { ...form };

    if (!body.service_provider) {
      delete body.service_provider;
    }

    body.application_date = timesWithUTC.map((row) => row.date) ?? [];

    setLoading(true);
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/montage/application`,
        body
      );

      if (response.status === 201 || response.status === 200) {
        setSuccess(true);
        clearReference();
        navigate(
          `/thank-you-montage?type=${
            form.type == "mobile" ? "mobile" : "service"
          }${form.type == "service" ? `&reservation=${fDateTime(body.application_date)}` : ""}`
        );
      } else {
        const { errors } = response.data;

        const errorString = errors
          ? errors
              ?.map((err: any) => (err.messages ?? []).join("\n"))
              .join("\n")
          : "Beklenmedik bir hata oluştu.";

        setError(
          "Başvuru gönderilirken beklenmedik bir hata oluştu.<br/>" +
            errorString
        );
      }
    } catch (error: any) {
      console.log(error)
      // if(error?.response?.data){
      //   const { data } = error.response;
      //   const { errors } = data;
      //   const errorString = errors
      //     ? errors
      //         ?.map((err: any) => (err.messages ?? []).join("\n"))
      //         .join("\n")
      //     : "Beklenmedik bir hata oluştu.";

      //   setError(
      //     "Başvuru gönderilirken beklenmedik bir hata oluştu.<br/>" +
      //       errorString
      //   );
      // }
      enqueueSnackbar("Başvuru gönderilirken bir hata oluştu.", {variant: "error"});
    }
    finally{
      setLoading(false);
    }
  };

   useEffect(() => {
     if (form.application_date.length > 0) {
       onSubmit();
     }
   }, [form.application_date]);

  const filteredServices = useMemo(() => (services ?? []).filter(
    (service: IServiceProvider) => {
      // Eğer form type "service" ise, araç tiplerini kontrol et
      if (form.type === "service") {
        const vehicles = form.vehicles.map(
          (v: { vehicle_type: string }) => v.vehicle_type
        );
        const findVehicle = vehicles.some(
          (v: string) => !service.vehicle_types_for_service.includes(v)
        );
        if (findVehicle) return false;
      }

      // Şehir ve ilçe bazlı filtreleme
      if (form?.city) {
        if(form?.district){
            return service?.address?.city === form?.city && service?.address.district === form?.district;
        }
        return (
          service?.address?.city === form?.city 
        );
      } 
      else 
        return service;
      
    }
  ), [services]);   

  if(loading)
    return <CircularProgressModal />;

  return (
    <Container sx={{ mt: 2 }}>
      <Header />
      <Box
        sx={{
          backgroundColor: "#002B32",
          borderBottomLeftRadius: "24px",
          borderBottomRightRadius: "24px",
          pb: 4,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* <img 
          src="/images/omb.webp" 
          width={
            isSmall ? 250 : 
            isMobile ? 480 : 
            isTablet ? 600 : 
            isPc ? 800 : 0
          } 
          style={{
          justifySelf: "center",
          alignSelf: "center"
          }}
        /> */}
        <CustomHeader>Online Montaj Başvurusu</CustomHeader>
        
        {step == 1 && (
          <Box
            mx={4}
            my={2}
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setStep(0)}
          >
            <Iconify icon="ep:arrow-left" color="white" width="30px" />
            <Typography sx={{ color: "white" }}>Geri Dön</Typography>
          </Box>
        )}
        <Box sx={{ mx: 4, backgroundColor: "#fff", borderRadius: 4, p: 3 }}>
          {success && (
            <Stack alignItems="center" gap={3} sx={{ py: 4 }}>
              <IconSuccess />
              <Typography
                sx={{
                  fontSize: "32px",
                  lineHeight: "40px",
                  textAlign: "center",
                }}
              >
                {form.type === "mobile"
                  ? "Mobil Randevu Talebiniz Alınmıştır"
                  : "Randevunuz Başarıyla Oluşturulmuştur"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "24px",
                  lineHeight: "34px",
                  textAlign: "center",
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      form.type === "mobile"
                        ? "Randevunuz en kısa sürede Müşteri Deneyimi ekibimiz tarafından değerlendirilecek<br/> Randevu bilgileri tarafınıza e-mail yolu ile iletilecektir.<br/>Herhangi bir değişiklik ya da ek bilgi için bizimle iletişime geçebilirsiniz."
                        : "Randevu bilgileriniz e-mail adresinize gönderilmiştir.Randevu saatinden <b>en az 10 dakika öncesinde</b> montaj noktasında olmanızı rica ederiz.<br/>Herhangi bir değişiklik ya da ek bilgi için bizimle iletişime geçebilirsiniz.",
                  }}
                />
              </Typography>
            </Stack>
          )}
          {!success && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              {/* FIRST STEP */}
              <Stack gap={3}>
                <Stack>
                  <Stack direction={"row"}>
                    {steps.map((step, index) => (
                      <Stack
                        key={index}
                        gap={2}
                        sx={{
                          flex: index < steps.length - 1 ? 1 : 0,
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <Box
                            sx={{
                              backgroundColor:
                                index <= activeStep
                                  ? secondary
                                  : step.inProgress
                                  ? "#025462"
                                  : "#002B324D",

                              borderRadius: "42px",
                              minWidth: "42px",
                              minHeight: "42px",
                              width: "42px",
                              height: "42px",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            {index < activeStep && (
                              <Iconify icon="ic:round-check" color="white" />
                            )}
                          </Box>
                          {index < steps.length - 1 && (
                            <Box
                              width={"100%"}
                              height={4}
                              sx={{
                                borderRadius: 2,
                                position: "relative",
                                overflow: "hidden",
                                backgroundColor:
                                  index >= activeStep ? "#002B324D" : secondary,
                              }}
                            >
                              {/*@ts-ignore*/}
                              {step.inProgress && (
                                <Box
                                  sx={{
                                    background: "#025462",
                                    position: "absolute",
                                    inset: 0,
                                    right: "50%",
                                  }}
                                />
                              )}
                            </Box>
                          )}
                        </Stack>
                        <Stack gap={0.25}>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "black",
                              fontWeight: 300,
                            }}
                          >
                            {index + 1}. Aşama
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color:
                                index == activeStep ? "warning.main" : "black",
                            }}
                          >
                            {index < activeStep
                              ? "Tamamlandı"
                              : //@ts-ignore
                              step.inProgress
                              ? "Devam ediyor"
                              : "Beklemede"}
                          </Typography>
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>

                {step == 0 && (
                  <>
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          lineHeight: "30px",
                          fontWeight: 500,
                        }}
                      >
                        Firma Bilgileri
                      </Typography>
                    </Stack>

                    <Stack direction="row" sx={styles.row}>
                      <FormControl sx={styles.formControl}>
                        <TextField
                          label="Firma İsmi"
                          value={form.company_name}
                          error={!!fieldErrors?.company_name}
                          helperText={
                            fieldErrors?.company_name &&
                            fieldErrors.company_name
                          }
                          onChange={(e) => {
                            if (
                              e.target.value !== "" &&
                              fieldErrors?.company_name
                            ) {
                              setFieldErrors((f: any) => {
                                const { company_name, ...rest } = f;
                                return rest;
                              });
                            }
                            setForm((prev: any) => ({
                              ...prev,
                              company_name: (
                                e.target.value as string
                              ).toLocaleUpperCase(),
                            }));
                          }}
                          required
                        />
                      </FormControl>
                      <FormControl sx={styles.formControl}>
                        <TextField
                          type="email"
                          error={!!fieldErrors?.authorized_person_email}
                          helperText={
                            fieldErrors?.authorized_person_email &&
                            fieldErrors.authorized_person_email
                          }
                          value={form.authorized_person_email}
                          onChange={(e) => {
                            if (
                              e.target.value !== "" &&
                              fieldErrors?.authorized_person_email
                            ) {
                              setFieldErrors((f: any) => {
                                const { authorized_person_email, ...rest } = f;
                                return rest;
                              });
                            }
                            setForm((prev: any) => ({
                              ...prev,
                              authorized_person_email: e.target.value,
                            }));
                          }}
                          label="Yetkili Mail Adresi"
                          required
                        />
                      </FormControl>
                      <FormControl sx={styles.formControl}>
                        <MaskedInput
                          required
                          value={form.authorized_person_phone}
                          onChange={(e) => {
                            if (
                              e.target.value !== "" &&
                              e.target.value.length === 17 &&
                              fieldErrors?.authorized_person_phone
                            ) {
                              setFieldErrors((f: any) => {
                                const { authorized_person_phone, ...rest } = f;
                                return rest;
                              });
                            }
                            setForm((prev: any) => ({
                              ...prev,
                              authorized_person_phone: e.target.value,
                            }));
                          }}
                          guide={false}
                          render={(innerRef, props) => (
                            <TextField
                              {...props}
                              inputRef={innerRef}
                              error={fieldErrors?.authorized_person_phone}
                              helperText={
                                fieldErrors?.authorized_person_phone &&
                                fieldErrors.authorized_person_phone
                              }
                              label="Yetkili Telefon Numarası"
                              // placeholder="+90 "
                              slotProps={{
                                inputLabel: {
                                  shrink: true,
                                },
                              }}
                            />
                          )}
                          mask={[
                            "0",
                            " ",
                            "(",
                            /\d/,
                            /\d/,
                            /\d/,
                            ")",
                            " ",
                            /\d/,
                            /\d/,
                            /\d/,
                            " ",
                            /\d/,
                            /\d/,
                            " ",
                            /\d/,
                            /\d/,
                          ]}
                        />
                      </FormControl>
                    </Stack>
                    <Divider sx={styles.divider} />
                    <Stack>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          lineHeight: "30px",
                          fontWeight: 500,
                        }}
                      >
                        Montaj Hizmeti Alınacak Araç Bilgisi
                      </Typography>
                    </Stack>
                    <Stack gap={1}>
                      <div>
                        <Stack direction="row" gap={2} flexWrap="wrap">
                          <TextField
                            sx={styles.vehicle_input}
                            value={vehicleForm.quantity}
                            onChange={(e) => {
                              setVehicleForm((f) => ({
                                ...f,
                                quantity: parseInt(e.target.value),
                              }));
                            }}
                            type={"number"}
                            label={"Adet"}
                          />
                          <FormControl
                            sx={{
                              width: 180,
                              ...styles.formControl,
                              flex: "initial",
                            }}
                          >
                            <InputLabel>Araç Tipi</InputLabel>
                            <Select
                              value={vehicleForm.vehicle_type}
                              error={!!fieldErrors?.vehicles}
                              onChange={(e) => {
                                const newVehicleType = e.target.value;

                                setVehicleForm((prev) => ({
                                  ...prev,
                                  vehicle_type: newVehicleType,
                                }));

                                setForm((prevForm: any) => {
                                  const updatedVehicleForm = {
                                    ...vehicleForm,
                                    vehicle_type: newVehicleType, // Güncellenmiş değeri burada kullan
                                  };

                                  if (
                                    parseInt(
                                      (
                                        updatedVehicleForm?.quantity ?? ""
                                      ).toString()
                                    ) <= 0
                                  ) {
                                    return prevForm;
                                  }

                                  const updatedForm = {
                                    ...prevForm,
                                    vehicles: [
                                      ...prevForm.vehicles,
                                      updatedVehicleForm,
                                    ],
                                    application_date: [],
                                    service_provider: "",
                                  };

                                  return updatedForm;
                                });
                                if (
                                  form?.vehicles.length !== 0 &&
                                  fieldErrors?.vehicles
                                ) {
                                  setFieldErrors((f: any) => {
                                    const { vehicles, ...rest } = f;
                                    return rest;
                                  });
                                }
                                setVehicleForm({
                                  quantity: 1,
                                  vehicle_type: "",
                                });
                              }}
                              label={"Araç Tipi"}
                            >
                              {ServiceVehicleTypes.map((type, index) => {
                                return (
                                  <MenuItem value={type} key={index}>
                                    {type}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <FormHelperText style={{ color: "#d32f2f" }}>
                              {fieldErrors?.vehicles && fieldErrors.vehicles}
                            </FormHelperText>
                          </FormControl>
                          {/* <Button
                            disabled={
                              !vehicleForm.quantity ||
                              !vehicleForm.vehicle_type.length ||
                              vehicleForm.quantity <= 0
                            }
                            variant="contained"
                            color="secondary"
                            startIcon={<Iconify icon="ic:round-add" />}
                            onClick={() => {
                              const f = { ...form };
                              const vf = { ...vehicleForm };

                              if (
                                parseInt((vf?.quantity ?? "").toString()) <= 0
                              )
                                return;

                              f.vehicles.push(vf as any);
                              f.application_date = [];
                              f.service_provider = "";
                              setForm(f);

                              setVehicleForm({ quantity: 1, vehicle_type: "" });
                            }}
                          >
                            Taşıt Ekle
                          </Button> */}
                        </Stack>

                        {!!(form.vehicles ?? []).length && (
                          <Stack gap={1} direction="row" mt={1} flexWrap="wrap">
                            {(form.vehicles ?? []).map(
                              (row: any, index: number) => (
                                <Stack
                                  sx={{
                                    backgroundColor: "#ecfbf2",
                                    px: 2,
                                    py: 1,
                                    borderRadius: 2,
                                  }}
                                  key={index}
                                  direction="row"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <Typography>
                                    {row.quantity} {row.vehicle_type}
                                  </Typography>
                                  <IconButton
                                    onClick={() => {
                                      const f = { ...form };
                                      if (
                                        totalVehicles - row.quantity <
                                        selectedDistrict?.count
                                      ) {
                                        f.type = "service";
                                        f.service_provider = "";
                                        f.application_date = [];
                                        f.lat = 0;
                                        f.lng = 0;
                                      }
                                      f.vehicles.splice(index, 1);
                                      setForm(f);
                                    }}
                                  >
                                    <Iconify icon="ic:round-close" />
                                  </IconButton>
                                </Stack>
                              )
                            )}
                          </Stack>
                        )}
                      </div>
                    </Stack>
                    <Stack
                      sx={{
                        ...styles.row,
                      }}
                    ></Stack>
                    <label style={styles.nativeLabel as any}>
                      <Checkbox
                        required
                        checked={KVKKChecked}
                        onChange={(e) => setKVKKChecked(e.target.checked)}
                      />
                      <Typography>
                        KVKK yürürlüğündeki{" "}
                        <span
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            window.open("/gizlilik", "_blank");
                          }}
                        >
                          metni
                        </span>{" "}
                        okudum ve onaylıyorum.
                      </Typography>
                    </label>
                    <Stack sx={styles.row}>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => onNextStep()}
                      >
                        Devam
                      </Button>
                    </Stack>
                  </>
                )}

                {/*SECOND STEP*/}

                {step == 1 && (
                  <>
                    <Stack>
                      <Stack sx={{ mb: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "20px",
                            lineHeight: "30px",
                            fontWeight: 500,
                          }}
                        >
                          Montaj Hizmeti Talep Edilen Adres Bilgisi
                        </Typography>
                      </Stack>
                      <Stack sx={styles.row}>
                        <FormControl sx={styles.formControl}>
                          <InputLabel>İl</InputLabel>
                          <Select
                            label="İl"
                            value={form.city}
                            onChange={(e) => {
                              setForm((prev: any) => ({
                                ...prev,
                                city: e.target.value,
                                district: "",
                              }));

                              fetchCoordinates(e.target.value).then((data) => {
                                if (data?.lat && data?.lng) {
                                  map.setCoordinates({
                                    lat: data.lat,
                                    lng: data.lng,
                                  });
                                  map.setZoom(8);
                                }
                              });
                            }}
                          >
                            <MenuItem value="İSTANBUL">İSTANBUL</MenuItem>
                            {!!filteredCitys?.length &&
                              filteredCitys.map((city, index) => {
                                if (city.city === "İSTANBUL") return;
                                return (
                                  <MenuItem value={city.city} key={index}>
                                    {city.city}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                        <FormControl sx={styles.formControl}>
                          <InputLabel>İlçe</InputLabel>
                          <Select
                            value={
                              form.district === "" ? "Tümü" : form.district
                            }
                            onChange={(e) => {
                              setForm((prev: any) => ({
                                ...prev,
                                district:
                                  e.target.value === "Tümü"
                                    ? ""
                                    : e.target.value,
                                type: "service",
                              }));

                              fetchCoordinates(form.city, e.target.value).then(
                                (data) => {
                                  if (data?.lat && data?.lng) {
                                    map.setCoordinates({
                                      lat: data.lat,
                                      lng: data.lng,
                                    });
                                    map.setZoom(10.5);
                                  }
                                }
                              );
                            }}
                            label="İlçe"
                            disabled={!form.city}
                          >
                            <MenuItem value="Tümü">Tümü</MenuItem>
                            {computedDistict.map((row: any, index: number) => (
                              <MenuItem key={index} value={row.district}>
                                {`${row.district}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                    </Stack>
                    <Divider sx={styles.divider} />
                    {selectedDistrict &&
                    totalVehicles >= selectedDistrict.count ? (
                      <ShowTooltip
                        show={
                          selectedDistrict &&
                          totalVehicles < selectedDistrict.count
                        }
                        title="Belirtilen araç sayısı mobil montaj başvurusu için uygun değil."
                      >
                        <FormControl>
                          <label
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Checkbox
                              disabled={
                                !selectedDistrict ||
                                totalVehicles < selectedDistrict.count
                              }
                              checked={form.type === "mobile"}
                              onChange={(e) => {
                                setForm((prev: any) => ({
                                  ...prev,
                                  type: e.target.checked ? "mobile" : "service",
                                  application_date: [],
                                  service_provider: "",
                                }));
                              }}
                            />
                            <Typography>Mobil Montaj İstiyorum</Typography>
                          </label>
                        </FormControl>
                      </ShowTooltip>
                    ) : (
                      <></>
                    )}
                    <Stack
                      direction={isMobile ? "column" : "row"}
                      alignItems={isMobile ? "" : "center"}
                      gap={1}
                      justifyContent="space-between"
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          lineHeight: "30px",
                          fontWeight: 500,
                        }}
                      >
                        Montaj Hizmeti Alınacak Adres Bilgisi
                      </Typography>
                      <Divider sx={styles.divider} />

                      {form.type === "service" && !!totalVehicles && (
                        <Typography
                          sx={{ fontSize: isMobile ? "12px" : "14px" }}
                        >
                          Seçilen araç tiplerine göre, toplam{" "}
                          <b>{services?.length ?? "-"}</b> bayiden{" "}
                          <b>{filteredServices?.length ?? "-"}</b> adet bayi
                          hizmet verebilmektedir.
                        </Typography>
                      )}
                    </Stack>

                    {form.type === "service" ? (
                      <Stack
                        gap={1}
                        direction={isMobile ? "column" : "row"}
                        sx={{
                          display: "flex",
                          ...(!isMobile && {
                            overflow: "hidden",
                            height: 500,
                          }),
                        }}
                      >
                        {/* <GoogleMap
                          mapContainerStyle={{ height: 500 }}
                          onLoad={onLoad}
                          onUnmount={onUnmount}
                          onClick={handleMapClick}
                        >
                          
                        </GoogleMap> */}

                        <Stack
                          sx={{
                            width: isMobile ? "100%" : "40%",
                            overflowY: "auto", // İçerik uzadıkça kaydırma ekliyoru
                            height: "100%",
                          }}
                          direction="column"
                          gap={1}
                        >
                          {form.city !== "" && filteredServices?.length ? (
                            filteredServices?.map(
                              (s: IServiceProvider, index: number) => (
                                <Box
                                  key={index}
                                  sx={{
                                    width: "100%",
                                    borderColor:
                                      s?._id === form?.service_provider
                                        ? "warning.main"
                                        : "lightgray",
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    minHeight: isMobile ? "100px" : "125px", // Min boyut belirliyoruz
                                    background: "rgba(226, 226, 226, 0.34)",
                                    px: 2,
                                    py: 1,
                                    borderRadius: 5,
                                    overflow: "hidden",
                                    cursor: "pointer",
                                    "&:hover": {
                                      background: "rgba(226, 226, 226, 0.5)",
                                      borderColor: "warning.main",
                                    },
                                  }}
                                  onClick={() => {
                                    if (
                                      s?.coordinates?.lat &&
                                      s?.coordinates?.lng
                                    ) {
                                      setForm((prev: any) => ({
                                        ...prev,
                                        service_provider: s._id,
                                        application_date: [],
                                        district: s?.address?.district,
                                        lat: s.coordinates.lat,
                                        lng: s.coordinates.lng,
                                      }));
                                    } else {
                                      setForm((prev: any) => ({
                                        ...prev,
                                        service_provider: s?._id,
                                        application_date: [],
                                        district: s?.address?.district,
                                      }));
                                    }
                                    if (
                                      s?.coordinates?.lat &&
                                      s?.coordinates?.lng
                                    ) {
                                      map.setCoordinates(s.coordinates);
                                      map.setZoom(16);
                                    }
                                  }}
                                >
                                  <Stack direction="column" gap={1}>
                                    <Stack>
                                      <Typography
                                        sx={{
                                          overflow: "hidden", // Taşanları gizler
                                          textOverflow: "ellipsis", // ... ile keser
                                          ...(!isMobile
                                            ? {
                                                fontSize: "20px",
                                                lineHeight: "30px",
                                                fontWeight: 500,
                                              }
                                            : {
                                                fontSize: "15px",
                                                lineHeight: "15px",
                                                fontWeight: 300,
                                              }),
                                          textWrap: "nowrap",
                                        }}
                                      >
                                        {(s?.signage_name ?? "-").toUpperCase()}
                                      </Typography>
                                    </Stack>
                                    <Stack>
                                      <Typography
                                        sx={{
                                          overflow: "hidden", // Fazla metni gizle
                                          textOverflow: "ellipsis",
                                          color: "text.secondary",
                                          textWrap: "nowrap",
                                          ...(!isMobile
                                            ? {
                                                fontSize: "20px",
                                                lineHeight: "30px",
                                                fontWeight: 500,
                                              }
                                            : {
                                                fontSize: "11px",
                                                lineHeight: "15px",
                                                fontWeight: 300,
                                              }),
                                        }}
                                      >
                                        {s?.address?.fullAddress}
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      justifyContent="space-evenly"
                                      direction="row"
                                      alignItems="center"
                                    >
                                      <Button
                                        sx={{
                                          textTransform: "none",
                                          width: "auto",
                                          alignSelf: "flex-start",
                                          gap: 1,
                                          zIndex: 2,
                                          fontSize: !isMobile ? 14 : 12,
                                        }}
                                        color="warning"
                                        disabled={
                                          !s?.coordinates?.lat ||
                                          !s?.coordinates?.lng
                                        }
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          window.open(
                                            `https://www.google.com/maps/search/${s?.coordinates?.lat},${s?.coordinates?.lng}`,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        <Iconify
                                          icon="streamline:location-pin-3"
                                          width={!isMobile ? 21 : 15}
                                        />
                                        Yol Tarifi
                                      </Button>
                                      <Button
                                        sx={{
                                          textTransform: "none",
                                          width: "auto",
                                          alignSelf: "flex-start",
                                          gap: 1,
                                          fontSize: !isMobile ? 14 : 12,
                                        }}
                                        color="success"
                                        onClick={(e) => {
                                          setShow(true);
                                        }}
                                      >
                                        <Iconify
                                          icon="teenyicons:appointments-outline"
                                          width={!isMobile ? 21 : 15}
                                        />
                                        Randevu Al
                                      </Button>

                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap={1}
                                      >
                                        <Box
                                          sx={
                                            isMobile
                                              ? {
                                                  width: "17px", // İkon boyutuna bağlı
                                                  height: "17px", // İkon boyutuna bağlı
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  borderRadius: "50%", // Yuvarlak şekil
                                                  backgroundColor:
                                                    s?._id ===
                                                    form.service_provider
                                                      ? "warning.main"
                                                      : "rgba(226, 226, 226, 0.34)",
                                                  color: "white",
                                                  fontSize: "20px", // İkon büyüdükçe kutu da büyür
                                                }
                                              : {
                                                  width: "22px", // İkon boyutuna bağlı
                                                  height: "22px", // İkon boyutuna bağlı
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  borderRadius: "50%", // Yuvarlak şekil
                                                  backgroundColor:
                                                    s?._id ===
                                                    form?.service_provider
                                                      ? "warning.main"
                                                      : "rgba(226, 226, 226, 0.34)",
                                                  borderColor: "warning.main",
                                                  borderWidth: 1,
                                                  borderStyle: "solid",
                                                  color: "white",
                                                  fontSize: "20px", // İkon büyüdükçe kutu da büyür
                                                }
                                          }
                                        >
                                          <Iconify
                                            icon="ic:round-check"
                                            width={"1em"}
                                            height={"1em"}
                                            color={
                                              s?._id === form?.service_provider
                                                ? "white"
                                                : "warning.main"
                                            }
                                          />
                                        </Box>
                                        <Typography
                                          color="warning"
                                          fontSize={!isMobile ? 15 : 12}
                                        >
                                          {s._id === form.service_provider
                                            ? "Seçildi"
                                            : "Seç"}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </Stack>
                                </Box>
                              )
                            )
                          ) : (
                            <Stack flex={1}></Stack>
                          )}
                        </Stack>
                        <Stack
                          sx={{ width: isMobile ? "100%" : "60%" }}
                          alignSelf="flex-start"
                          justifySelf="flex-end"
                        >
                          <OpenStreetMap
                            coordinates={map.coordinates}
                            zoom={map.zoom}
                            onMapClick={handleMapClick}
                            services={filteredServices}
                            scrollWheelZoom={isMobile ? false : true}
                            isFormRendered
                            setShow={setShow}
                            form={form}
                            setForm={setForm}
                          />
                        </Stack>
                      </Stack>
                    ) : (
                      <TextField
                        fullWidth
                        value={form.fullAddress}
                        onChange={(e) => {
                          setForm((prev: any) => ({
                            ...prev,
                            fullAddress: e.target.value,
                          }));
                        }}
                        label="Adres"
                        required
                      />
                    )}
                    {form.type === "service" && (
                      <>
                        <MontageTimePicker
                          count={totalVehicles}
                          title={
                            services?.find(
                              (s: any) => s._id === form?.service_provider
                            )?.offical_title ?? ""
                          }
                          onSave={(times) => {
                            const f = { ...form };
                            f.application_date = times;
                            setForm(f);
                          }}
                          onClose={() => setShow(false)}
                          service={form.service_provider}
                          showButton
                          show={show}
                          setShow={setShow}
                          isMontageForm
                          onSubmit={onSubmit}
                        />
                        {!!form.application_date.length && (
                          <Stack sx={{ ...styles.row, flexWrap: "wrap" }}>
                            {form.application_date.map(
                              (
                                d: { date: string; time: string },
                                index: number
                              ) => {
                                const [year, month, day] = d.date.split(".");
                                const f = new Date(`${year}-${month}-${day}`);
                                const dateString = format(f, "dd MMMM yyyy", {
                                  locale: tr,
                                });
                                return (
                                  <Box
                                    sx={{ overflow: "hidden", borderRadius: 2 }}
                                    key={index}
                                  >
                                    <Box
                                      sx={{
                                        backgroundColor: "#ecfbf2",
                                        borderRadius: 2,
                                        px: 2,
                                        py: 1,
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 2,
                                        fontSize: "14px",
                                      }}
                                    >
                                      {`${dateString} ${d.time}`}
                                      {/* <Iconify icon="ic:round-close" /> */}
                                    </Box>
                                  </Box>
                                );
                              }
                            )}
                          </Stack>
                        )}
                      </>
                    )}

                    <Divider sx={styles.divider} />
                    <Stack sx={styles.row}></Stack>
                    {error && (
                      <Alert severity="error">
                        <span
                          dangerouslySetInnerHTML={{ __html: error }}
                        ></span>
                      </Alert>
                    )}
                    {form.type === "mobile" && (
                      <Stack>
                        <Box>
                          <Button
                            type="submit"
                            disabled={loading}
                            variant="contained"
                            color="secondary"
                            id="montaj-basvuru-buton"
                          >
                            Randevu Başvurusunda Bulun
                          </Button>
                        </Box>
                      </Stack>
                    )}
                  </>
                )}
              </Stack>
            </form>
          )}
        </Box>
      </Box>
      <Footer />
    </Container>
  );
});

export default MontajBasvuruForm;
