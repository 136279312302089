import axios from "axios";
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { axiosInstance, setAuthToken } from "../utils";
import { mutate } from "swr";

interface User {
  id: string;
  username: string;
  role: "admin" | "customer" | "service_provider";
  utts?: any;
}

interface AdminContextType {
  user: User | null;
  login: (username: string, password: string) => void;
  logout: () => void;
  isAuthenticated: boolean;
  error?: string;
  isLoading?: boolean;
}

const AdminContext = createContext<AdminContextType | undefined>(undefined);

export const AdminProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const login = async (username: string, password: string) => {
    setLoading(true);
    try {
      setError("");
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/jwt/basic`,
        {
          username,
          password,
        }
      );

      const { data: response, success } = data;

      if (success) {
        localStorage.setItem("token", response.token);

        setAuthToken(response.token);

        setUser(
          response.utts
            ? {
                username: response.username,
                id: response.id,
                role: response.role,
                utts: response.utts,
              }
            : {
                username: response.username,
                id: response.id,
                role: response.role,
              }
        );

        window.location.reload();
      } else {
        setError("Geçersiz kullanıcı adı veya şifre.");
      }
    } catch (e) {
      setError("Geçersiz kullanıcı adı veya şifre.");
    }
    setLoading(false);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
    setLoading(false);
    setError("");
  };

  const checkToken = async (token: string) => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/jwt/basic`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data: response, success } = data;
      if (success) {
        localStorage.setItem("token", token);
        setAuthToken(token);
        setUser(
          response.utts
            ? {
                username: response.username,
                id: response.id,
                role: response.role,
                utts: response.utts,
              }
            : {
                username: response.username,
                id: response.id,
                role: response.role,
              }
        );
      } else {
        setError("");
        setUser(null);
      }
    } catch (error) {
      setUser(null);
      setError("");
    }
    setLoading(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setUser(null);
      setError("");
      setLoading(false);
      return;
    }

    checkToken(token);
  }, []);

  const isAuthenticated = !!user;

  return (
    <AdminContext.Provider
      value={{
        user,
        login,
        logout,
        isAuthenticated,
        error,
        isLoading: loading,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAuth = (): AdminContextType => {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
